import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTimezoneForSubcategory,
  updateClientTimezone,
} from '../../Api/GraphQL/TimezoneApi';
import {
  getTimezoneSuccess,
  getTimezoneFailure,
} from '../Actions/ActionCreators';
import { ACTIONS } from '../Actions/Actions';

export function* getTimezone(action) {
  const { subcategoryId } = action.payload;
  try {
    const { data } = yield call(getTimezoneForSubcategory, {
      id: subcategoryId,
    });
    const { timezone } = data.getSubcategory;
    const payload = { id: subcategoryId, timezone };
    yield put(getTimezoneSuccess(payload));
  } catch (e) {
    yield put(getTimezoneFailure({ message: e.message }));
  }
}

export function* updateTimezone(action) {
  const { subcategoryId, timezone } = action.payload;
  yield call(updateClientTimezone, {
    id: subcategoryId,
    timezone,
  });
}

export function* onGetTimezoneForSubcategory() {
  yield takeEvery(ACTIONS.getTimezone, getTimezone);
}

export function* onUpdateTimezone() {
  yield takeEvery(ACTIONS.updateTimezone, updateTimezone);
}
