export const GROUP_MANAGER_ACTIONS = {
  loadSchedulingGroupsForGroupManager:
    'LOAD_SCHEDULING_GROUPS_FOR_GROUP_MANAGER',
  setActiveSchedulingGroup: 'SET_ACTIVE_SCHEDULING_GROUP',
  loadAdmiralCampaigns: 'LOAD_ADMIRAL_CAMPAIGNS',
  createNewSchedulingGroup: 'CREATE_NEW_SCHEDULING_GROUP',
  saveSchedulingGroups: 'SAVE_SCHEDULING_GROUPS',
  cancelNewSchedulingGroups: 'CANCEL_NEW_SCHEDULING_GROUPS',
  selectAdmiralCampaign: 'SELECT_ADMIRAL_CAMPAIGN',
  deselectAdmiralCampaign: 'DESELECT_ADMIRAL_CAMPAIGN',
  resetSelectedAdmiralCampaigns: 'RESET_SELECTED_ADMIRAL_CAMPAIGNS',
  moveToSchedulingGroup: 'MOVE_TO_SCHEDULING_GROUP',
  deleteSchedulingGroup: 'DELETE_SCHEDULING_GROUP',
  deleteSchedulingGroupSuccess: 'DELETE_SCHEDULING_GROUP_SUCCESS',
  moveToNewSchedulingGroup: 'MOVE_TO_NEW_SCHEDULING_GROUP',
  editSchedulingGroup: 'EDIT_SCHEDULING_GROUP',
  renameSchedulingGroup: 'RENAME_SCHEDULING_GROUP',
  cancelRenameSchedulingGroup: 'CANCEL_RENAME_SCHEDULING_GROUP',
};

export const GROUP_MANAGER_REQUEST_ACTIONS = {
  getSchedulingGroupsForGroupManagerSuccess:
    'GET_SCHEDULING_GROUPS_FOR_GROUP_MANAGER_SUCCESS',
  getSchedulingGroupsForGroupManagerFailure:
    'GET_SCHEDULING_GROUPS_FOR_GROUP_MANAGER_FAILURE',
  getAdmiralCampaignsSuccess: 'GET_ADMIRAL_CAMPAIGNS_SUCCESS',
  getAdmiralCampaignsFailure: 'GET_ADMIRAL_CAMPAIGNS_FAILURE',
  createNewSchedulingGroupSuccess: 'CREATE_NEW_SCHEDULING_GROUP_SUCCESS',
  createNewSchedulingGroupFailure: 'CREATE_NEW_SCHEDULING_GROUP_FAILURE',
  saveSchedulingGroupsSuccess: 'SAVE_SCHEDULING_GROUPS_SUCCESS',
  saveSchedulingGroupsFailure: 'SAVE_SCHEDULING_GROUPS_FAILURE',
  updateSchedulingGroupsSuccess: 'UPDATE_SCHEDULING_GROUPS_SUCCESS',
  updateSchedulingGroupsFailure: 'UPDATE_SCHEDULING_GROUPS_FAILURE',
  deleteSchedulingGroupsSuccess: 'DELETE_SCHEDULING_GROUPS_SUCCESS',
  deleteSchedulingGroupsFailure: 'DELETE_SCHEDULING_GROUPS_FAILURE',
};
