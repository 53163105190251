import { GROUP_MANAGER_ACTIONS } from 'Features/SchedulingGroupManager/SchedulingGroupManagerActions';
import { PLATFORM_CAMPAIGN_REQUEST_ACTIONS } from 'Features/PlatformCampaigns/PlatformCampaignActions';
import { SCHEDULING_GROUPS_ACTIONS } from './schedulingGroupsActions';

export const initialState = () => ({
  data: {},
  loaded: false,
});

const activeSchedulingGroupReducer = (state = initialState(), action) => {
  switch (action.type) {
    case GROUP_MANAGER_ACTIONS.setActiveSchedulingGroup:
    case PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsSuccess: {
      const { schedulingGroup } = action.payload;
      return {
        ...state,
        data: schedulingGroup,
        loaded: true,
      };
    }
    case SCHEDULING_GROUPS_ACTIONS.resetActiveSchedulingGroupState: {
      return initialState();
    }
    default:
      return state;
  }
};

export default activeSchedulingGroupReducer;
