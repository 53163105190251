import { call, put, takeEvery } from 'redux-saga/effects';
import { getTimezonesList } from '../../Api/GraphQL/TimezoneApi';
import {
  getTimezonesSuccess,
  getTimezonesFailure,
} from '../Actions/ActionCreators';
import { ACTIONS } from '../Actions/Actions';

export function* getTimezones(action) {
  const { filter } = action.payload;
  try {
    const timezones = yield call(getTimezonesList, { filter });
    yield put(getTimezonesSuccess(timezones));
  } catch (e) {
    yield put(getTimezonesFailure({ message: e.message }));
  }
}

function* onGetTimezones() {
  yield takeEvery(ACTIONS.getTimezones, getTimezones);
}

export default onGetTimezones;
