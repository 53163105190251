import { connect } from 'react-redux';
import withErrorBoundary from 'Components/ErrorBoundary/ErrorBoundaryContainer';
import {
  resetSchedulingGroupsState,
  resetActiveSchedulingGroupState,
  resetActiveSubcategoryState,
  setSubcategoriesSearchTerm,
} from 'Shared/SharedActionCreators';
import getExpandedSubcategories from 'Shared/Selectors/SubcategoriesPageUISelector';
import {
  getSubcategories,
  getSubcategoriesSearchTerm,
} from 'Shared/Selectors/SubcategorySelector';
import SubcategoryListContainer from './SubcategoryListContainer';
import {
  loadSubcategories,
  subcategoryClick,
  loadSubcategoriesOnInterval,
  selectPage,
} from './subcategoryListActionCreators';
import { selectManagerFilter } from '../../Store/Actions/ActionCreators';

const mapStateToProps = state => ({
  subcategoryStore: getSubcategories(state),
  expandedSubcategories: getExpandedSubcategories(state),
  subcategoriesSearchTerm: getSubcategoriesSearchTerm(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadSubcategories: () => {
    dispatch(loadSubcategories());
  },
  onLoadSubcategoriesOnInterval: () => {
    dispatch(loadSubcategoriesOnInterval());
  },
  onSubcategoryClick: subcategoryId => {
    dispatch(subcategoryClick({ subcategoryId }));
  },
  resetState: () => {
    dispatch(resetActiveSubcategoryState());
    dispatch(resetActiveSchedulingGroupState());
    dispatch(resetSchedulingGroupsState());
  },
  onSelectManagerFilter: managerEmail => {
    dispatch(selectManagerFilter({ email: managerEmail }));
  },
  onSetSubcategoriesSearchTerm: searchTerm => {
    dispatch(setSubcategoriesSearchTerm({ searchTerm }));
  },
  onSelectPage: page => {
    dispatch(selectPage({ page }));
  },
});

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(SubcategoryListContainer),
);
