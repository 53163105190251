import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'Components/BaseModal/BaseModal';
import Loader from 'Components/Loader/Loader';
import Schedule from 'Features/Schedules/Schedule';
import TimezonePicker from 'Components/TimezonePicker/TimezonePicker';
import { removeDayOfWeekProperty } from 'Shared/Formatters/Formatters';

export const ScheduleModal = props => {
  const {
    subcatName,
    subcategoryId,
    id,
    name: schedulingGroupName,
    division,
    onOpenScheduleModal,
    onCloseScheduleModal,
    onResetSchedule,
    onSaveSchedule,
    schedules,
    schedulesLoaded,
    renderFooterError,
    changeFieldValues,
  } = props;

  const scheduleData = schedulesLoaded ? (
    <Schedule schedulingGroupId={id} changeFieldValues={changeFieldValues} />
  ) : (
    <Loader name="Schedules" />
  );

  return (
    <Fragment>
      <BaseModal
        id={id}
        buttonText="Schedule"
        getModalData={onOpenScheduleModal}
        resetCallback={onResetSchedule}
        saveCallback={() =>
          onSaveSchedule(removeDayOfWeekProperty(schedules.data), id)
        }
        footerError={renderFooterError}
        title={`Schedule Settings (${subcatName})`}
        modalClose={onCloseScheduleModal}
      >
        <div className="modal-sg-name-container">
          <h3>
            Scheduling Group
            <span className="modal-sg-name">
              {`${division} - ${schedulingGroupName}`}
            </span>
          </h3>
          <TimezonePicker subcategoryId={subcategoryId} readOnly />
        </div>
        {scheduleData}
      </BaseModal>
    </Fragment>
  );
};

ScheduleModal.defaultProps = {
  renderFooterError: PropTypes.bool,
};

ScheduleModal.propTypes = {
  subcatName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  division: PropTypes.string.isRequired,
  subcategoryId: PropTypes.number.isRequired,
  schedules: PropTypes.shape({
    data: PropTypes.arrayOf,
    times_by_id: PropTypes.arrayOf,
    type: PropTypes.string,
  }).isRequired,
  changeFieldValues: PropTypes.func.isRequired,
  onOpenScheduleModal: PropTypes.func.isRequired,
  onCloseScheduleModal: PropTypes.func.isRequired,
  onSaveSchedule: PropTypes.func.isRequired,
  onResetSchedule: PropTypes.func.isRequired,
  schedulesLoaded: PropTypes.bool.isRequired,
  renderFooterError: PropTypes.bool,
};

export default ScheduleModal;
