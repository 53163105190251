import { connect } from 'react-redux';
import { getSchedulingGroupsBySubcategoryId } from 'Shared/Selectors/SchedulingGroupSelector';
import { SCHEDULING_GROUP_POLLING_INTERVAL } from 'Shared/Constants/Polling';
import { loadSchedulingGroups } from './store/schedulingGroupsActionCreators';
import SchedulingGroupContainer from './SchedulingGroupContainer';

const mapStateToProps = (state, props) => ({
  schedulingGroups: getSchedulingGroupsBySubcategoryId(
    state,
    props.subcategory.id,
  ),
});

const mapDispatchToProps = dispatch => ({
  onPageLoad: subcategoryId => {
    dispatch(loadSchedulingGroups({ subcategoryId }));
    return setInterval(() => {
      dispatch(loadSchedulingGroups({ subcategoryId }));
    }, SCHEDULING_GROUP_POLLING_INTERVAL);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchedulingGroupContainer);
