import { call, put, takeEvery } from 'redux-saga/effects';
import getDivisions from '../../Api/GraphQL/DivisionApi';
import { ACTIONS } from '../Actions/Actions';
import {
  getDivisionsFailure,
  getDivisionsSuccess,
} from '../Actions/ActionCreators';

function* fetchDivisions() {
  try {
    const { data } = yield call(() => getDivisions({ managed: false }));
    yield put(getDivisionsSuccess(data.divisions));
  } catch (e) {
    yield put(getDivisionsFailure({ message: e.message }));
  }
}

export default function* onFetchDivisions() {
  yield takeEvery(ACTIONS.loadDivisions, fetchDivisions);
}
