import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isDataEqual } from 'Shared/Formatters/Functions';
import SubcategoryDivisionPicker from 'Features/SubcategoryDivisionPicker/components/SubcategoryDivisionPicker';

const SubcategoryDivisionPickerContainer = props => {
  const [newSubcategoryId, setNewSubcategoryId] = useState('');

  const selectDivision = division => {
    const { onDivisionSelect } = props;
    onDivisionSelect(division);
  };

  const alertAccept = () => {
    const { onSubcategorySelect, onAlertDialogClose } = props;
    onSubcategorySelect(newSubcategoryId);
    onAlertDialogClose();
  };

  const selectSubcategory = subcategory => {
    const {
      onSubcategorySelect,
      schedulingGroups,
      admiralCampaigns,
      immutableAdmiralCampaigns,
      activeSubcategory,
      onAlertDialogOpen,
      onAlertDialogClose,
    } = props;
    const { data, immutableData } = schedulingGroups;

    if (
      (isDataEqual(data, immutableData) ||
        isDataEqual(admiralCampaigns, immutableAdmiralCampaigns)) &&
      subcategory.id !== activeSubcategory.id
    ) {
      setNewSubcategoryId(subcategory);
      onAlertDialogOpen({
        alertText:
          'You have unsaved changes, are you sure you want to leave this page?',
        alertAccept,
        alertCancel: onAlertDialogClose,
      });
    } else {
      onSubcategorySelect(subcategory);
    }
  };

  const {
    activeSubcategory,
    subcategories,
    divisions,
    loadDivisions,
    selectedDivision,
    searchTerm,
    onFilterSubcategories,
  } = props;

  return (
    <Fragment>
      <SubcategoryDivisionPicker
        filterSubcategories={onFilterSubcategories}
        subcategories={subcategories}
        onSubcategorySelect={selectSubcategory}
        activeSubcategory={activeSubcategory}
        searchTerm={searchTerm}
        loadDivisions={loadDivisions}
        divisions={divisions}
        onDivisionSelect={selectDivision}
        selectedDivision={selectedDivision}
      />
    </Fragment>
  );
};

SubcategoryDivisionPickerContainer.propTypes = {
  activeSubcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  onFilterSubcategories: PropTypes.func.isRequired,
  onSubcategorySelect: PropTypes.func.isRequired,
  schedulingGroups: PropTypes.shape({
    data: PropTypes.objectOf(PropTypes.object),
    immutableData: PropTypes.objectOf(PropTypes.object),
    data_by_id: PropTypes.arrayOf(PropTypes.object),
    groupManagerLoaded: PropTypes.bool,
    loaded: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  admiralCampaigns: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      schedulingGroupId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
  ).isRequired,
  immutableAdmiralCampaigns: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      schedulingGroupId: PropTypes.number,
    }),
  ).isRequired,
  onAlertDialogOpen: PropTypes.func.isRequired,
  onAlertDialogClose: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string),
  loadDivisions: PropTypes.func.isRequired,
  onDivisionSelect: PropTypes.func.isRequired,
  selectedDivision: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
};

SubcategoryDivisionPickerContainer.defaultProps = {
  divisions: [],
  searchTerm: '',
};

export default SubcategoryDivisionPickerContainer;
