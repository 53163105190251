import { ACTIONS, REQUEST_ACTIONS } from '../Actions/Actions';

export const initialState = () => ({
  data: [],
  selectedDivision: '',
});

const divisionReducer = (state = initialState(), action) => {
  switch (action.type) {
    case REQUEST_ACTIONS.getDivisionsSuccess: {
      const divisions = action.payload;
      return {
        ...state,
        data: divisions,
      };
    }
    case REQUEST_ACTIONS.getDivisionsFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }
    case ACTIONS.setDivisionFilter: {
      const { division } = action.payload;
      return {
        ...state,
        selectedDivision: division,
      };
    }
    case ACTIONS.clearFilters: {
      return { ...state, selectedDivision: '' };
    }
    default:
      return state;
  }
};

export default divisionReducer;
