import { SUBCATEGORYLIST_ACTIONS } from './subcategoryListActions';

export const initialState = () => ({
  expandedSubcategories: {},
});

const subcategoriesPageUIReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SUBCATEGORYLIST_ACTIONS.subcategoryClick: {
      const { subcategoryId } = action.payload;
      const { expandedSubcategories } = state;
      return {
        ...state,
        expandedSubcategories: {
          ...expandedSubcategories,
          [subcategoryId]: !expandedSubcategories[subcategoryId],
        },
      };
    }
    case SUBCATEGORYLIST_ACTIONS.clearExpandedSubcategories: {
      return initialState();
    }
    default:
      return state;
  }
};

export default subcategoriesPageUIReducer;
