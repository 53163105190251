import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, sortBy } from 'lodash';
import EmptySearch from 'Components/EmptySearch/EmptySearch';
import { SUBCATEGORY_POLLING_INTERVAL } from 'Shared/Constants/Polling';
import { TEXT_STRINGS } from 'Shared/Constants/Constants';
import Loader from 'Components/Loader/Loader';
import SubcategoryList from './SubcategoryList';
import SubcategoryItem from './SubcategoryItem';

export class SubcategoryListContainer extends Component {
  static propTypes = {
    subcategoryStore: PropTypes.shape({
      managers: PropTypes.shape({}),
      loaded: PropTypes.bool,
      subcategories: PropTypes.objectOf(PropTypes.object),
      pageCount: PropTypes.number.isRequired,
      currentPage: PropTypes.number.isRequired,
    }).isRequired,
    onLoadSubcategories: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    onSubcategoryClick: PropTypes.func.isRequired,
    onLoadSubcategoriesOnInterval: PropTypes.func.isRequired,
    expandedSubcategories: PropTypes.objectOf(PropTypes.bool).isRequired,
    onSetSubcategoriesSearchTerm: PropTypes.func.isRequired,
    subcategoriesSearchTerm: PropTypes.string.isRequired,
    onSelectPage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { resetState } = this.props;
    resetState();
    this.loadSubcategories(false);
  }

  componentDidUpdate() {
    const { subcategoryStore } = this.props;
    const { loaded } = subcategoryStore;
    this.loadSubcategories(loaded);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  loadSubcategories = loaded => {
    const { onLoadSubcategories, onLoadSubcategoriesOnInterval } = this.props;

    if (!loaded) {
      onLoadSubcategories();
      this.pollInterval = setInterval(() => {
        onLoadSubcategoriesOnInterval();
      }, SUBCATEGORY_POLLING_INTERVAL);
    }
  };

  renderResults() {
    const {
      subcategoryStore,
      onSubcategoryClick,
      expandedSubcategories,
    } = this.props;

    const buildSubcategoryItem = subcategory => {
      const ids = subcategory.managers;
      const managersInfo = ids.map(id => subcategoryStore.managers[id]);
      return (
        <SubcategoryItem
          key={subcategory.id}
          subcategory={subcategory}
          managers={managersInfo}
          onSubcategoryClick={onSubcategoryClick}
          isSubcategoryExpanded={expandedSubcategories[subcategory.id]}
        />
      );
    };

    const subcategories = map(
      sortBy(subcategoryStore.subcategories, 'name'),
      buildSubcategoryItem,
    );

    return subcategories.length === 0 ? (
      <EmptySearch message={TEXT_STRINGS.EMPTY_SEARCH} />
    ) : (
      subcategories
    );
  }

  render() {
    const {
      subcategoryStore,
      onSetSubcategoriesSearchTerm,
      subcategoriesSearchTerm,
      onSelectPage,
    } = this.props;

    const { loaded, pageCount, currentPage } = subcategoryStore;

    return loaded ? (
      <SubcategoryList
        subcategories={this.renderResults()}
        onSetSubcategoriesSearchTerm={onSetSubcategoriesSearchTerm}
        subcategoriesSearchTerm={subcategoriesSearchTerm}
        pageCount={pageCount}
        currentPage={currentPage}
        selectPage={onSelectPage}
      />
    ) : (
      <Loader name="Subcategories" />
    );
  }
}

export default SubcategoryListContainer;
