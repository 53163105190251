/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import URLRouter from 'Components/URLRouter/URLRouter';
import SnackbarContainer from 'Components/Snackbar/SnackbarContainer';
import AlertDialogConnector from 'Components/AlertDialog/AlertDialogConnector';
import LogRocket from 'logrocket';
import config from 'config';
import Loader from 'Components/Loader/Loader';
import { Auth0Provider, useAuth0 } from 'Components/Auth0/react-auth0-spa';
import Error from 'Components/Error/Error';
import { ThemeProvider } from '@material-ui/styles';
import { defaultTheme } from '@mvf/product-components';
import { loadStateAndMakeStore } from './Store/Store';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './Less/imports.less';
import { isLogRocketEnabled } from './config/toggles';
import history from './utils/history';
import alertIcon from '../public/img/alert-icon.svg';
import arrowDownWhite from '../public/img/arrow-down-white.svg';
import chevronDown from '../public/img/chevron-down.svg';
import emptySearchIcon from '../public/img/empty-search-icon.svg';
import favicon from '../public/img/favicon.ico';
import iconPause from '../public/img/icon-pause.svg';
import iconPlay from '../public/img/icon-play.svg';
import iconSearchButton from '../public/img/icon-search-button.svg';
import iconStop from '../public/img/icon-stop.svg';
import loadingIcon from '../public/img/loading-icon.svg';
import mvfLogo from '../public/img/mvf-logo.png';
import schedulingGroup from '../public/img/scheduling-group.svg';
import subcategoryGroup from '../public/img/subcategory-group.svg';
import whiteDropdown from '../public/img/white-dropdown.svg';
import keyImage from '../public/img/key.png';

if (isLogRocketEnabled()) {
  LogRocket.init('mvf/mercury');
}

const store = loadStateAndMakeStore();

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

const App = () => {
  const { loading } = useAuth0();

  const isAuthorized = !window.location.search.includes('error=unauthorized');

  if (!isAuthorized) {
    const params = new URL(window.location).searchParams;
    const message = params.get('error_description');
    return <Error message={message} type="defult" />;
  }

  if (loading) {
    return <Loader name="Mercury" />;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <SnackbarContainer />
        <AlertDialogConnector />
        <URLRouter />
      </ThemeProvider>
    </Provider>
  );
};

const renderApp = () => {
  if (typeof window !== 'undefined') {
    ReactDOM.render(
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        client_id={config.AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>,
      document.getElementById('root'),
    );
  }
};

export default renderApp;
