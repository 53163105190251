export const ACTIONS = {
  saveLimits: 'LIMITS_SAVED',
  resetLimits: 'LIMITS_RESET',
  openLimitsModal: 'LIMITS_MODAL_OPENED',
  closeLimitsModal: 'LIMITS_MODAL_CLOSED',
  changeLimit: 'LIMIT_CHANGED',
  setIgnoreSubcategoryLimits: 'SET_IGNORE_SUBCATEGORY_LIMITS',
};

export const REQUEST_ACTIONS = {
  getLimitsSuccess: 'GET_LIMITS_SUCCEEDED',
  getLimitsFailure: 'GET_LIMITS_FAILED',
  createLimitsSuccess: 'CREATE_LIMITS_SUCCEEDED',
  createLimitsFailure: 'CREATE_LIMITS_FAILED',
  saveLimitsSuccess: 'SAVE_LIMITS_SUCCEEDED',
  saveLimitsFailure: 'SAVE_LIMITS_FAILED',
};
