import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

const SchedulerToggle = ({ onScheduleToggle, isScheduled }) => (
  <div className="toggle-switch">
    <Toggle onChange={onScheduleToggle} checked={isScheduled} icons={false} />
  </div>
);

SchedulerToggle.propTypes = {
  isScheduled: PropTypes.bool.isRequired,
  onScheduleToggle: PropTypes.func.isRequired,
};

export default SchedulerToggle;
