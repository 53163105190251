import React from 'react';
import PropTypes from 'prop-types';

import Timezone from './Timezone';

const TimezoneList = (subcategoryId, response) =>
  response.data.timezones.map(timezone => (
    <Timezone
      key={timezone.name}
      timezone={timezone}
      subcategoryId={subcategoryId}
    />
  ));

TimezoneList.propTypes = {
  subcategoryId: PropTypes.number.isRequired,
  response: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default TimezoneList;
