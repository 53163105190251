import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as ManagerApi from '../../Api/GraphQL/ManagerApi';
import { ACTIONS } from '../Actions/Actions';
import {
  getManagersFailure,
  getManagersSuccess,
} from '../Actions/ActionCreators';
import { getManagers } from '../../Shared/Selectors/ManagerSelector';

export function* fetchManagers() {
  try {
    const managers = yield select(getManagers);

    if (managers && Object.keys(managers).length > 0) {
      return;
    }

    yield put(getManagersSuccess(yield call(ManagerApi.get)));
  } catch (e) {
    yield put(getManagersFailure({ message: e.message }));
  }
}

export function* onFetchManagers() {
  yield takeEvery(ACTIONS.loadManagers, fetchManagers);
}
