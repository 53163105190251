import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import BaseDropdownItem from 'Components/Dropdown/BaseDropdownItem';
import BaseDropdownCheckboxItem from 'Components/Dropdown/BaseDropdownCheckboxItem';
import Dropdown from 'Components/Dropdown/BaseDropdown';
import { InputField, Icon } from '@mvf/product-components';
import './FilterBar.less';

const FilterBar = props => {
  const {
    setSearchTerm,
    searchTerm,
    onLoadDivisions,
    divisions,
    selectedDivision,
    onSetDivisionFilter,
    onLoadManagers,
    managers,
    isManagerSelected,
    selectManagerFilter,
    statuses,
    isStatusSelected,
    selectStatusFilter,
    clearFilters,
    divisionFilter,
    managerFilter,
    statusFilter,
    filterClearButton,
  } = props;

  const dropdownHeader = selectedDivision || 'Division';

  const renderDivisionFilter = divisionFilter ? (
    <div className="division-selection">
      <Dropdown dropdownHeader={dropdownHeader} onMenuMount={onLoadDivisions}>
        {map(divisions, division => (
          <BaseDropdownItem
            key={division}
            name={division}
            handleClick={() => onSetDivisionFilter(division)}
          />
        ))}
      </Dropdown>
    </div>
  ) : null;

  const renderManagerFilter = managerFilter ? (
    <div className="manager-selection">
      <Dropdown dropdownHeader="Manager" onMenuMount={onLoadManagers}>
        {map(managers, manager => (
          <BaseDropdownCheckboxItem
            id={manager.email}
            key={manager.email}
            name={manager.fullName}
            checked={isManagerSelected(manager.email)}
            handleChange={selectManagerFilter}
          />
        ))}
      </Dropdown>
    </div>
  ) : null;

  const renderStatusFilter = statusFilter ? (
    <div className="status-selection">
      <Dropdown dropdownHeader="Status">
        {map(statuses, status => (
          <BaseDropdownCheckboxItem
            id={status.name}
            key={status.name}
            name={status.displayName}
            checked={isStatusSelected(status.name)}
            handleChange={selectStatusFilter}
          />
        ))}
      </Dropdown>
    </div>
  ) : null;

  const renderClearFiltersButton = filterClearButton ? (
    <div className="filter-bar__clear-wrapper">
      <button
        type="button"
        className="filter-bar__clear"
        onClick={clearFilters}
      >
        x
      </button>
    </div>
  ) : null;

  return (
    <div className="filter-bar">
      <InputField
        onChange={setSearchTerm}
        placeholder="Search"
        value={searchTerm}
        icon={Icon.SEARCH}
      />
      {renderDivisionFilter}
      {renderManagerFilter}
      {renderStatusFilter}
      {renderClearFiltersButton}
    </div>
  );
};

FilterBar.defaultProps = {
  divisionFilter: false,
  managerFilter: false,
  statusFilter: false,
  filterClearButton: false,
  managers: [],
  onLoadManagers: null,
};

FilterBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onLoadDivisions: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDivision: PropTypes.string.isRequired,
  onSetDivisionFilter: PropTypes.func.isRequired,
  onLoadManagers: PropTypes.func,
  managers: PropTypes.arrayOf(PropTypes.object),
  isManagerSelected: PropTypes.func.isRequired,
  selectManagerFilter: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  isStatusSelected: PropTypes.func.isRequired,
  selectStatusFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  divisionFilter: PropTypes.bool,
  managerFilter: PropTypes.bool,
  statusFilter: PropTypes.bool,
  filterClearButton: PropTypes.bool,
};

export default FilterBar;
