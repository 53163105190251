import PropTypes from 'prop-types';

const statusesProptypes = PropTypes.arrayOf(
  PropTypes.shape({
    count: PropTypes.number,
    status: PropTypes.string,
  }),
);

export default statusesProptypes;
