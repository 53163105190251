import React, { Fragment, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { decode } from 'jsonwebtoken';
import LOCAL_STORAGE from 'Shared/Constants/LocalStorage';
import { ACCESS_TOKEN_POLLING_INTERVAL } from 'Shared/Constants/Polling';
import LogRocket from 'logrocket';
import { useAuth0 } from '../Auth0/react-auth0-spa';
import config from '../../config';
import Logout from './Logout';
import Login from './Login';

const SecureRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated, getTokenSilently, user } = useAuth0();

  const isTokenExpired = () => {
    const tokenExpiresAt = localStorage.getItem(LOCAL_STORAGE.tokenExpiresAt);
    return (
      tokenExpiresAt === null ||
      new Date(tokenExpiresAt).getTime() - 240 * 1000 <= new Date().getTime()
    );
  };

  const [accessTokenStored, setAccessTokenStored] = useState(!isTokenExpired());

  useEffect(() => {
    if (isAuthenticated) {
      LogRocket.identify(user.sub, { email: user.email });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('tokenExpiresAt');
    const interval = setInterval(() => {
      if (isTokenExpired() !== accessTokenStored) {
        setAccessTokenStored(!isTokenExpired());
      }
    }, ACCESS_TOKEN_POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const storeAccessToken = async () => {
    try {
      const accessToken = await getTokenSilently({
        response_type: config.AUTH0_RESPONSE_TYPE,
        audience: config.AUTH0_AUDIENCE,
      });

      const decoded = decode(accessToken, { complete: true });
      const expiresAt = new Date(decoded.payload.exp * 1000);

      localStorage.setItem(LOCAL_STORAGE.accessToken, accessToken);
      localStorage.setItem(LOCAL_STORAGE.tokenExpiresAt, expiresAt);

      setAccessTokenStored(true);
    } catch (error) {
      setAccessTokenStored(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !accessTokenStored) {
      storeAccessToken();
    }
  }, [isAuthenticated, accessTokenStored]);

  const render = props =>
    isAuthenticated === true && accessTokenStored === true ? (
      <Fragment>
        <Logout />
        <Component {...props} />
      </Fragment>
    ) : (
      <Login />
    );

  return <Route path={path} render={render} {...rest} />;
};

SecureRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.node.isRequired,
};

export default SecureRoute;
