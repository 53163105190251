export const SUBCATEGORY_DROPDOWN_ACTIONS = {
  filterSubcategoriesForDropdown: 'FILTER_SUBCATEGORIES_FOR_DROPDOWN',
  setActiveSubcategory: 'SET_ACTIVE_SUBCATEGORY',
};

export const SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS = {
  getSubcategorySuccess: 'GET_SUBCATEGORY_SUCCESS',
  getSubcategoryFailure: 'GET_SUBCATEGORY_FAILURE',
  getSubcategoriesForDropdownSuccess:
    'GET_SUBCATEGORIES_FOR_DROPDOWN_SUCCEEDED',
  getSubcategoriesForDropdownFailure: 'GET_SUBCATEGORIES_FOR_DROPDOWN_FAILED',
};
