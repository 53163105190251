import { normalize, schema } from 'normalizr';
import {
  SUBCATEGORY_DROPDOWN_ACTIONS,
  SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS,
} from 'Features/SubcategoryDivisionPicker/store/subcategoryDivisionPickerActions';

export const initialState = () => ({
  subcategories: {},
  searchTerm: '',
});

const subcategoryDropdownReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS.getSubcategoriesForDropdownSuccess: {
      const subcategoryDropdownEntity = new schema.Entity('subcategories');
      const subcategoryDropdownSchema = new schema.Array(
        subcategoryDropdownEntity,
      );
      const { subcategories } = action.payload;
      const normalized = normalize(subcategories, subcategoryDropdownSchema);
      const { subcategories: normalizedSubcategories } = normalized.entities;

      return {
        ...state,
        subcategories: normalizedSubcategories,
      };
    }

    case SUBCATEGORY_DROPDOWN_ACTIONS.filterSubcategoriesForDropdown: {
      const { filter } = action.payload;
      return {
        ...state,
        searchTerm: filter,
      };
    }

    default:
      return state;
  }
};

export default subcategoryDropdownReducer;
