import React from 'react';
import PropTypes from 'prop-types';

const SchedulingGroupManagerFooter = props => {
  const {
    enableFooterButtons,
    cancelDraftSchedulingGroups,
    saveSchedulingGroups,
  } = props;
  return (
    <div className="scheduling-group-manager-footer">
      <button
        type="button"
        className="action-secondary"
        disabled={!enableFooterButtons}
        onClick={cancelDraftSchedulingGroups}
      >
        Cancel
      </button>
      <button
        type="button"
        className="action-primary"
        disabled={!enableFooterButtons}
        onClick={saveSchedulingGroups}
      >
        Save Changes
      </button>
    </div>
  );
};

SchedulingGroupManagerFooter.propTypes = {
  enableFooterButtons: PropTypes.bool.isRequired,
  cancelDraftSchedulingGroups: PropTypes.func.isRequired,
  saveSchedulingGroups: PropTypes.func.isRequired,
};

export default SchedulingGroupManagerFooter;
