import React from 'react';

const Error = () => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M15.086 13.817L10.792 10l4.294-3.817a.527.527 0 0 0-.699-.787L10 9.296l-4.387-3.9a.526.526 0 1 0-.7.787L9.209 10l-4.294 3.817a.527.527 0 0 0 .699.787l4.387-3.9 4.387 3.9a.526.526 0 0 0 .699-.787zM10 20a9.937 9.937 0 0 1-7.072-2.928A9.93 9.93 0 0 1 0 10.002a9.937 9.937 0 0 1 2.928-7.073A9.932 9.932 0 0 1 10 0a9.934 9.934 0 0 1 7.072 2.93A9.933 9.933 0 0 1 20 10a9.933 9.933 0 0 1-2.928 7.07A9.935 9.935 0 0 1 10 20zm0-18.947c-4.934 0-8.947 4.013-8.947 8.947 0 4.934 4.013 8.947 8.947 8.947 4.934 0 8.947-4.013 8.947-8.947 0-4.934-4.013-8.947-8.947-8.947z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#FFF">
        <path d="M-45-40H65V70H-45z" />
      </g>
    </g>
  </svg>
);

export default Error;
