import {
  PLATFORM_CAMPAIGN_ACTIONS,
  PLATFORM_CAMPAIGN_REQUEST_ACTIONS,
} from './PlatformCampaignActions';

export const loadPlatformCampaigns = payload => ({
  type: PLATFORM_CAMPAIGN_ACTIONS.loadPlatformCampaigns,
  payload,
});

export const getPlatformCampaignsSuccess = payload => ({
  type: PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsSuccess,
  payload,
});

export const getPlatformCampaignsFailure = payload => ({
  type: PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsFailure,
  payload,
});

export const updatePlatformCampaignStatus = payload => ({
  type: PLATFORM_CAMPAIGN_ACTIONS.updatePlatformCampaignStatus,
  payload,
});

export const updatePlatformCampaignStatusSuccess = () => ({
  type: PLATFORM_CAMPAIGN_REQUEST_ACTIONS.updatePlatformCampaignStatusSuccess,
});

export const updatePlatformCampaignStatusFailure = payload => ({
  type: PLATFORM_CAMPAIGN_REQUEST_ACTIONS.updatePlatformCampaignStatusFailure,
  payload,
});
