import { normalize, schema } from 'normalizr';
import SHARED_ACTIONS from 'Shared/SharedActions';
import {
  SUBCATEGORYLIST_ACTIONS,
  SUBCATEGORYLIST_REQUEST_ACTIONS,
} from './subcategoryListActions';
import { ACTIONS } from '../../Store/Actions/Actions';

export const initialSubcategoryState = () => ({
  subcategories: {},
  managers: {},
  loaded: false,
  error: false,
  searchTerm: '',
  pageCount: 1,
  currentPage: 1,
});

const subcategoriesReducer = (state = initialSubcategoryState(), action) => {
  const manager = new schema.Entity('managers', {}, { idAttribute: 'email' });

  const subcategory = new schema.Entity('subcategories', {
    managers: [manager],
  });

  const subcategoriesSchema = new schema.Array(subcategory);

  switch (action.type) {
    case SUBCATEGORYLIST_REQUEST_ACTIONS.getSubcategoriesSuccess: {
      const { subcategories, pageCount } = action.payload;
      const normalized = normalize(subcategories, subcategoriesSchema);

      const {
        subcategories: normalizedSubcategories,
        managers: normalizedManagers,
      } = normalized.entities;

      return {
        ...state,
        subcategories: normalizedSubcategories,
        managers: normalizedManagers || {},
        loaded: true,
        pageCount,
      };
    }

    case SUBCATEGORYLIST_REQUEST_ACTIONS.getSubcategoriesFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }

    case SHARED_ACTIONS.setSubcategoriesSearchTerm: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        searchTerm,
      };
    }

    case ACTIONS.clearFilters: {
      return { ...state, searchTerm: initialSubcategoryState().searchTerm };
    }

    case SUBCATEGORYLIST_ACTIONS.selectPage: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case SUBCATEGORYLIST_ACTIONS.resetCurrentPage: {
      return {
        ...state,
        currentPage: 1,
      };
    }

    case ACTIONS.loadError: {
      return {
        ...state,
        error: true,
        loaded: true,
      };
    }

    default:
      return state;
  }
};

export default subcategoriesReducer;
