import React, { Component } from 'react';
import { Dropdown } from 'reactstrap';
import PropTypes from 'prop-types';
import DropdownComponent from './DropdownComponent';
import ChevronDownIcon from './Svg/ChevronDownIcon';

class DropdownContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { dropdownHeader, menuClassName, icon, children } = this.props;

    const { dropdownOpen } = this.state;
    return (
      <Dropdown
        className={`dropdown-component ${menuClassName}`}
        isOpen={dropdownOpen}
        size="sm"
        toggle={this.toggleDropdown}
      >
        <DropdownComponent dropdownHeader={dropdownHeader} icon={icon}>
          {children}
        </DropdownComponent>
      </Dropdown>
    );
  }
}

DropdownContainer.defaultProps = {
  icon: <ChevronDownIcon />,
  dropdownHeader: null,
};

DropdownContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dropdownHeader: PropTypes.string,
  menuClassName: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default DropdownContainer;
