import React from 'react';
import PropTypes from 'prop-types';

const EmptySearch = ({ message }) => (
  <div className="empty-search">
    <h2>{message}</h2>
  </div>
);

EmptySearch.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptySearch;
