import { connect } from 'react-redux';
import FilterBarContainer from 'Features/FilterBar/FilterBarContainer';
import {
  selectStatusesFilter,
  deselectStatusesFilter,
} from 'Features/FilterBar/store/filterBarActionCreators';
import {
  getSubcategoryDivisions,
  getSelectedSubcategoryDivision,
} from 'Shared/Selectors/SubcategoryDivisionSelector';
import {
  getManagers,
  getSelectedManagerFilters,
} from 'Shared/Selectors/ManagerSelector';
import { resetCurrentPage } from 'Features/SubcategoryList/subcategoryListActionCreators';
import getSelectedStatuses from './store/filterBarSelector';
import {
  loadSubcategoryDivisions,
  setSubcategoryDivisionFilter,
  loadManagers,
  selectManagerFilter,
  deselectManagerFilter,
  clearFilters,
} from '../../Store/Actions/ActionCreators';

const mapStateToProps = (state, ownProps) => ({
  divisions: getSubcategoryDivisions(state),
  selectedDivision: getSelectedSubcategoryDivision(state),
  managers: getManagers(state),
  selectedManagers: getSelectedManagerFilters(state),
  selectedStatuses: getSelectedStatuses(state),
  searchTerm: ownProps.searchTerm,
});

const mapDispatchToProps = dispatch => ({
  onLoadDivisions: () => {
    dispatch(loadSubcategoryDivisions());
  },
  onSetDivisionFilter: division => {
    dispatch(setSubcategoryDivisionFilter({ division }));
  },
  onLoadManagers: () => {
    dispatch(loadManagers());
  },
  onSelectManagerFilter: managerEmail => {
    dispatch(selectManagerFilter({ email: managerEmail }));
  },
  onDeselectManagerFilter: managerEmail => {
    dispatch(deselectManagerFilter({ email: managerEmail }));
  },
  onSelectStatusesFilter: statuses => {
    dispatch(selectStatusesFilter(statuses));
  },
  onDeselectStatusesFilter: statuses => {
    dispatch(deselectStatusesFilter(statuses));
  },
  onClearFilters: () => {
    dispatch(clearFilters());
  },
  onResetCurrentPage: () => {
    dispatch(resetCurrentPage());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBarContainer);
