import React from 'react';

const SubcategoryListHeader = () => (
  <header>
    <div className="grid-container grid-container-headers">
      <div className="column column-subcat">
        <span>Subcat Name</span>
      </div>
      <div className="column column-schedule">
        <span>Manager</span>
      </div>
      <div className="column column-leads">
        <span>Sold Leads</span>
      </div>
      <div className="column column-conversion">
        <span>Conversions</span>
      </div>
      <div className="column column-status">
        <span>Status</span>
      </div>
      <div className="column column-action">
        <span>Actions</span>
      </div>
    </div>
  </header>
);

export default SubcategoryListHeader;
