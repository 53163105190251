import {
  SCHEDULE_ACTIONS,
  SCHEDULE_REQUEST_ACTIONS,
} from 'Features/Schedules/ScheduleActions';

// User Actions
export const saveSchedule = payload => ({
  type: SCHEDULE_ACTIONS.saveSchedule,
  payload,
});

export const resetSchedule = () => ({ type: SCHEDULE_ACTIONS.resetSchedule });

export const openScheduleModal = payload => ({
  type: SCHEDULE_ACTIONS.openScheduleModal,
  payload,
});

export const closeScheduleModal = () => ({
  type: SCHEDULE_ACTIONS.closeScheduleModal,
});

export const changeSchedule = payload => ({
  type: SCHEDULE_ACTIONS.changeSchedule,
  payload,
});

// Requests
export const updateScheduleSuccess = payload => ({
  type: SCHEDULE_REQUEST_ACTIONS.updateScheduleSuccess,
  payload,
});

export const updateScheduleFailure = payload => ({
  type: SCHEDULE_REQUEST_ACTIONS.updateScheduleFailure,
  payload,
});

export const getScheduleSuccess = payload => ({
  type: SCHEDULE_REQUEST_ACTIONS.getScheduleSuccess,
  payload,
});

export const getScheduleFailure = payload => ({
  type: SCHEDULE_REQUEST_ACTIONS.getScheduleFailure,
  payload,
});
