import React from 'react';

const RunningStatusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18">
    <path
      fill="none"
      d="M.5 2.115v13.77a1.5 1.5 0 0 0 2.427 1.18l8.763-6.886a1.5 1.5 0 0 0 0-2.358L2.927.935A1.5 1.5 0 0 0 .5 2.115z"
    />
  </svg>
);

export default RunningStatusIcon;
