import React from 'react';
import SubcategoryListConnector from 'Features/SubcategoryList/SubcategoryListConnector';

const Subcategories = () => (
  <div className="app">
    <SubcategoryListConnector />
  </div>
);

export default Subcategories;
