import { cloneDeep, keyBy, map } from 'lodash';
import { ACTIONS, REQUEST_ACTIONS } from './LimitsActions';

export const initialLimitState = () => ({
  subcategoryLimits: {},
  schedulingGroupLimits: {},
  loaded: false,
});

const updateLimitAndUnlimited = (limits, changedLimit) =>
  map(limits, limit => {
    const limitCopy = { ...limit };
    if (limitCopy.id === changedLimit.id) {
      limitCopy.limit = changedLimit.limit;
      limitCopy.unlimited = changedLimit.unlimited;
    }
    return limitCopy;
  });

const updateGroupLimits = (groups, changedLimit) =>
  map(groups, groupLimits => {
    const updatedLimits = { ...groupLimits };
    const { conversions, soldLeads } = updatedLimits;
    updatedLimits.conversions = updateLimitAndUnlimited(
      conversions,
      changedLimit,
    );
    updatedLimits.soldLeads = updateLimitAndUnlimited(soldLeads, changedLimit);
    return updatedLimits;
  });

const updateLimit = (
  changedLimit,
  subcategoryLimits,
  schedulingGroupLimits,
) => {
  const updatedSubcategoryLimits = updateGroupLimits(
    subcategoryLimits,
    changedLimit,
  );
  const updatedSchedulingGroupLimits = updateGroupLimits(
    schedulingGroupLimits,
    changedLimit,
  );
  return {
    schedulingGroupLimits: keyBy(updatedSchedulingGroupLimits, 'id'),
    subcategoryLimits: keyBy(updatedSubcategoryLimits, 'id'),
  };
};

const updateIgnoreSubcategoryLimits = (
  schedulingGroupLimits,
  schedulingGroupId,
  ignoreSubcategoryLimits,
) => {
  const updatedSchedulingGroups = map(
    schedulingGroupLimits,
    (schedulingGroup, id) =>
      parseInt(id, 0) === schedulingGroupId
        ? { ...schedulingGroup, ignoreSubcategoryLimits }
        : schedulingGroup,
  );
  return keyBy(updatedSchedulingGroups, 'id');
};

const limitReducer = (state = initialLimitState(), action) => {
  switch (action.type) {
    case REQUEST_ACTIONS.getLimitsSuccess: {
      const {
        schedulingGroupLimits,
        subcategoryLimits,
        updatedAt,
      } = action.payload;
      return {
        ...state,
        subcategoryLimits: keyBy([subcategoryLimits], 'id'),
        schedulingGroupLimits: keyBy(schedulingGroupLimits, 'id'),
        immutableLimits: {
          subcategoryLimits: keyBy([subcategoryLimits], 'id'),
          schedulingGroupLimits: keyBy(schedulingGroupLimits, 'id'),
        },
        updatedAt,
        loaded: true,
      };
    }
    case REQUEST_ACTIONS.getLimitsFailure:
    case REQUEST_ACTIONS.saveLimitsFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }
    case ACTIONS.changeLimit: {
      const changedLimit = action.payload;
      const { subcategoryLimits, schedulingGroupLimits } = updateLimit(
        changedLimit,
        cloneDeep(state.subcategoryLimits),
        cloneDeep(state.schedulingGroupLimits),
      );
      return {
        ...state,
        subcategoryLimits,
        schedulingGroupLimits,
      };
    }
    case ACTIONS.setIgnoreSubcategoryLimits: {
      const { schedulingGroupId, ignoreSubcategoryLimits } = action.payload;
      const schedulingGroupLimits = updateIgnoreSubcategoryLimits(
        state.schedulingGroupLimits,
        schedulingGroupId,
        ignoreSubcategoryLimits,
      );
      return {
        ...state,
        schedulingGroupLimits,
      };
    }
    case ACTIONS.resetLimits: {
      if (state.loaded) {
        return {
          ...state,
          subcategoryLimits: cloneDeep(state.immutableLimits.subcategoryLimits),
          schedulingGroupLimits: cloneDeep(
            state.immutableLimits.schedulingGroupLimits,
          ),
        };
      }
      return state;
    }
    case ACTIONS.closeLimitsModal: {
      return initialLimitState();
    }
    default: {
      return state;
    }
  }
};

export default limitReducer;
