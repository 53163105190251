import { css } from '@mvf/product-components';

const subcategoryListStyles = theme => ({
  pagination: css({
    display: 'flex',
    justifyContent: 'center',
    margin: theme.mvf.spacing.large,
  }),
});

export default subcategoryListStyles;
