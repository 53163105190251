import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { defaultTheme } from '@mvf/product-components';
import FilterBar from 'Features/FilterBar';
import { Pagination } from '@mvf/product-components/labs/mercury';
import SubcategoryListHeader from './SubcategoryListHeader';
import './SubcategoryList.less';
import './Grid.less';
import subcategoryListStyles from './subcategoryListStyles';

export const SubcategoryList = props => {
  const {
    subcategories,
    onSetSubcategoriesSearchTerm,
    subcategoriesSearchTerm,
    pageCount,
    currentPage,
    selectPage,
  } = props;

  const styles = subcategoryListStyles(defaultTheme);

  return (
    <Fragment>
      <div className="subcategories-list">
        {subcategories}
        <SubcategoryListHeader />
      </div>
      <div className="subcategories-filter-bar-fixed">
        <FilterBar
          setSearchTerm={onSetSubcategoriesSearchTerm}
          searchTerm={subcategoriesSearchTerm}
          divisionFilter
          managerFilter
          statusFilter
          filterClearButton
        />
      </div>
      <div className="subcategories-heading-fixed">
        <h2>Subcategories</h2>
      </div>
      <div style={styles.pagination}>
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          onChange={selectPage}
        />
      </div>
    </Fragment>
  );
};

SubcategoryList.propTypes = {
  subcategories: PropTypes.node.isRequired,
  onSetSubcategoriesSearchTerm: PropTypes.func.isRequired,
  subcategoriesSearchTerm: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectPage: PropTypes.func.isRequired,
};

export default SubcategoryList;
