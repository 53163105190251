import ACTIONS from 'Components/AlertDialog/AlertDialogActions';

export const initialState = () => ({
  alertText: '',
  alertAcceptText: '',
  alertCancelText: '',
  isOpen: false,
  alertCancel: null,
  alertAccept: null,
  multipleOptions: true,
});

const alertDialogReducer = (state = initialState(), action) => {
  switch (action.type) {
    case ACTIONS.openAlertDialog: {
      const {
        alertText,
        alertAcceptText,
        alertCancelText,
        alertCancel,
        alertAccept,
        multipleOptions,
      } = action.payload;
      return {
        ...state,
        alertText,
        alertAcceptText,
        alertCancelText,
        alertCancel,
        alertAccept,
        isOpen: true,
        multipleOptions,
      };
    }
    case ACTIONS.closeAlertDialog: {
      return initialState();
    }
    default:
      return state;
  }
};

export default alertDialogReducer;
