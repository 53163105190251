import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import './BaseDropdownCheckboxItem.less';

const BaseDropdownCheckboxItem = ({ id, name, checked, handleChange }) => (
  <div className="base-dropdown-checkbox-item">
    <label className="base-dropdown-checkbox-item__label" htmlFor={id} key={id}>
      <Toggle
        className="base-dropdown-checkbox-item__input"
        icons={false}
        id={id}
        checked={checked}
        onChange={() => {
          handleChange(id);
        }}
      />
      <span className="base-dropdown-checkbox-item__text">{name}</span>
    </label>
  </div>
);

BaseDropdownCheckboxItem.defaultProps = {
  handleChange: () => {},
};

BaseDropdownCheckboxItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

export default BaseDropdownCheckboxItem;
