import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterBar from 'Features/FilterBar/FilterBar';
import { capitalize, keys, sortBy, map } from 'lodash';
import { STATUS_FILTERS } from 'Shared/Constants/Constants';

export class FilterBarContainer extends Component {
  static propTypes = {
    onLoadDivisions: PropTypes.func.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.string),
    onSetDivisionFilter: PropTypes.func.isRequired,
    onLoadManagers: PropTypes.func.isRequired,
    managers: PropTypes.shape(),
    onSelectManagerFilter: PropTypes.func.isRequired,
    onDeselectManagerFilter: PropTypes.func.isRequired,
    onSelectStatusesFilter: PropTypes.func.isRequired,
    onDeselectStatusesFilter: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    selectedDivision: PropTypes.string.isRequired,
    selectedManagers: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    divisionFilter: PropTypes.bool,
    managerFilter: PropTypes.bool,
    statusFilter: PropTypes.bool,
    filterClearButton: PropTypes.bool,
    setSearchTerm: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    onResetCurrentPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    managers: {},
    divisions: [],
    divisionFilter: false,
    managerFilter: false,
    statusFilter: false,
    searchTerm: '',
    filterClearButton: false,
  };

  selectManagerFilter = manager => {
    const {
      onSelectManagerFilter,
      onDeselectManagerFilter,
      onResetCurrentPage,
    } = this.props;

    onResetCurrentPage();
    if (this.isManagerSelected(manager)) {
      onDeselectManagerFilter(manager);
    } else {
      onSelectManagerFilter(manager);
    }
  };

  selectStatusFilter = status => {
    const {
      onSelectStatusesFilter,
      onDeselectStatusesFilter,
      onResetCurrentPage,
    } = this.props;
    const statusIsSelected = this.isStatusSelected(status);
    onResetCurrentPage();
    if (statusIsSelected) {
      onDeselectStatusesFilter([status]);
    } else {
      onSelectStatusesFilter([status]);
    }
  };

  isManagerSelected = manager => {
    const { selectedManagers } = this.props;
    return selectedManagers.includes(manager);
  };

  isStatusSelected = status => {
    const { selectedStatuses } = this.props;
    return selectedStatuses.includes(status);
  };

  setDivisionFilter = division => {
    const { onSetDivisionFilter, onResetCurrentPage } = this.props;
    onResetCurrentPage();
    onSetDivisionFilter(division);
  };

  setSearchTerm = searchTerm => {
    const { setSearchTerm, onResetCurrentPage } = this.props;
    onResetCurrentPage();
    setSearchTerm(searchTerm);
  };

  clearFilters = () => {
    const { onClearFilters, onResetCurrentPage } = this.props;
    onResetCurrentPage();
    onClearFilters();
  };

  render() {
    const {
      onLoadDivisions,
      divisions,
      selectedDivision,
      onLoadManagers,
      managers,
      divisionFilter,
      managerFilter,
      statusFilter,
      filterClearButton,
      searchTerm,
    } = this.props;

    const managersSortedByEmail = sortBy(managers, 'email');
    const statuses = map(keys(STATUS_FILTERS), status => ({
      name: status,
      displayName: capitalize(status),
    }));

    return (
      <FilterBar
        selectedDivision={selectedDivision}
        onLoadDivisions={onLoadDivisions}
        divisions={divisions}
        onSetDivisionFilter={this.setDivisionFilter}
        onLoadManagers={onLoadManagers}
        managers={managersSortedByEmail}
        isManagerSelected={this.isManagerSelected}
        selectManagerFilter={this.selectManagerFilter}
        statuses={statuses}
        isStatusSelected={this.isStatusSelected}
        selectStatusFilter={this.selectStatusFilter}
        clearFilters={this.clearFilters}
        divisionFilter={divisionFilter}
        managerFilter={managerFilter}
        statusFilter={statusFilter}
        filterClearButton={filterClearButton}
        setSearchTerm={this.setSearchTerm}
        searchTerm={searchTerm}
      />
    );
  }
}

export default FilterBarContainer;
