import ACTIONS from './SnackbarActions';

export const createSnackbar = payload => ({
  type: ACTIONS.createSnackbar,
  payload,
});

export const destroySnackbar = payload => ({
  type: ACTIONS.destroySnackbar,
  payload,
});
