import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { map } from 'lodash';
import { createSnackbar } from 'Components/Snackbar/SnackbarActionCreators';
import {
  openAlertDialog,
  closeAlertDialog,
} from 'Components/AlertDialog/AlertDialogActionCreators';
import ALERT_DIALOG_ACTIONS from 'Components/AlertDialog/AlertDialogActions';
import * as SEVERITY from 'Shared/Constants/Severity';
import { getActiveSchedulingGroup } from 'Shared/Selectors/SchedulingGroupSelector';
import {
  getSchedulingGroupsApi,
  getAdmiralCampaignsApi,
  createSchedulingGroups,
  updateSchedulingGroups,
  deleteSchedulingGroups,
} from './SchedulingGroupManagerApi';
import { GROUP_MANAGER_ACTIONS } from './SchedulingGroupManagerActions';
import {
  getSchedulingGroupsForGroupManagerSuccess,
  getSchedulingGroupsForGroupManagerFailure,
  getAdmiralCampaignsSuccess,
  getAdmiralCampaignsFailure,
  saveSchedulingGroupsSuccess,
  saveSchedulingGroupsFailure,
  loadAdmiralCampaigns,
  loadSchedulingGroupsForGroupManager,
  updateSchedulingGroupsSuccess,
  updateSchedulingGroupsFailure,
  moveToSchedulingGroup,
  deleteSchedulingGroupSuccess,
  deleteSchedulingGroupsSuccess,
  deleteSchedulingGroupsFailure,
  setActiveSchedulingGroup,
} from './SchedulingGroupManagerActionCreators';
import {
  getSchedulingGroupAdmiralCampaigns,
  getDefaultSchedulingGroupId,
  getAdmiralCampaigns,
  getAdmiralCampaignTimestamp,
  getActiveSubcategoryId,
  getNewSchedulingGroups,
  getUpdatedSchedulingGroups,
  getDeletedSchedulingGroups,
  getActiveSubcategorySchedulingGroupsUpdated,
  getSchedulingGroupsNamesAndAdmiralCampaignIds,
  getSchedulingGroupsIdsAndNamesAndAdmiralCampaignIds,
} from './SchedulingGroupManagerSelector';
import { countObjectWithSchedulingGroupId } from './SchedulingGroupManagerHelper';
import { getSelectedDivision } from '../../Shared/Selectors/DivisionSelector';

const buildSnackbar = (message, severity) => ({
  snackbar: {
    message,
    autoHideDuration: 4000,
    severity,
  },
});

export function* fetchSchedulingGroupsForGroupManager() {
  const subcategoryId = yield select(getActiveSubcategoryId);
  const division = yield select(getSelectedDivision);
  const managed = false;
  try {
    const response = yield call(getSchedulingGroupsApi, {
      id: subcategoryId,
      filters: { divisions: [division], managed },
    });
    const { schedulingGroups } = response.data;
    const schedulingGroupIds = schedulingGroups.map(
      schedulingGroup => schedulingGroup.id,
    );
    yield put(getSchedulingGroupsForGroupManagerSuccess(schedulingGroups));
    yield put(loadAdmiralCampaigns({ schedulingGroupIds }));
  } catch (e) {
    yield put(
      getSchedulingGroupsForGroupManagerFailure({
        message: e.message,
      }),
    );
  }
}

export function* onFetchSchedulingGroupsForGroupManager() {
  yield all([
    takeEvery(
      GROUP_MANAGER_ACTIONS.loadSchedulingGroupsForGroupManager,
      fetchSchedulingGroupsForGroupManager,
    ),
    takeEvery(
      GROUP_MANAGER_ACTIONS.cancelNewSchedulingGroups,
      fetchSchedulingGroupsForGroupManager,
    ),
  ]);
}

function* fetchAdmiralCampaigns(action) {
  const { schedulingGroupIds } = action.payload;
  if (!schedulingGroupIds) {
    yield;
  } else {
    try {
      const response = yield call(getAdmiralCampaignsApi, schedulingGroupIds);
      const admiralCampaigns = response.data;
      yield put(getAdmiralCampaignsSuccess(admiralCampaigns));
    } catch (e) {
      yield put(getAdmiralCampaignsFailure(e.message));
    }
  }
}

export function* onFetchAdmiralCampaigns() {
  yield takeEvery(
    GROUP_MANAGER_ACTIONS.loadAdmiralCampaigns,
    fetchAdmiralCampaigns,
  );
}

function* moveToDefaultGroup(admiralCampaigns) {
  yield put(closeAlertDialog());
  const defaultGroupId = yield select(getDefaultSchedulingGroupId);
  yield put(
    moveToSchedulingGroup({
      selectedAdmiralCampaigns: admiralCampaigns,
      schedulingGroupId: defaultGroupId,
    }),
  );
}

function* onOpenAlertDialog(admiralCampaigns, alertAccept, alertCancel) {
  yield put(
    openAlertDialog({
      alertText:
        'Deleting this group will move the associated Campaign IDs back to the default. Are you sure you want to continue?',
      alertAccept,
      alertCancel,
    }),
  );
  const confirmation = yield take(ALERT_DIALOG_ACTIONS.confirmYes);
  if (confirmation) {
    yield moveToDefaultGroup(admiralCampaigns);
  }
}

function* setActiveSchedulingGroupToDefault() {
  const defaultGroupId = yield select(getDefaultSchedulingGroupId);
  const admiralCampaigns = yield select(getAdmiralCampaigns);
  yield put(
    setActiveSchedulingGroup({
      schedulingGroup: {
        id: defaultGroupId,
        name: 'Default',
        numberOfCampaigns: countObjectWithSchedulingGroupId(
          admiralCampaigns,
          defaultGroupId,
        ),
      },
    }),
  );
}
function* deleteSchedulingGroup(action) {
  const { deletedGroupId, alertAccept, alertCancel } = action.payload;
  const deletedGroupAdmiralCampaigns = yield select(
    getSchedulingGroupAdmiralCampaigns,
    deletedGroupId,
  );
  if (deletedGroupAdmiralCampaigns.length > 0) {
    yield onOpenAlertDialog(
      deletedGroupAdmiralCampaigns,
      alertAccept,
      alertCancel,
    );
  }

  yield put(deleteSchedulingGroupSuccess(deletedGroupId));
  const activeSchedulingGroup = yield select(getActiveSchedulingGroup);
  if (activeSchedulingGroup.id === deletedGroupId) {
    yield setActiveSchedulingGroupToDefault();
  }
}

export function* onDeleteSchedulingGroup() {
  yield takeEvery(
    GROUP_MANAGER_ACTIONS.deleteSchedulingGroup,
    deleteSchedulingGroup,
  );
}

function* createGroups(subcategoryId, timestamp, newGroups, division) {
  const schedulingGroups = yield select(
    getSchedulingGroupsNamesAndAdmiralCampaignIds,
    newGroups,
  );
  try {
    yield call(createSchedulingGroups, {
      subcategoryId,
      timestamp,
      schedulingGroups,
      division,
    });
    yield put(saveSchedulingGroupsSuccess());
    yield put(
      createSnackbar(
        buildSnackbar(
          'Created group changes have been saved.',
          SEVERITY.SUCCESS,
        ),
      ),
    );
  } catch (e) {
    yield put(saveSchedulingGroupsFailure({ message: e.message }));
    yield put(
      createSnackbar(
        buildSnackbar(
          'There was a problem with your create action, please refresh the page.',
          SEVERITY.ERROR,
        ),
      ),
    );
    throw e;
  }
}

function* updateGroups(subcategoryId, timestamp, updatedGroups, division) {
  const schedulingGroups = yield select(
    getSchedulingGroupsIdsAndNamesAndAdmiralCampaignIds,
    updatedGroups,
  );
  try {
    yield call(updateSchedulingGroups, {
      subcategoryId,
      timestamp,
      schedulingGroups,
      division,
    });
    yield put(updateSchedulingGroupsSuccess());
    yield put(
      createSnackbar(
        buildSnackbar(
          'Updated group changes have been saved.',
          SEVERITY.SUCCESS,
        ),
      ),
    );
  } catch (e) {
    yield put(updateSchedulingGroupsFailure({ message: e.message }));
    yield put(
      createSnackbar(
        buildSnackbar(
          'There was a problem with your update action, please refresh the page.',
          SEVERITY.ERROR,
        ),
      ),
    );
    throw e;
  }
}

function* deleteGroups(subcategoryId, timestamp, deletedGroups, division) {
  const schedulingGroups = map(deletedGroups, group => ({
    id: group.id,
    name: group.name,
  }));
  try {
    yield call(deleteSchedulingGroups, {
      subcategoryId,
      timestamp,
      schedulingGroups,
      division,
    });
    yield put(deleteSchedulingGroupsSuccess());
    yield put(
      createSnackbar(
        buildSnackbar(
          'Deleted group changes have been saved.',
          SEVERITY.SUCCESS,
        ),
      ),
    );
  } catch (e) {
    yield put(deleteSchedulingGroupsFailure({ message: e.message }));
    yield put(
      createSnackbar(
        buildSnackbar(
          'There was a problem with your delete action, please refresh the page.',
          SEVERITY.ERROR,
        ),
      ),
    );
    throw e;
  }
}

function* createUpdateDeleteSchedulingGroups() {
  const newSchedulingGroups = yield select(getNewSchedulingGroups);
  const updatedSchedulingGroups = yield select(getUpdatedSchedulingGroups);
  const deletedSchedulingGroups = yield select(getDeletedSchedulingGroups);
  const subcategoryId = yield select(getActiveSubcategoryId);
  const timestamp = yield select(getAdmiralCampaignTimestamp);
  const division = yield select(getSelectedDivision);
  const schedulingGroupsUpdated = yield select(
    getActiveSubcategorySchedulingGroupsUpdated,
  );

  if (!subcategoryId || !timestamp) {
    return;
  }

  if (newSchedulingGroups instanceof Array && newSchedulingGroups.length) {
    try {
      yield createGroups(
        subcategoryId,
        timestamp,
        newSchedulingGroups,
        division,
      );
    } catch (e) {
      return;
    }
  }

  if (
    schedulingGroupsUpdated &&
    updatedSchedulingGroups instanceof Array &&
    updatedSchedulingGroups.length
  ) {
    try {
      yield updateGroups(
        subcategoryId,
        timestamp,
        updatedSchedulingGroups,
        division,
      );
    } catch (e) {
      return;
    }
  }

  if (
    deletedSchedulingGroups instanceof Array &&
    deletedSchedulingGroups.length
  ) {
    try {
      yield deleteGroups(
        subcategoryId,
        timestamp,
        deletedSchedulingGroups,
        division,
      );
    } catch (e) {
      return;
    }
  }
  yield put(loadSchedulingGroupsForGroupManager());
}

export function* onSaveSchedulingGroups() {
  yield takeEvery(
    GROUP_MANAGER_ACTIONS.saveSchedulingGroups,
    createUpdateDeleteSchedulingGroups,
  );
}
