import { REQUEST_ACTIONS } from '../Actions/Actions';

export const initialState = () => ({
  data: {},
  loaded: false,
});

const timezonesReducer = (state = initialState(), action) => {
  switch (action.type) {
    case REQUEST_ACTIONS.getTimezonesSuccess: {
      const { timezones } = action.payload.data;
      const newData = Object.assign({}, state.data, {
        timezones,
      });
      return {
        ...state,
        data: newData,
        loaded: true,
      };
    }

    case REQUEST_ACTIONS.getTimezonesFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }
    default:
      return state;
  }
};

export default timezonesReducer;
