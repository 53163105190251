import React from 'react';
import PropTypes from 'prop-types';
import { convertSpacesToHyphens } from 'Shared/Formatters/Functions';

const AccordionTitle = props => {
  const { group, title } = props;
  const className = convertSpacesToHyphens(group).toLowerCase();
  return (
    <h5>
      {title}
      <strong className={className}>{group}</strong>
    </h5>
  );
};

AccordionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
};

export default AccordionTitle;
