import { normalize, schema } from 'normalizr';
import SHARED_ACTIONS from 'Shared/SharedActions';
import { PLATFORM_CAMPAIGN_REQUEST_ACTIONS } from './PlatformCampaignActions';

export const initialState = () => ({
  data: {},
  loaded: false,
  searchTerm: '',
});

const platformCampaignEntity = new schema.Entity('platformCampaigns');
const platformCampaignSchema = {
  platformCampaigns: [platformCampaignEntity],
};

export const platformCampaignReducer = (state = initialState(), action) => {
  switch (action.type) {
    case PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsSuccess: {
      const { platformCampaigns } = action.payload;
      const normalizedData = normalize(
        { platformCampaigns },
        platformCampaignSchema,
      );
      return {
        ...state,
        data: normalizedData.entities.platformCampaigns,
        loaded: true,
      };
    }
    case PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsFailure: {
      const { message } = action.payload;
      return {
        ...state,
        errorMessage: message,
        loaded: true,
      };
    }
    case PLATFORM_CAMPAIGN_REQUEST_ACTIONS.updatePlatformCampaignStatusFailure: {
      const message = action.payload;
      return {
        ...state,
        errorMessage: message,
        loaded: true,
      };
    }
    case SHARED_ACTIONS.setCampaignNameSearchTerm: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        searchTerm,
        loaded: true,
      };
    }
    default:
      return state;
  }
};
