import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress, limit, unlimited, color }) => {
  let bar;
  const limitSign = unlimited ? '∞' : limit;
  const text = <span>{`${progress} / ${limitSign}`}</span>;

  if (!unlimited) {
    const isInProgress = progress < limit;
    const style = {
      width: `${isInProgress ? 100 * (progress / limit) : 100}%`,
    };
    const colorCss = `progress-bar-background--color-${
      isInProgress ? color : 'full'
    }`;
    const progressClass = `progress-bar-foreground ${colorCss}`;

    bar = (
      <Fragment>
        <div className={progressClass} style={style} />
        {text}
      </Fragment>
    );
  }

  return <div className="progress-bar-background">{bar || text}</div>;
};

ProgressBar.defaultProps = {
  unlimited: false,
  color: 'default',
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  unlimited: PropTypes.bool,
  color: PropTypes.string,
};

export default ProgressBar;
