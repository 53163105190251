import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronRight from './Svg/ChevronRight';
import ChevronLeft from './Svg/ChevronLeft';

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      sidebarOpen: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  toggleSidebar() {
    const { sidebarOpen } = this.state;
    this.setState({
      sidebarOpen: !sidebarOpen,
    });
  }

  closeSidebar() {
    this.setState({
      sidebarOpen: false,
    });
  }

  renderSidebarData(sidebarData) {
    return sidebarData.map(data => (
      <li key={data.id} className="sidebar-list-item">
        <NavLink
          className="sidebar-item"
          activeClassName="sidebar-item-active"
          to={data.navigation}
          exact
          onClick={this.closeSidebar}
        >
          <div className="sidebar-image">{data.image}</div>
          <span className="sidebar-label">{data.displayName}</span>
        </NavLink>
      </li>
    ));
  }

  render() {
    const { sidebarData } = this.props;
    const { sidebarOpen } = this.state;
    const menuClassName = sidebarOpen ? 'opened' : 'closed';
    return (
      <nav key="sidebar" id="side-menu" className={`menu-${menuClassName}`}>
        <div className="sidebar-container">
          <ul className="side-menu--links">
            {this.renderSidebarData(sidebarData)}
          </ul>
          <button
            type="button"
            className="sidebar-expandable-button"
            onClick={this.toggleSidebar}
          >
            <div className="button-image">
              {(sidebarOpen && <ChevronLeft />) || <ChevronRight />}
            </div>
            <div className="button-text">Collapse</div>
          </button>
        </div>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  sidebarData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      navigation: PropTypes.string,
    }),
  ).isRequired,
};

export default Sidebar;
