import { concat, filter } from 'lodash';
import FILTER_BAR_ACTIONS from 'Features/FilterBar/store/filterBarActions';
import { ACTIONS } from '../../../Store/Actions/Actions';

export const initialState = () => [];

const filterBarReducer = (state = initialState(), action) => {
  switch (action.type) {
    case FILTER_BAR_ACTIONS.selectStatusesFilter: {
      const selectedStatuses = action.payload;
      return concat(state, selectedStatuses);
    }
    case FILTER_BAR_ACTIONS.deselectStatusesFilter: {
      const deselectedStatuses = action.payload;
      return filter(state, status => !deselectedStatuses.includes(status));
    }
    case ACTIONS.clearFilters: {
      return initialState();
    }
    default:
      return state;
  }
};

export default filterBarReducer;
