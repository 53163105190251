import { ACTIONS, REQUEST_ACTIONS } from '../Actions/Actions';

export const initialState = () => ({
  data: {},
  loaded: false,
});

const timezoneReducer = (state = initialState(), action) => {
  switch (action.type) {
    case REQUEST_ACTIONS.getTimezoneSuccess: {
      const { id: subcategoryId, timezone } = action.payload;
      const newData = Object.assign({}, state.data, {
        subcategoryId,
        timezone,
      });
      return Object.assign({}, state, {
        data: newData,
        loaded: true,
      });
    }

    case REQUEST_ACTIONS.getTimezoneFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }

    case ACTIONS.changeTimezone: {
      const { id: subcategoryId, timezone } = action.payload;
      const newData = Object.assign({}, state.data, {
        subcategoryId,
        timezone,
      });
      return Object.assign({}, state, {
        data: newData,
        loaded: true,
      });
    }
    default:
      return state;
  }
};

export default timezoneReducer;
