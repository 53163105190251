import { ACTIONS, REQUEST_ACTIONS } from './Actions';

// User actions
export const loadError = () => ({
  type: ACTIONS.loadError,
});
export const selectManagerFilter = payload => ({
  type: ACTIONS.selectManagerFilter,
  payload,
});
export const deselectManagerFilter = payload => ({
  type: ACTIONS.deselectManagerFilter,
  payload,
});
export const clearFilters = () => ({ type: ACTIONS.clearFilters });
export const loadManagers = () => ({ type: ACTIONS.loadManagers });
export const loadSubcategoryDivisions = () => ({
  type: ACTIONS.loadSubcategoryDivisions,
});
export const loadDivisions = () => ({ type: ACTIONS.loadDivisions });
export const setSubcategoryDivisionFilter = payload => ({
  type: ACTIONS.setSubcategoryDivisionFilter,
  payload,
});
export const setDivisionFilter = payload => ({
  type: ACTIONS.setDivisionFilter,
  payload,
});
export const getTimezone = payload => ({
  type: ACTIONS.getTimezone,
  payload,
});
export const getTimezones = payload => ({
  type: ACTIONS.getTimezones,
  payload,
});
export const changeTimezone = payload => ({
  type: ACTIONS.changeTimezone,
  payload,
});
export const updateTimezone = payload => ({
  type: ACTIONS.updateTimezone,
  payload,
});

// Related to requests
export const getManagersSuccess = payload => ({
  type: REQUEST_ACTIONS.getManagersSuccess,
  payload,
});
export const getManagersFailure = payload => ({
  type: REQUEST_ACTIONS.getManagersFailure,
  payload,
});
export const getSubcategoryDivisionsSuccess = payload => ({
  type: REQUEST_ACTIONS.getSubcategoryDivisionsSuccess,
  payload,
});
export const getDivisionsSuccess = payload => ({
  type: REQUEST_ACTIONS.getDivisionsSuccess,
  payload,
});
export const getSubcategoryDivisionsFailure = payload => ({
  type: REQUEST_ACTIONS.getSubcategoryDivisionsFailure,
  payload,
});
export const getDivisionsFailure = payload => ({
  type: REQUEST_ACTIONS.getDivisionsFailure,
  payload,
});
export const getTimezonesSuccess = payload => ({
  type: REQUEST_ACTIONS.getTimezonesSuccess,
  payload,
});
export const getTimezonesFailure = payload => ({
  type: REQUEST_ACTIONS.getTimezonesFailure,
  payload,
});
export const getTimezoneSuccess = payload => ({
  type: REQUEST_ACTIONS.getTimezoneSuccess,
  payload,
});
export const getTimezoneFailure = payload => ({
  type: REQUEST_ACTIONS.getTimezoneFailure,
  payload,
});
