const { hostname } = window.location;

export const isStaging = () =>
  hostname.includes('mercury-staging.mvfglobal.com') ||
  hostname.includes('stg');

export const isProduction = () =>
  hostname.includes('mercury.mvfglobal.com') || hostname.includes('prd');

export const isDev = () =>
  hostname.includes('dev') || hostname.includes('localhost');
export const isUsingHTTPS = () => window.location.protocol === 'https:';
