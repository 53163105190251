import SHARED_ACTIONS from './SharedActions';

export const resetSchedulingGroupsState = () => ({
  type: SHARED_ACTIONS.resetSchedulingGroupsState,
});

export const setCampaignNameSearchTerm = payload => ({
  type: SHARED_ACTIONS.setCampaignNameSearchTerm,
  payload,
});

export const resetActiveSchedulingGroupState = () => ({
  type: SHARED_ACTIONS.resetActiveSchedulingGroupState,
});

export const resetActiveSubcategoryState = () => ({
  type: SHARED_ACTIONS.resetActiveSubcategoryState,
});

export const updateSchedulingGroupToggle = payload => ({
  type: SHARED_ACTIONS.updateSchedulingGroupToggle,
  payload,
});

export const setSubcategoriesSearchTerm = payload => ({
  type: SHARED_ACTIONS.setSubcategoriesSearchTerm,
  payload,
});
