export const SCHEDULE_ACTIONS = {
  saveSchedule: 'SCHEDULE_SAVED',
  resetSchedule: 'SCHEDULE_RESET',
  openScheduleModal: 'SCHEDULE_MODAL_OPENED',
  closeScheduleModal: 'SCHEDULE_MODAL_CLOSED',
  changeSchedule: 'SCHEDULE_CHANGED',
};

export const SCHEDULE_REQUEST_ACTIONS = {
  updateScheduleSuccess: 'UPDATE_SCHEDULE_SUCCEEDED',
  updateScheduleFailure: 'UPDATE_SCHEDULE_FAILED',
  getScheduleSuccess: 'GET_SCHEDULE_SUCCEEDED',
  getScheduleFailure: 'GET_SCHEDULE_FAILED',
};
