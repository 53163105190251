import {
  SUBCATEGORY_DROPDOWN_ACTIONS,
  SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS,
} from './subcategoryDivisionPickerActions';

export const setActiveSubcategory = payload => ({
  type: SUBCATEGORY_DROPDOWN_ACTIONS.setActiveSubcategory,
  payload,
});
export const filterSubcategoriesForDropdown = payload => ({
  type: SUBCATEGORY_DROPDOWN_ACTIONS.filterSubcategoriesForDropdown,
  payload,
});

// Request Actions
export const getSubcategoriesForDropdownSuccess = payload => ({
  type: SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS.getSubcategoriesForDropdownSuccess,
  payload,
});
export const getSubcategoriesForDropdownFailure = payload => ({
  type: SUBCATEGORY_DROPDOWN_REQUEST_ACTIONS.getSubcategoriesForDropdownFailure,
  payload,
});
