import gql from 'graphql-tag';
import sendQuery from '../../Api/GraphQL/Request';

export function getPlatformCampaignsForSchedulingGroup(payload) {
  const { schedulingGroupId, subcategoryId } = payload;
  const query = gql`
    query {
      platformCampaigns: getPlatformCampaigns(
        schedulingGroupId: ${schedulingGroupId}
      ) {
        admiralCampaignId
        id
        isScheduled
        name
        manager {
          fullName
        }
        mercuryStatus
        platformStatus
        platform
        identifier
      }
      subcategory: getSubcategory(id: ${subcategoryId}) {
        id
        name
      }
      schedulingGroup: getSchedulingGroupById(id: ${schedulingGroupId}) {
        id
        name
        division
      }
    }
  `;
  return sendQuery(query, payload);
}

export function updatePlatformCampaignScheduledStatus(payload) {
  const mutation = gql`
    mutation($isScheduled: Boolean!, $platformCampaignId: Int!) {
      scheduleUpdated: updateIsScheduledByPlatformCampaignId(
        isScheduled: $isScheduled
        platformCampaignId: $platformCampaignId
      )
    }
  `;
  return sendQuery(mutation, payload);
}
