import React from 'react';
import './index.less';

const PrivacyPolicy = () => (
  <div className="privacyPolicy">
    <h1 className="privacyPolicy__heading">Privacy Policy</h1>
    <div className="privacyPolicy__content">
      <p>
        Mercury is a campaign management application which we have built at MVF.
        Our marketing teams manually create campaigns on marketing platforms
        (eg. Google Ads, Facebook Ads) and use Mercury to set rules for when
        they should be live or paused on the marketing platforms. Mercury
        integrates with the marketing platform APIs to request campaign details
        and update the status of these campaigns. No other aspects of campaign
        management are performed within Mercury.
      </p>
      <p>
        <strong>Mercury is only used by our internal marketing teams. </strong>
        Our teams log into Mercury using Auth0 single sign-on. There are no
        external users granted access to the application outside of MVF.
      </p>
      <p>
        <strong>
          Mercury does not access or store any personal user data from any of
          the marketing platforms it integrates with. Mercury only makes
          requests to marketing platform APIs to get and update marketing
          campaign data. It does not have a Facebook integration for likes,
          shares or Facebook login.
        </strong>
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
