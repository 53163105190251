import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import Tooltip from '../Tooltip/Tooltip';

export const getManager = managers => {
  let displayManagers;
  if (!managers || managers.length <= 0) {
    displayManagers = 'Unassigned';
  } else if (managers[0] === undefined) {
    displayManagers = `Unassigned`;
  } else if (managers.length > 1) {
    displayManagers = `${managers.length} Managers`;
  } else {
    displayManagers = `${managers[0].fullName}`;
  }
  return displayManagers;
};

export const getTooltipText = managers => {
  const alphabetiseManagerNames = sortBy(managers, ['fullName']);
  let managerNames;
  if (managers[0] !== undefined) {
    managerNames = alphabetiseManagerNames.map(manager => manager.fullName);
  } else {
    managerNames = ['Unassigned'];
  }
  const text = managerNames.map(name => <li key={name}>{name}</li>);
  return <ul>{text}</ul>;
};

const SubcategoryManager = ({ managers }) => {
  const displayManager = getManager(managers);
  const renderTooltip = managers !== null && managers.length > 1;
  return (
    <div className="manager">
      {displayManager}
      {renderTooltip && <Tooltip content={getTooltipText(managers)} />}
    </div>
  );
};

SubcategoryManager.propTypes = {
  managers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubcategoryManager;
