import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { changeTimezone } from '../../Store/Actions/ActionCreators';

export class Timezone extends Component {
  constructor() {
    super();

    this.onTimezoneSelect = this.onTimezoneSelect.bind(this);
  }

  onTimezoneSelect() {
    const { subcategoryId: id, timezone, onTimezoneSelect } = this.props;
    onTimezoneSelect({ id, timezone });
  }

  render() {
    const { timezone } = this.props;
    return (
      <div className="timezone-select">
        <DropdownItem onClick={this.onTimezoneSelect}>
          {timezone.text}
        </DropdownItem>
      </div>
    );
  }
}

Timezone.propTypes = {
  timezone: PropTypes.oneOfType([PropTypes.object]).isRequired,
  subcategoryId: PropTypes.number.isRequired,
  onTimezoneSelect: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onTimezoneSelect: (id, timezone) => {
    dispatch(changeTimezone(id, timezone));
  },
});

export default connect(null, mapDispatchToProps)(Timezone);
