import React from 'react';
import { DropdownItem } from 'reactstrap';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

const LocalTimezone = () => {
  const name = momentTimezone.tz.guess();
  const timezone = momentTimezone(moment.now()).tz(name);
  const formattedTimezone = timezone.format('Z z');

  return (
    <DropdownItem disabled>
      {`Local Timezone: (${formattedTimezone}) ${name}`}
    </DropdownItem>
  );
};

LocalTimezone.propTypes = {};

export default LocalTimezone;
