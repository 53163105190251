import React from 'react';
import DashboardIcon from './Svg/DashboardIcon';
import GroupManagerIcon from './Svg/GroupManagerIcon';

const sidebarData = [
  {
    id: 1,
    displayName: 'Subcategories',
    navigation: '/',
    image: <DashboardIcon />,
  },
  {
    id: 2,
    displayName: 'Group manager',
    navigation: '/groupmanager',
    image: <GroupManagerIcon />,
  },
  {
    id: 3,
    displayName: 'Privacy policy',
    navigation: '/privacy-policy',
    image: <img src="/img/key.png" alt="key" title="key" />,
  },
];

export default sidebarData;
