import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle } from 'reactstrap';
import TimezoneDropdown from './TimezoneDropdown';
import { getTimezone } from '../../Store/Actions/ActionCreators';
import getTimezoneSelector from '../../Store/Selectors/TimezoneSelector';

export class TimezonePicker extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.getTimezone();
  }

  getTimezone() {
    const { onGetTimezoneForsubcategory, subcategoryId } = this.props;
    onGetTimezoneForsubcategory({ subcategoryId });
  }

  toggle() {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  }

  render() {
    const { timezone, subcategoryId, readOnly } = this.props;
    const { dropdownOpen } = this.state;
    let timezoneForSubcategory;

    if (timezone.loaded && readOnly) {
      timezoneForSubcategory = (
        <span className="timezone__readonly">
          {timezone.data.timezone.text}
        </span>
      );
    } else if (timezone.loaded && !readOnly) {
      timezoneForSubcategory = (
        <DropdownToggle caret>{timezone.data.timezone.text}</DropdownToggle>
      );
    } else {
      timezoneForSubcategory = <div> Error </div>;
    }

    const renderTimezone = readOnly ? (
      timezoneForSubcategory
    ) : (
      <Dropdown isOpen={dropdownOpen} size="sm" toggle={this.toggle}>
        {timezoneForSubcategory}
        <TimezoneDropdown subcategoryId={subcategoryId} open={dropdownOpen} />
      </Dropdown>
    );

    return (
      <div className="timezone">
        <h4>Timezone: </h4>
        {renderTimezone}
      </div>
    );
  }
}

TimezonePicker.defaultProps = {
  readOnly: false,
};

TimezonePicker.propTypes = {
  subcategoryId: PropTypes.number.isRequired,
  timezone: PropTypes.shape({
    data: [PropTypes.object].isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  onGetTimezoneForsubcategory: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

const mapStateToProps = state => ({
  timezone: getTimezoneSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onGetTimezoneForsubcategory: subcategoryId => {
    dispatch(getTimezone(subcategoryId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimezonePicker);
