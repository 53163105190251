import { filter } from 'lodash';
import { GROUP_MANAGER_ACTIONS } from './SchedulingGroupManagerActions';

export const initialState = () => ({
  type: 'selected admiral campaigns',
  data: [],
});

const selectedAdmiralCampaignsReducer = (state = initialState(), action) => {
  switch (action.type) {
    case GROUP_MANAGER_ACTIONS.selectAdmiralCampaign: {
      const { admiralCampaign } = action.payload;
      return {
        ...state,
        data: state.data.concat(admiralCampaign),
      };
    }

    case GROUP_MANAGER_ACTIONS.deselectAdmiralCampaign: {
      const { admiralCampaign } = action.payload;
      return {
        ...state,
        data: filter(state.data, campaign => campaign !== admiralCampaign),
      };
    }

    case GROUP_MANAGER_ACTIONS.resetSelectedAdmiralCampaigns: {
      return initialState();
    }
    default:
      return state;
  }
};

export default selectedAdmiralCampaignsReducer;
