import { filter, map, orderBy, remove, includes, isEmpty, find } from 'lodash';
import {
  countObject,
  countObjectWithSchedulingGroupId,
  pickBySchedulingGroupId,
  isDefaultSchedulingGroup,
} from './SchedulingGroupManagerHelper';
import { getActiveSubcategory } from '../../Store/Selectors/ActiveSubcategory';

export const getActiveSubcategoryId = state => getActiveSubcategory(state).id;

export const getDefaultSchedulingGroupId = state => {
  const schedulingGroups = state.schedulingGroups.data;
  const defaultGroup = find(schedulingGroups, schedulingGroup =>
    isDefaultSchedulingGroup(schedulingGroup),
  );
  return parseInt(defaultGroup.id, 0);
};

const getAdmiralCampaignIds = admiralCampaigns =>
  map(admiralCampaigns, campaign => campaign.id);

export const getAdmiralCampaigns = state => {
  const { admiralCampaigns } = state.admiralCampaigns;
  return !isEmpty(admiralCampaigns) ? admiralCampaigns : {};
};

export const getSchedulingGroupAdmiralCampaigns = (state, groupId) =>
  filter(
    state.admiralCampaigns.admiralCampaigns,
    campaign => campaign.schedulingGroupId === groupId,
  );

export const getNewSchedulingGroups = state => {
  const allGroups = state.schedulingGroups.data;
  return filter(
    allGroups,
    group =>
      includes(group.id, 'draftSchedulingGroup_') && group.deleted !== true,
  );
};

export const getUpdatedSchedulingGroups = state => {
  const allGroups = state.schedulingGroups.data;
  return filter(
    allGroups,
    group => typeof group.id !== 'string' && group.deleted !== true,
  );
};

export const getDeletedSchedulingGroups = state => {
  const allGroups = state.schedulingGroups.data;
  return filter(
    allGroups,
    group => typeof group.id !== 'string' && group.deleted === true,
  );
};

export const getSchedulingGroupsNamesAndAdmiralCampaignIds = (state, groups) =>
  map(groups, group => ({
    name: group.name,
    admiralCampaignIds: getAdmiralCampaignIds(
      getSchedulingGroupAdmiralCampaigns(state, group.id),
    ),
  }));

export const getSchedulingGroupsIdsAndNamesAndAdmiralCampaignIds = (
  state,
  groups,
) =>
  map(groups, group => ({
    id: group.id,
    name: group.name,
    admiralCampaignIds: getAdmiralCampaignIds(
      getSchedulingGroupAdmiralCampaigns(state, group.id),
    ),
  }));

export const getActiveSchedulingGroupAdmiralCampaigns = state => {
  const { admiralCampaigns } = state.admiralCampaigns;
  const { activeSchedulingGroup } = state;

  if (activeSchedulingGroup.loaded) {
    const schedulingGroupId = activeSchedulingGroup.data.id;
    return pickBySchedulingGroupId(admiralCampaigns, schedulingGroupId);
  }

  return {};
};

export const getImmutableAdmiralCampaigns = state => {
  const { immutableAdmiralCampaigns } = state.admiralCampaigns;

  if (!isEmpty(immutableAdmiralCampaigns)) {
    return immutableAdmiralCampaigns;
  }
  return {};
};

export const getNumberOfAdmiralCampaigns = state =>
  countObject(state.admiralCampaigns.admiralCampaigns);

export const getAdmiralCampaignTimestamp = state =>
  state.admiralCampaignTimestamp.timestamp;

export const getSelectedAdmiralCampaigns = state =>
  state.selectedAdmiralCampaigns.data;

export const getSortedSchedulingGroups = state => {
  if (state.schedulingGroups.groupManagerLoaded) {
    const unsortedGroups = state.schedulingGroups.data;
    const sortedGroups = orderBy(
      unsortedGroups,
      [group => group.name.toLowerCase()],
      ['asc'],
    );

    const removeDefault = remove(
      sortedGroups,
      group => group.name === 'Default',
    );

    if (removeDefault.length > 0) {
      sortedGroups.unshift(removeDefault[0]);
    }

    remove(sortedGroups, group => group.deleted === true);

    const admiralCampaigns = getAdmiralCampaigns(state);

    return map(sortedGroups, schedulingGroup => ({
      ...schedulingGroup,
      numberOfCampaigns: countObjectWithSchedulingGroupId(
        admiralCampaigns,
        schedulingGroup.id,
      ),
    }));
  }
  return [];
};

export const getSchedulingGroupsForDropdown = state => {
  const sortedSchedulingGroups = getSortedSchedulingGroups(state);
  const activeSchedulingGroupLoaded = state.activeSchedulingGroup.loaded;
  const isAdmiralCampaignSelected = !isEmpty(
    state.selectedAdmiralCampaigns.data,
  );
  if (activeSchedulingGroupLoaded && isAdmiralCampaignSelected) {
    const activeSchedulingGroupName = state.activeSchedulingGroup.data.name;
    const filteredOutActiveSchedulingGroup = filter(
      sortedSchedulingGroups,
      schedulingGroup => schedulingGroup.name !== activeSchedulingGroupName,
    );
    return map(filteredOutActiveSchedulingGroup, schedulingGroup => ({
      ...schedulingGroup,
      name: `${schedulingGroup.name} (${schedulingGroup.numberOfCampaigns})`,
    }));
  }
  return [];
};

export const getActiveSubcategoryEnableFooterButtons = state =>
  state.activeSubcategory.enableFooterButtons;

export const getActiveSubcategorySchedulingGroupsUpdated = state =>
  state.activeSubcategory.schedulingGroupsUpdated;
