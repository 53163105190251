import gql from 'graphql-tag';
import sendQuery from './Request';

function getSchedulingGroups(payload) {
  const query = gql`
    query($subcategoryId: Int!, $filters: SchedulingGroupFilter) {
      getSchedulingGroups(subcategoryId: $subcategoryId, filters: $filters) {
        id
        name
        division
        status
        isScheduled
        ignoreSubcategoryLimits
        metrics {
          soldLeads {
            progress
            limit
            unlimited
          }
          conversions {
            progress
            limit
            unlimited
          }
        }
      }
    }
  `;
  return sendQuery(query, payload);
}

export default getSchedulingGroups;
