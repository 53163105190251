import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ content }) => <div className="tooltip-box">{content}</div>;

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
};

export default Tooltip;
