import { concat, filter, uniqueId } from 'lodash';
import ACTIONS from './SnackbarActions';

export const initialState = () => [];

const snackbarReducer = (state = initialState(), action) => {
  switch (action.type) {
    case ACTIONS.createSnackbar: {
      const snackbar = {
        ...action.payload.snackbar,
        id: uniqueId(),
      };
      return concat(state, [snackbar]);
    }
    case ACTIONS.destroySnackbar: {
      const { id } = action.payload;
      return filter(state, snackbar => snackbar.id !== id);
    }
    default:
      return state;
  }
};

export default snackbarReducer;
