import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message, type }) => (
  <div className={`${type}-error`}>{`Error: ${message}...`}</div>
);

Error.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Error;
