import { css } from '@mvf/product-components';

const schedulingGroupManagerTableStyles = () => ({
  dropdown: css({
    display: 'block',
    height: 'initial',
  }),
});

export default schedulingGroupManagerTableStyles;
