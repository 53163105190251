import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getActiveSchedulingGroupId } from 'Shared/Selectors/SchedulingGroupSelector';
import { getActiveSubcategory } from '../../Store/Selectors/ActiveSubcategory';
import {
  getPlatformCampaignsForSchedulingGroup,
  updatePlatformCampaignScheduledStatus,
} from './PlatformCampaignApi';
import {
  getPlatformCampaignsSuccess,
  getPlatformCampaignsFailure,
  updatePlatformCampaignStatusSuccess,
  updatePlatformCampaignStatusFailure,
} from './PlatformCampaignActionCreators';
import { PLATFORM_CAMPAIGN_ACTIONS } from './PlatformCampaignActions';

function* getPlatformCampaigns(action) {
  const { schedulingGroupId, subcategoryId } = action.payload;
  if (!schedulingGroupId || !subcategoryId) {
    yield;
  } else {
    try {
      const { data } = yield call(getPlatformCampaignsForSchedulingGroup, {
        schedulingGroupId,
        subcategoryId,
      });
      yield put(getPlatformCampaignsSuccess(data));
    } catch (error) {
      yield put(getPlatformCampaignsFailure(error.message));
    }
  }
}

function* updatePlatformCampaignStatus(action) {
  const { isScheduled, platformCampaignId } = action.payload;
  if (
    isScheduled === null ||
    isScheduled === undefined ||
    !platformCampaignId
  ) {
    yield;
  } else {
    try {
      yield call(updatePlatformCampaignScheduledStatus, {
        isScheduled,
        platformCampaignId,
      });
      const activeSchedulingGroupId = yield select(getActiveSchedulingGroupId);
      const activeSubcategory = yield select(getActiveSubcategory);
      yield put(updatePlatformCampaignStatusSuccess());
      yield getPlatformCampaigns({
        payload: {
          schedulingGroupId: activeSchedulingGroupId,
          subcategoryId: activeSubcategory.id,
        },
      });
    } catch (error) {
      yield put(updatePlatformCampaignStatusFailure(error.message));
    }
  }
}

export function* onGetPlatformCampaigns() {
  yield takeEvery(
    PLATFORM_CAMPAIGN_ACTIONS.loadPlatformCampaigns,
    getPlatformCampaigns,
  );
}

export function* onUpdatePlatformCampaignStatus() {
  yield takeEvery(
    PLATFORM_CAMPAIGN_ACTIONS.updatePlatformCampaignStatus,
    updatePlatformCampaignStatus,
  );
}
