import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as SEVERITY from 'Shared/Constants/Severity';
import nextSnackbar from './SnackbarSelector';
import { destroySnackbar } from './SnackbarActionCreators';
import SnackbarAnimator from './SnackbarAnimator';
import Success from './Svg/Success';
import Error from './Svg/Error';

const icons = {
  [SEVERITY.SUCCESS]: <Success />,
  [SEVERITY.ERROR]: <Error />,
  [undefined]: '',
};

export class SnackbarContainer extends Component {
  shouldComponentUpdate(nextProp) {
    const { snackbar } = this.props;
    return snackbar !== nextProp.snackbar;
  }

  render() {
    const {
      onDestroySnackbar,
      snackbar: { id, severity, message, autoHideDuration },
    } = this.props;
    const variant = severity ? `variant--${severity}` : '';
    const icon = icons[severity];

    if (!isEmpty(this.props.snackbar)) {
      setTimeout(() => {
        onDestroySnackbar(id);
      }, autoHideDuration);
    }

    return (
      <SnackbarAnimator
        id={id}
        variant={variant}
        icon={icon}
        message={message}
      />
    );
  }
}

SnackbarContainer.defaultProps = {
  snackbar: {},
};

SnackbarContainer.propTypes = {
  snackbar: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.node,
    autoHideDuration: PropTypes.number,
    severity: PropTypes.oneOf(Object.values(SEVERITY)),
  }),
  onDestroySnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  snackbar: nextSnackbar(state),
});

const mapDispatchToProps = dispatch => ({
  onDestroySnackbar: id => dispatch(destroySnackbar({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);
