import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    title: PropTypes.node.isRequired,
    accordionState: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    color: 'blue-dark',
  };

  state = {
    expandableClosed: !this.props.accordionState,
  };

  onClick = () => {
    const { expandableClosed } = this.state;
    this.setState({ expandableClosed: !expandableClosed });
  };

  render() {
    const { expandableClosed } = this.state;
    const { children, color, title } = this.props;
    return (
      <div className="accordion-container">
        <button
          type="button"
          className={`accordion-button accordion-button--${color} 
          accordion-button--${color}-${expandableClosed ? 'closed' : 'open'}`}
          onClick={this.onClick}
          aria-expanded={!expandableClosed}
        >
          <span
            className={`accordion-button--icon accordion-button--icon-${color}-${
              expandableClosed ? 'closed' : 'open'
            }`}
          />
          <div className="accordion-button--title">{title}</div>
        </button>
        <div
          className={`accordion accordion--${
            expandableClosed ? 'closed' : 'open'
          }`}
          role="grid"
          aria-hidden={expandableClosed}
        >
          {!expandableClosed && children}
        </div>
      </div>
    );
  }
}

export default Accordion;
