import { unset } from 'lodash';
import { normalize, schema } from 'normalizr';

export const removeDayOfWeekProperty = times =>
  times.map(time => {
    unset(time, 'dayOfWeek');
    return time;
  });

export const change24HToLongStatus = status =>
  status === '24H' ? 'TWENTY_FOUR_HOUR' : status;

export const normalizeData = input => {
  const inputObject = { data: input };
  const entity = new schema.Entity('data');
  const schemaDefinition = { data: [entity] };
  const normalized = normalize(inputObject, schemaDefinition);
  const { data } = normalized.entities;
  return data;
};

export const removeIdPrefix = id => parseInt(id.replace('id_', ''), 10);

export const addSchedulingGroupId = (campaigns, schedulingGroupId) =>
  campaigns.map(campaign => ({
    ...campaign,
    schedulingGroupId: removeIdPrefix(schedulingGroupId),
  }));
