import gql from 'graphql-tag';
import sendQuery from './Request';

const get = payload => {
  const query = gql`
    query($schedulingGroupId: Int!) {
      schedule: getSchedule(schedulingGroupId: $schedulingGroupId) {
        id
        start
        stop
        status
        dayOfWeek
      }
    }
  `;
  return sendQuery(query, payload);
};

const save = payload => {
  const query = gql`
    mutation($schedulingTimes: [TimeInput]!) {
      schedule: updateScheduleByTimeId(schedulingTimes: $schedulingTimes) {
        id
        start
        stop
        status
      }
    }
  `;
  return sendQuery(query, payload);
};

const ScheduleApi = { get, save };
export default ScheduleApi;
