import React from 'react';
import PropTypes from 'prop-types';

const Snackbar = ({ variant, icon, children }) => (
  <span className="snackbar">
    <div className={`variant ${variant}`}>{icon}</div>
    <div className="content">{children}</div>
  </span>
);

Snackbar.propTypes = {
  variant: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Snackbar;
