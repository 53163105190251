import ACTIONS from './AlertDialogActions';

export const openAlertDialog = payload => ({
  type: ACTIONS.openAlertDialog,
  payload,
});

export const closeAlertDialog = () => ({
  type: ACTIONS.closeAlertDialog,
});

export const confirmYes = () => ({
  type: ACTIONS.confirmYes,
});
