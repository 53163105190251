import { ACTIONS, REQUEST_ACTIONS } from 'Features/Limits/LimitsActions';

export const openLimitsModal = payload => ({
  type: ACTIONS.openLimitsModal,
  payload,
});

export const closeLimitsModal = () => ({
  type: ACTIONS.closeLimitsModal,
});

export const getLimitsSuccess = payload => ({
  type: REQUEST_ACTIONS.getLimitsSuccess,
  payload,
});

export const getLimitsFailure = payload => ({
  type: REQUEST_ACTIONS.getLimitsFailure,
  payload,
});

export const changeLimit = payload => ({ type: ACTIONS.changeLimit, payload });

export const saveLimits = payload => ({
  type: ACTIONS.saveLimits,
  payload,
});

export const saveLimitsSuccess = payload => ({
  type: REQUEST_ACTIONS.saveLimitsSuccess,
  payload,
});

export const saveLimitsFailure = payload => ({
  type: REQUEST_ACTIONS.saveLimitsFailure,
  payload,
});

export const updateTimezone = payload => ({
  type: ACTIONS.updateTimezone,
  payload,
});

export const resetLimits = () => ({ type: ACTIONS.resetLimits });

export const setIgnoreSubcategoryLimits = payload => ({
  type: ACTIONS.setIgnoreSubcategoryLimits,
  payload,
});
