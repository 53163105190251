import gql from 'graphql-tag';
import sendQuery from './Request';

function getSubcategories(payload) {
  const query = gql`
    query($subcategoryFilters: SubcategoryFilter) {
      subcategories: getPaginatedSubcategories(filters: $subcategoryFilters) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;
  return sendQuery(query, payload);
}

export default getSubcategories;
