import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';
import { KEY_CODES } from 'Shared/Constants/Constants';
import EmptySearch from 'Components/EmptySearch/EmptySearch';
import SchedulingGroupManagerTable from './SchedulingGroupManagerTable';

class SchedulingGroupManagerContainer extends Component {
  static propTypes = {
    selectedDivision: PropTypes.string.isRequired,
    activeSubcategory: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      loaded: PropTypes.bool.isRequired,
    }).isRequired,
    sortedSchedulingGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        numberOfCampaigns: PropTypes.number,
      }),
    ).isRequired,
    selectedAdmiralCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ).isRequired,
    activeSchedulingGroup: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      numberOfCampaigns: PropTypes.number,
    }).isRequired,
    activeSchedulingGroupAdmiralCampaigns: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ).isRequired,
    schedulingGroupsForDropdown: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ).isRequired,
    activeSchedulingGroupLoaded: PropTypes.bool.isRequired,
    numberOfAdmiralCampaigns: PropTypes.number.isRequired,
    setSchedulingGroupsToInitialState: PropTypes.func.isRequired,
    setActiveSchedulingGroupToInitialState: PropTypes.func.isRequired,
    setSelectedAdmiralCampaignsToInitialState: PropTypes.func.isRequired,
    onFetchSchedulingGroups: PropTypes.func.isRequired,
    onSetActiveSchedulingGroup: PropTypes.func.isRequired,
    onCreateNewSchedulingGroup: PropTypes.func.isRequired,
    onCancelNewSchedulingGroups: PropTypes.func.isRequired,
    onSaveSchedulingGroups: PropTypes.func.isRequired,
    onDeselectAdmiralCampaign: PropTypes.func.isRequired,
    onSelectAdmiralCampaign: PropTypes.func.isRequired,
    onMoveToSchedulingGroup: PropTypes.func.isRequired,
    enableFooterButtons: PropTypes.bool.isRequired,
    onShowSchedulingGroupNameError: PropTypes.func.isRequired,
    onHideSchedulingGroupNameError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { newSchedulingGroupInput: false };
  }

  componentDidMount() {
    const {
      activeSubcategory,
      selectedDivision,
      onFetchSchedulingGroups,
      setActiveSchedulingGroupToInitialState,
      setSchedulingGroupsToInitialState,
      setSelectedAdmiralCampaignsToInitialState,
    } = this.props;
    setActiveSchedulingGroupToInitialState();
    setSchedulingGroupsToInitialState();
    setSelectedAdmiralCampaignsToInitialState();

    if (activeSubcategory.loaded && selectedDivision) {
      onFetchSchedulingGroups();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeSubcategory,
      selectedDivision,
      setActiveSchedulingGroupToInitialState,
      setSelectedAdmiralCampaignsToInitialState,
      onFetchSchedulingGroups,
    } = this.props;

    const isSubcategoryIdChanged =
      activeSubcategory.id !== prevProps.activeSubcategory.id;
    const isDivisionChanged = selectedDivision !== prevProps.selectedDivision;
    const divisionAndSubcategorySelected =
      activeSubcategory.loaded && selectedDivision;

    if (
      divisionAndSubcategorySelected &&
      (isSubcategoryIdChanged || isDivisionChanged)
    ) {
      setActiveSchedulingGroupToInitialState();
      setSelectedAdmiralCampaignsToInitialState();
      onFetchSchedulingGroups();
    }
  }

  handleNewSchedulingGroupClick = () => {
    this.setState({
      newSchedulingGroupInput: true,
    });
  };

  submitOnReturn = e => {
    const { onHideSchedulingGroupNameError } = this.props;
    if (e.key === 'Enter') {
      this.handleAddDraftSchedulingGroup(e);
    } else {
      onHideSchedulingGroupNameError();
    }
  };

  clearErrorOnDelete = e => {
    const { onHideSchedulingGroupNameError } = this.props;
    if (e.keyCode === KEY_CODES.DELETE || e.keyCode === KEY_CODES.BACKSPACE) {
      onHideSchedulingGroupNameError();
    }
  };

  cancelDraftSchedulingGroups = () => {
    const {
      onCancelNewSchedulingGroups,
      setActiveSchedulingGroupToInitialState,
    } = this.props;

    onCancelNewSchedulingGroups();
    setActiveSchedulingGroupToInitialState();
  };

  handleAddDraftSchedulingGroup = event => {
    const newSchedulingGroupName = event.target.value.replace(/\s+$/g, '');
    const lowerCaseSchedulingGroupName = newSchedulingGroupName.toLowerCase();
    const {
      onCreateNewSchedulingGroup,
      sortedSchedulingGroups,
      onShowSchedulingGroupNameError,
      onHideSchedulingGroupNameError,
    } = this.props;

    const currentGroupNames = map(sortedSchedulingGroups, group =>
      group.name.toLowerCase(),
    );

    if (
      newSchedulingGroupName &&
      !currentGroupNames.includes(lowerCaseSchedulingGroupName) &&
      lowerCaseSchedulingGroupName !== 'all'
    ) {
      onCreateNewSchedulingGroup(newSchedulingGroupName);
      onHideSchedulingGroupNameError();
      this.setState({ newSchedulingGroupInput: false });
    } else {
      onShowSchedulingGroupNameError();
    }

    if (!newSchedulingGroupName) {
      onHideSchedulingGroupNameError();
      this.setState({ newSchedulingGroupInput: false });
    }
  };

  handleAdmiralCampaignSelect = (isChecked, admiralCampaign) => {
    const { onSelectAdmiralCampaign, onDeselectAdmiralCampaign } = this.props;
    if (isChecked) {
      onSelectAdmiralCampaign(admiralCampaign);
    } else {
      onDeselectAdmiralCampaign(admiralCampaign);
    }
  };

  handleMoveToSchedulingGroup = schedulingGroupId => {
    const {
      selectedAdmiralCampaigns,
      onMoveToSchedulingGroup,
      setSelectedAdmiralCampaignsToInitialState,
      setActiveSchedulingGroupToInitialState,
      activeSchedulingGroup,
    } = this.props;

    if (!isEmpty(selectedAdmiralCampaigns)) {
      onMoveToSchedulingGroup(selectedAdmiralCampaigns, schedulingGroupId);
      setSelectedAdmiralCampaignsToInitialState();

      if (
        activeSchedulingGroup.numberOfCampaigns ===
        selectedAdmiralCampaigns.length
      ) {
        setActiveSchedulingGroupToInitialState();
      }
    }
  };

  handleSetActiveSchedulingGroup = schedulingGroup => {
    const {
      onSetActiveSchedulingGroup,
      setSelectedAdmiralCampaignsToInitialState,
    } = this.props;

    onSetActiveSchedulingGroup(schedulingGroup);
    setSelectedAdmiralCampaignsToInitialState();
  };

  addPropertiesToSchedulingGroupsForDropdown() {
    const { schedulingGroupsForDropdown } = this.props;
    return schedulingGroupsForDropdown.map(schedulingGroup => {
      const updatedGroup = schedulingGroup;
      updatedGroup.handleClick = this.handleMoveToSchedulingGroup;
      updatedGroup.itemClassName = 'scheduling-group';
      return updatedGroup;
    });
  }

  render() {
    const {
      selectedDivision,
      activeSubcategory,
      sortedSchedulingGroups,
      activeSchedulingGroup,
      activeSchedulingGroupAdmiralCampaigns,
      numberOfAdmiralCampaigns,
      enableFooterButtons,
      activeSchedulingGroupLoaded,
      onSaveSchedulingGroups,
    } = this.props;

    const { newSchedulingGroupInput } = this.state;
    const displayTable = activeSubcategory.loaded && selectedDivision !== '';
    if (displayTable) {
      if (sortedSchedulingGroups.length === 0) {
        return (
          <EmptySearch message="There are no scheduling groups associated with this subcategory in this division, please select another." />
        );
      }
      return (
        <SchedulingGroupManagerTable
          selectedDivision={selectedDivision}
          activeSubcategory={activeSubcategory}
          sortedSchedulingGroups={sortedSchedulingGroups}
          activeSchedulingGroup={activeSchedulingGroup}
          numberOfAdmiralCampaigns={numberOfAdmiralCampaigns}
          activeSchedulingGroupAdmiralCampaigns={
            activeSchedulingGroupAdmiralCampaigns
          }
          newSchedulingGroupInput={newSchedulingGroupInput}
          schedulingGroupsForDropdown={this.addPropertiesToSchedulingGroupsForDropdown()}
          handleSetActiveSchedulingGroup={this.handleSetActiveSchedulingGroup}
          handleNewSchedulingGroupClick={this.handleNewSchedulingGroupClick}
          handleAddDraftSchedulingGroup={this.handleAddDraftSchedulingGroup}
          submitOnReturn={this.submitOnReturn}
          enableFooterButtons={enableFooterButtons}
          cancelDraftSchedulingGroups={this.cancelDraftSchedulingGroups}
          saveSchedulingGroups={onSaveSchedulingGroups}
          handleAdmiralCampaignSelect={this.handleAdmiralCampaignSelect}
          handleMoveToSchedulingGroup={this.handleMoveToSchedulingGroup}
          activeSchedulingGroupLoaded={activeSchedulingGroupLoaded}
          clearErrorOnDelete={this.clearErrorOnDelete}
        />
      );
    }
    return false;
  }
}

export default SchedulingGroupManagerContainer;
