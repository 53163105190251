import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSubcategories } from 'Shared/Selectors/SubcategorySelector';

export default function withErrorBoundary(WrappedComponent) {
  class ErrorBoundary extends Component {
    static propTypes = {
      subcategories: PropTypes.shape({
        loaded: PropTypes.bool.isRequired,
        managers: PropTypes.objectOf(PropTypes.object),
        managersById: PropTypes.arrayOf(PropTypes.object),
        subcategories: PropTypes.objectOf(PropTypes.object),
        subcategoriesById: PropTypes.arrayOf(PropTypes.object),
        error: PropTypes.bool.isRequired,
      }).isRequired,
    };

    state = {
      hasError: false,
    };

    componentDidCatch() {
      this.setState({
        hasError: true,
      });
    }

    render() {
      const { hasError } = this.state;
      const { subcategories } = this.props;
      if (hasError || subcategories.error) {
        return (
          <h1 className="error-display">
            Failed to fetch data from the backend.
          </h1>
        );
      }
      return <WrappedComponent />;
    }
  }

  const mapStateToProps = state => ({
    subcategories: getSubcategories(state),
  });

  return connect(mapStateToProps)(ErrorBoundary);
}
