import {
  SCHEDULING_GROUPS_ACTIONS,
  SCHEDULING_GROUPS_REQUEST_ACTIONS,
} from 'Features/SchedulingGroups/store/schedulingGroupsActions';

export const loadSchedulingGroups = subcategoryId => ({
  type: SCHEDULING_GROUPS_ACTIONS.loadSchedulingGroups,
  payload: subcategoryId,
});

export const getSchedulingGroupsSuccess = payload => ({
  type: SCHEDULING_GROUPS_REQUEST_ACTIONS.getSchedulingGroupsSuccess,
  payload,
});

export const getSchedulingGroupsFailure = payload => ({
  type: SCHEDULING_GROUPS_REQUEST_ACTIONS.getSchedulingGroupsFailure,
  payload,
});
