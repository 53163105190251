export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = state => {
  let savedState = state;
  savedState = {
    managers: {
      filters: state.managers.filters,
    },
    statuses: state.statuses,
    divisions: {
      selectedDivision: state.divisions.selectedDivision,
    },
  };
  const serializedState = JSON.stringify(savedState);
  localStorage.setItem('state', serializedState);
};
