import time from './Times';

export const mockSchedule = () => ({
  type: 'schedule',
  data: {
    schedule: [time()],
  },
});

export const defaultSchedule = () => [
  {
    id: 1,
    dayOfWeek: 'MON',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 2,
    dayOfWeek: 'TUE',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 3,
    dayOfWeek: 'WED',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 4,
    dayOfWeek: 'THU',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 5,
    dayOfWeek: 'FRI',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 6,
    dayOfWeek: 'SAT',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 7,
    dayOfWeek: 'SUN',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
];

export const updatedSchedule = () => [
  {
    id: 8,
    dayOfWeek: 'MON',
    status: 'SCHEDULE',
    start: '10:00',
    stop: '20:00',
  },
  {
    id: 9,
    dayOfWeek: 'TUE',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 10,
    dayOfWeek: 'WED',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 11,
    dayOfWeek: 'THU',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 12,
    dayOfWeek: 'FRI',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 13,
    dayOfWeek: 'SAT',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
  {
    id: 14,
    dayOfWeek: 'SUN',
    status: 'OFF',
    start: '09:00',
    stop: '20:00',
  },
];
