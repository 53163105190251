import { connect } from 'react-redux';
import { getActiveSchedulingGroupName } from 'Shared/Selectors/SchedulingGroupSelector';
import {
  getPlatformCampaigns,
  getLoadedState,
} from 'Features/PlatformCampaigns/PlatformCampaignSelector';
import { setCampaignNameSearchTerm } from 'Shared/SharedActionCreators';
import getCampaignNameSearchTerm from 'Shared/Selectors/PlatformCampaignSelector';
import { getActiveSubcategoryName } from '../../Store/Selectors/ActiveSubcategory';
import {
  loadPlatformCampaigns,
  updatePlatformCampaignStatus,
} from './PlatformCampaignActionCreators';
import PlatformCampaignContainer from './PlatformCampaignContainer';

const mapStateToProps = state => ({
  platformCampaigns: getPlatformCampaigns(state),
  subcategoryName: getActiveSubcategoryName(state),
  schedulingGroupName: getActiveSchedulingGroupName(state),
  loaded: getLoadedState(state),
  campaignNameSearchTerm: getCampaignNameSearchTerm(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadPlatformCampaigns: (schedulingGroupId, subcategoryId) => {
    dispatch(loadPlatformCampaigns({ schedulingGroupId, subcategoryId }));
  },
  onUpdatePlatformCampaignStatus: (isScheduled, platformCampaignId) => {
    dispatch(
      updatePlatformCampaignStatus({
        isScheduled,
        platformCampaignId,
      }),
    );
  },
  onSetCampaignNameSearchTerm: searchTerm => {
    dispatch(setCampaignNameSearchTerm({ searchTerm }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlatformCampaignContainer);
