import gql from 'graphql-tag';
import sendQuery from './Request';

/*
 * There should be one static method per CRUD operation as necessary.
 */
export function update(payload) {
  const mutation = gql`
    mutation($schedulingGroupId: Int!, $isScheduled: Boolean!) {
      updateIsScheduledBySchedulingGroupId(
        schedulingGroupId: $schedulingGroupId
        isScheduled: $isScheduled
      ) {
        id
        isScheduled
      }
    }
  `;
  return sendQuery(mutation, payload);
}

export default { update };
