import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import Snackbar from './Snackbar';

const Box = posed.div({
  enter: {
    opacity: 1,
    bottom: '2.5rem',
    transition: { duration: 250 },
    flip: true,
  },
  exit: { opacity: 0, bottom: 0, transition: { duration: 250 }, flip: true },
});

const SnackbarAnimator = ({ id, variant, icon, message }) => (
  <PoseGroup flipMove={false}>
    {id && (
      <Box key={id} className="snackbar-box">
        <Snackbar variant={variant} icon={icon}>
          {message}
        </Snackbar>
      </Box>
    )}
  </PoseGroup>
);

SnackbarAnimator.defaultProps = {
  id: undefined,
  variant: undefined,
  icon: undefined,
  message: undefined,
};

SnackbarAnimator.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.node,
};

export default SnackbarAnimator;
