import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getSelectedSubcategoryDivision } from 'Shared/Selectors/SubcategoryDivisionSelector';
import getSchedulingGroups from 'Api/GraphQL/SchedulingGroupApi';
import { SCHEDULING_GROUPS_ACTIONS } from 'Features/SchedulingGroups/store/schedulingGroupsActions';
import {
  getSchedulingGroupsFailure,
  getSchedulingGroupsSuccess,
} from 'Features/SchedulingGroups/store/schedulingGroupsActionCreators';

export function* fetchSchedulingGroups(action) {
  const { subcategoryId } = action.payload;
  const selectedDivision = yield select(getSelectedSubcategoryDivision);

  const filters = { managed: true };
  if (selectedDivision) {
    filters.divisions = [selectedDivision];
  }

  try {
    const schedulingGroups = yield call(getSchedulingGroups, {
      subcategoryId,
      filters,
    });
    yield put(
      getSchedulingGroupsSuccess({
        subcategoryId,
        schedulingGroups: schedulingGroups.data.getSchedulingGroups,
      }),
    );
  } catch (e) {
    yield put(
      getSchedulingGroupsFailure({
        subcategoryId,
        message: e.message,
      }),
    );
  }
}

function* onFetchSchedulingGroups() {
  yield takeEvery(
    SCHEDULING_GROUPS_ACTIONS.loadSchedulingGroups,
    fetchSchedulingGroups,
  );
}

export default onFetchSchedulingGroups;
