import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownMenu, DropdownItem, Input } from 'reactstrap';
import TimezoneList from './TimezoneList';
import LocalTimezone from './LocalTimezone';
import { getTimezones } from '../../Store/Actions/ActionCreators';
import getTimezonesSelector from '../../Store/Selectors/TimezonesSelector';

export class TimezoneDropdown extends Component {
  constructor() {
    super();
    this.state = {
      filter: '',
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.getTimezones();
  }

  getTimezones() {
    const { onGetTimezones } = this.props;
    const { filter } = this.state;
    onGetTimezones({ filter });
  }

  async handleFilterChange(event) {
    await this.setState({ filter: event.target.value });
    this.getTimezones();
  }

  renderTimezones() {
    const { timezones, subcategoryId } = this.props;
    return TimezoneList(subcategoryId, timezones);
  }

  render() {
    const { timezones } = this.props;
    const { filter } = this.state;
    let timezoneList;
    if (timezones.loaded) {
      timezoneList = this.renderTimezones();
    } else {
      timezoneList = <div> Error </div>;
    }
    return (
      <DropdownMenu right>
        <div className="timezone-header">
          <div className="disabled dropdown-item">
            <Input
              type="text"
              placeholder="Filter..."
              onChange={this.handleFilterChange}
              value={filter}
            />
          </div>
          <LocalTimezone class="local" />
          <DropdownItem disabled className="header">
            Timezones
          </DropdownItem>
        </div>
        <div className="timezone-list">{timezoneList}</div>
      </DropdownMenu>
    );
  }
}

TimezoneDropdown.propTypes = {
  subcategoryId: PropTypes.number.isRequired,
  onGetTimezones: PropTypes.func.isRequired,
  timezones: PropTypes.shape({
    data: [PropTypes.object].isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  timezones: getTimezonesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onGetTimezones: filter => {
    dispatch(getTimezones(filter));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneDropdown);
