import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SCHEDULE_STATUSES } from 'Shared/Constants/Constants';
import WeekdayHeader from 'Components/Weekdays/Weekdays';
import { getSchedules } from 'Features/Schedules/ScheduleSelector';

export const TimeInputField = ({ time, fieldType, index, handleChange }) => {
  const disabledState = time.status === 'SCHEDULE' ? false : 'disabled';
  const containerClasses =
    time.status === 'OFF' ? 'input-container disabled' : 'input-container';
  return (
    <div className={containerClasses}>
      <input
        type="time"
        step="900"
        onChange={e => handleChange(e, index, fieldType)}
        value={time[fieldType]}
        disabled={disabledState}
      />
    </div>
  );
};

export const changeLongStatusto24H = status =>
  status === SCHEDULE_STATUSES.TWENTY_FOUR_HOUR
    ? SCHEDULE_STATUSES['24H']
    : status;

const statuses = Object.values(SCHEDULE_STATUSES).filter(
  el => el !== SCHEDULE_STATUSES.TWENTY_FOUR_HOUR,
);

export const filterStatuses = item =>
  statuses.filter(el => el.toLowerCase().indexOf(item.toLowerCase()) === -1);

export const SelectField = ({ time, fieldType, index, handleChange }) => {
  const status = changeLongStatusto24H(time[fieldType]);
  const filteredStatuses = filterStatuses(status);
  const disabledClassName = time.status === 'OFF' ? ' disabled' : '';
  return (
    <div className={`input-container${disabledClassName}`}>
      <select onChange={e => handleChange(e, index, fieldType)}>
        <option key="selected" value={status}>
          {status}
        </option>
        {filteredStatuses.map(filteredStatus => (
          <option key={filteredStatus} value={filteredStatus}>
            {filteredStatus}
          </option>
        ))}
      </select>
    </div>
  );
};

const createTimeFieldKey = (fieldType, index, timeId) =>
  `${fieldType}_${index}_${timeId}`;

export const Schedule = ({ times, changeFieldValues }) => (
  <Fragment>
    <WeekdayHeader />
    <div className="schedule-data">
      <div className="schedule-row">
        {times.data.map((time, index) => (
          <SelectField
            key={createTimeFieldKey('status', index, time.id)}
            time={time}
            fieldType="status"
            index={index}
            handleChange={changeFieldValues}
          />
        ))}
      </div>
      <div className="schedule-row schedule-start">
        {times.data.map((time, index) => (
          <TimeInputField
            key={createTimeFieldKey('start', index, time.id)}
            time={time}
            fieldType="start"
            index={index}
            handleChange={changeFieldValues}
          />
        ))}
      </div>
      <div className="schedule-row schedule-stop">
        {times.data.map((time, index) => (
          <TimeInputField
            key={createTimeFieldKey('stop', index, time.id)}
            time={time}
            fieldType="stop"
            index={index}
            handleChange={changeFieldValues}
          />
        ))}
      </div>
    </div>
  </Fragment>
);

Schedule.propTypes = {
  times: PropTypes.shape({
    type: PropTypes.string,
    schedule_by_id: PropTypes.arrayOf,
    data: PropTypes.arrayOf,
    loaded: PropTypes.bool,
    immutableData: PropTypes.arrayOf,
  }).isRequired,
  changeFieldValues: PropTypes.func.isRequired,
};

TimeInputField.propTypes = {
  time: PropTypes.shape({
    id: PropTypes.number,
    start: PropTypes.string,
    stop: PropTypes.string,
    status: PropTypes.string,
    dayOfWeek: PropTypes.string,
  }).isRequired,
  fieldType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

SelectField.defaultProps = {
  time: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
};

SelectField.propTypes = {
  time: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  fieldType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  times: getSchedules(state),
});

export default connect(mapStateToProps)(Schedule);
