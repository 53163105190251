import { countObjectWithSchedulingGroupId } from 'Features/SchedulingGroupManager/SchedulingGroupManagerHelper';
import { getAdmiralCampaigns } from 'Features/SchedulingGroupManager/SchedulingGroupManagerSelector';

export const getSchedulingGroupsBySubcategoryId = (state, subcategoryId) =>
  state.schedulingGroups.data[subcategoryId];

export const getSchedulingGroups = state => state.schedulingGroups;

export const getActiveSchedulingGroupLoaded = state =>
  state.activeSchedulingGroup.loaded;

export const getActiveSchedulingGroup = state =>
  getActiveSchedulingGroupLoaded(state)
    ? {
        id: state.activeSchedulingGroup.data.id,
        name: state.activeSchedulingGroup.data.name,
        division: state.activeSchedulingGroup.data.division,
      }
    : {};

export const getActiveSchedulingGroupName = state => {
  const activeSchedulingGroup = getActiveSchedulingGroup(state);
  return `${activeSchedulingGroup.division} - ${activeSchedulingGroup.name}`;
};

export const getActiveSchedulingGroupId = state =>
  getActiveSchedulingGroup(state).id;

export const getActiveSchedulingGroupWithAdmiralCampaigns = state =>
  getActiveSchedulingGroupLoaded(state)
    ? {
        id: state.activeSchedulingGroup.data.id,
        name: state.activeSchedulingGroup.data.name,
        numberOfCampaigns: countObjectWithSchedulingGroupId(
          getAdmiralCampaigns(state),
          state.activeSchedulingGroup.data.id,
        ),
      }
    : {};
