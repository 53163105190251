import React from 'react';

const PausedStatusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16">
    <g fill="none" fillRule="evenodd">
      <rect width="8" height="15" x=".5" y=".5" rx="2" />
      <rect width="8" height="15" x="11.5" y=".5" rx="2" />
    </g>
  </svg>
);

export default PausedStatusIcon;
