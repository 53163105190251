import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';

const BaseDropdownItem = ({ name, handleClick }) => (
  <div className="base-dropdown-item">
    <DropdownItem onClick={handleClick}>{name}</DropdownItem>
  </div>
);

BaseDropdownItem.defaultProps = {
  handleClick: () => {},
};

BaseDropdownItem.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default BaseDropdownItem;
