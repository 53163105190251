import React, { Component, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import ModalHeader from '../ModalHeader/ModalHeader';

class BaseModal extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { getModalData, modalClose } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) {
      getModalData();
    } else {
      modalClose();
    }

    this.setState({ isOpen: !isOpen });
  }

  render() {
    const {
      buttonText,
      footerError,
      resetCallback,
      saveCallback,
      children,
      title,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <Fragment>
        <button
          type="button"
          className="btn-secondary--small"
          onClick={this.toggle}
        >
          {buttonText}
        </button>
        <Modal size="lg" isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader title={title} modalToggle={this.toggle} />
          <ModalBody>{children}</ModalBody>
          <div className="modal-footer">
            <div className="modal-footer--left">
              <button
                type="button"
                className="reset-button btn-secondary"
                onClick={resetCallback}
              >
                Reset
              </button>
            </div>
            <div className="modal-footer--center">{footerError}</div>
            <div className="modal-footer--right">
              <button
                type="button"
                className="save-button btn-primary"
                onClick={() => {
                  saveCallback();
                  this.toggle();
                }}
                disabled={!!footerError}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

BaseModal.defaultProps = {
  footerError: false,
};

BaseModal.propTypes = {
  getModalData: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  resetCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  footerError: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  modalClose: PropTypes.func.isRequired,
};

export default BaseModal;
