import React from 'react';
import PropTypes from 'prop-types';
import SchedulerToggle from 'Components/SchedulingGroupToggle/SchedulerToggle';
import PausedStatusIcon from 'Shared/Svg/PausedStatusIcon';
import RunningStatusIcon from 'Shared/Svg/RunningStatusIcon';
import { PLATFORM_CAMPAIGN_STATUSES } from 'Shared/Constants/Constants';

const campaignStatusIconMap = {
  [PLATFORM_CAMPAIGN_STATUSES.PAUSED]: <PausedStatusIcon />,
  [PLATFORM_CAMPAIGN_STATUSES.RUNNING]: <RunningStatusIcon />,
};

const PlatformCampaignItem = ({
  id,
  isScheduled,
  platform,
  name,
  admiralCampaignId,
  manager,
  mercuryStatus,
  platformStatus,
  onScheduleToggle,
  identifier,
}) => {
  const statusIcon = campaignStatusIconMap[mercuryStatus];
  return (
    <div className="grid-container grid-container-campaign">
      {id ? (
        <SchedulerToggle
          endpoint="platform_campaigns"
          isScheduled={isScheduled}
          groupId={id}
          onScheduleToggle={() => onScheduleToggle(id)}
        />
      ) : null}
      <div className="platform-title">{platform}</div>
      <div className="campaign-name">{name}</div>
      <div className="campaign-id">{admiralCampaignId}</div>
      <div className="campaign-manager">{manager}</div>
      <div className="campaign-identifier">{identifier}</div>
      <div className="campaign-status" data-platform-status={platformStatus}>
        <div className="icon-outline-black">{statusIcon}</div>
        {mercuryStatus}
      </div>
    </div>
  );
};

PlatformCampaignItem.propTypes = {
  id: PropTypes.number.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  admiralCampaignId: PropTypes.string.isRequired,
  manager: PropTypes.string.isRequired,
  mercuryStatus: PropTypes.string.isRequired,
  platformStatus: PropTypes.string.isRequired,
  onScheduleToggle: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
};

export default PlatformCampaignItem;
