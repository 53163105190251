import { call, takeEvery } from 'redux-saga/effects';
import SHARED_ACTIONS from 'Shared/SharedActions';
import * as SchedulerToggleApi from '../../../Api/GraphQL/SchedulerToggleApi';

export function* updateSchedulerToggle(action) {
  const { schedulingGroupId, isScheduled } = action.payload;
  yield call(SchedulerToggleApi.update, {
    schedulingGroupId,
    isScheduled,
  });
}

function* onUpdateSchedulingGroupToggle() {
  yield takeEvery(
    SHARED_ACTIONS.updateSchedulingGroupToggle,
    updateSchedulerToggle,
  );
}

export default onUpdateSchedulingGroupToggle;
