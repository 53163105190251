import { filter, map } from 'lodash';
import { normalize, schema } from 'normalizr';
import { ACTIONS, REQUEST_ACTIONS } from '../Actions/Actions';

export const initialState = () => ({
  type: 'managers',
  managers: {},
  filters: [],
});

const managerReducer = (state = initialState(), action) => {
  const managerEntity = new schema.Entity(
    'managers',
    {},
    { idAttribute: 'email' },
  );
  const managersSchema = new schema.Array(managerEntity);
  switch (action.type) {
    case REQUEST_ACTIONS.getManagersSuccess: {
      let { managers } = action.payload.data;
      managers = map(managers, manager => ({
        fullName: manager.fullName,
        email: manager.email.toLowerCase(),
      }));
      const normalized = normalize(managers, managersSchema);
      const { managers: normalizedManagers } = normalized.entities;
      return { ...state, managers: normalizedManagers };
    }
    case REQUEST_ACTIONS.getManagersFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }
    case ACTIONS.selectManagerFilter: {
      const { email } = action.payload;
      return { ...state, filters: state.filters.concat(email) };
    }
    case ACTIONS.deselectManagerFilter: {
      const { email } = action.payload;
      return {
        ...state,
        filters: filter(state.filters, manager => manager !== email),
      };
    }
    case ACTIONS.clearFilters: {
      return { ...state, filters: [] };
    }
    default:
      return state;
  }
};

export default managerReducer;
