import {
  SUBCATEGORYLIST_ACTIONS,
  SUBCATEGORYLIST_REQUEST_ACTIONS,
} from './subcategoryListActions';

export const loadSubcategories = () => ({
  type: SUBCATEGORYLIST_ACTIONS.loadSubcategories,
});

export const loadSubcategoriesOnInterval = () => ({
  type: SUBCATEGORYLIST_ACTIONS.loadSubcategoriesOnInterval,
});

export const subcategoryClick = payload => ({
  type: SUBCATEGORYLIST_ACTIONS.subcategoryClick,
  payload,
});

export const clearExpandedSubcategories = () => ({
  type: SUBCATEGORYLIST_ACTIONS.clearExpandedSubcategories,
});

export const getSubcategoriesSuccess = payload => ({
  type: SUBCATEGORYLIST_REQUEST_ACTIONS.getSubcategoriesSuccess,
  payload,
});

export const getSubcategoriesFailure = payload => ({
  type: SUBCATEGORYLIST_REQUEST_ACTIONS.getSubcategoriesFailure,
  payload,
});

export const selectPage = payload => ({
  type: SUBCATEGORYLIST_ACTIONS.selectPage,
  payload,
});

export const resetCurrentPage = () => ({
  type: SUBCATEGORYLIST_ACTIONS.resetCurrentPage,
});
