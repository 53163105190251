import { execute, makePromise } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import LOCAL_STORAGE from 'Shared/Constants/LocalStorage';
import api from '../api';

function sendQuery(query, payload = {}) {
  const uri = api('graphql');
  const link = new HttpLink({
    uri,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE.accessToken,
      )}`,
      'auth-type': 'auth0',
    },
  });
  const operation = { query, variables: payload };

  return makePromise(execute(link, operation));
}

export default sendQuery;
