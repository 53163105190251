import { cloneDeep } from 'lodash';
import {
  SCHEDULE_ACTIONS,
  SCHEDULE_REQUEST_ACTIONS,
} from 'Features/Schedules/ScheduleActions';
import { defaultSchedule } from 'Mocks/Responses/Schedules';

export const initialScheduleState = {
  schedule_by_id: [1, 2, 3, 4, 5, 6, 7],
  data: defaultSchedule(),
  loaded: false,
};

const scheduleReducer = (state = initialScheduleState, action) => {
  switch (action.type) {
    case SCHEDULE_REQUEST_ACTIONS.getScheduleSuccess:
    case SCHEDULE_REQUEST_ACTIONS.updateScheduleSuccess:
      return {
        ...state,
        schedule_by_id: action.payload.data.schedule.map(time => time.id),
        data: action.payload.data.schedule,
        immutableData: cloneDeep(action.payload.data.schedule),
        loaded: true,
      };

    case SCHEDULE_ACTIONS.changeSchedule:
    case SCHEDULE_ACTIONS.saveSchedule:
      return {
        ...state,
        schedule_by_id: state.schedule_by_id,
        data: cloneDeep(state.data),
        immutableData: state.immutableData,
      };

    case SCHEDULE_ACTIONS.resetSchedule:
      return {
        ...state,
        schedule_by_id: state.schedule_by_id,
        data: cloneDeep(state.immutableData),
        immutableData: state.immutableData,
      };

    case SCHEDULE_ACTIONS.closeScheduleModal:
      return initialScheduleState;

    default:
      return state;
  }
};

export default scheduleReducer;
