import React from 'react';

const Success = () => (
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M7.895 14.21a.529.529 0 0 1-.373-.153l-3.158-3.158a.527.527 0 0 1 .744-.744l2.786 2.785 6.995-6.996a.527.527 0 0 1 .745.744l-7.369 7.369a.524.524 0 0 1-.372.154h.002zM10 20a9.937 9.937 0 0 1-7.072-2.928A9.93 9.93 0 0 1 0 10.002a9.937 9.937 0 0 1 2.928-7.073A9.932 9.932 0 0 1 10 0a9.934 9.934 0 0 1 7.072 2.93A9.933 9.933 0 0 1 20 10a9.933 9.933 0 0 1-2.928 7.07A9.935 9.935 0 0 1 10 20zm0-18.947c-4.934 0-8.947 4.013-8.947 8.947 0 4.934 4.013 8.947 8.947 8.947 4.934 0 8.947-4.013 8.947-8.947 0-4.934-4.013-8.947-8.947-8.947z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)" fill="#FFF">
        <path d="M-45-42H65V68H-45z" />
      </g>
    </g>
  </svg>
);

export default Success;
