import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { updateSchedulingGroupToggle } from 'Shared/SharedActionCreators';

export class SchedulingGroupToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleActive: this.props.hasGroupBool,
    };

    this.onScheduleToggle = this.onScheduleToggle.bind(this);
  }

  onScheduleToggle() {
    this.setState(
      prevState => ({ toggleActive: !prevState.toggleActive }),
      () => {
        this.handleToggleSubmit();
      },
    );
  }

  handleToggleSubmit() {
    const { schedulingGroupId, onScheduleToggle } = this.props;
    const { toggleActive: isScheduled } = this.state;
    onScheduleToggle({ schedulingGroupId, isScheduled });
  }

  render() {
    return (
      <div className="toggle-switch">
        <Toggle
          onChange={() => this.onScheduleToggle()}
          checked={this.state.toggleActive}
          icons={false}
        />
      </div>
    );
  }
}

SchedulingGroupToggle.propTypes = {
  hasGroupBool: PropTypes.bool.isRequired,
  schedulingGroupId: PropTypes.number.isRequired,
  onScheduleToggle: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onScheduleToggle: (schedulingGroupId, isScheduled) => {
    dispatch(updateSchedulingGroupToggle(schedulingGroupId, isScheduled));
  },
});

export default connect(null, mapDispatchToProps)(SchedulingGroupToggle);
