import React, { PureComponent, Fragment } from 'react';
import { keyBy, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { convertSpacesToHyphens } from 'Shared/Formatters/Functions';
import { METRIC_HEADER } from 'Shared/Constants/Constants';
import OffStatusIcon from 'Shared/Svg/OffStatusIcon';
import RunningStatusIcon from 'Shared/Svg/RunningStatusIcon';
import PausedStatusIcon from 'Shared/Svg/PausedStatusIcon';
import CampaignProgress from 'Components/CampaignProgress/CampaignProgress';
import SubcategoryManager from 'Components/SubcategoryManager/SubcategoryManager';
import LimitsModalContainer from 'Features/Limits/LimitsModalContainer';
import metricsProptypes from 'Shared/Proptypes/MetricsProptypes';
import statusesProptypes from 'Shared/Proptypes/StatusesProptypes';
import timezoneProptypes from 'Shared/Proptypes/TimezoneProptypes';
import SchedulingGroupConnector from '../SchedulingGroups/SchedulingGroupConnector';

export class SubcategoryItem extends PureComponent {
  static propTypes = {
    subcategory: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      timezone: timezoneProptypes,
      managers: PropTypes.arrayOf(PropTypes.string),
      metrics: metricsProptypes,
      statuses: statusesProptypes,
      loaded: PropTypes.bool,
      schedulingGroupsExpanded: PropTypes.bool,
    }).isRequired,
    managers: PropTypes.arrayOf(PropTypes.object),
    onSubcategoryClick: PropTypes.func.isRequired,
    isSubcategoryExpanded: PropTypes.bool,
  };

  static defaultProps = {
    isSubcategoryExpanded: false,
    managers: [],
  };

  render() {
    const {
      subcategory,
      managers,
      onSubcategoryClick,
      isSubcategoryExpanded,
    } = this.props;
    const { statuses } = subcategory;
    const statusesCount = mapValues(keyBy(statuses, 'status'), 'count');
    const subcategoryNameHyphened = convertSpacesToHyphens(
      subcategory.name,
    ).toLowerCase();

    return (
      <Fragment>
        <div
          className="grid-container grid-container-subcategory"
          data-subcategory={subcategoryNameHyphened}
        >
          <div className="subcat subcat-toggle">
            <Toggle
              onChange={() => onSubcategoryClick(subcategory.id)}
              icons={false}
              className={`arrow arrow-${
                isSubcategoryExpanded ? 'down' : 'right'
              }`}
            />
          </div>
          <button
            type="button"
            data-cy="expand-subcategory"
            className="subcat subcat-name"
            onClick={() => onSubcategoryClick(subcategory.id)}
          >
            <span>{subcategory.name}</span>
          </button>
          <div className="subcat subcat-manager">
            <SubcategoryManager managers={managers} key={subcategory.id} />
          </div>
          <div className="subcat subcat-sold-leads">
            <div className="progress-container">
              {subcategory.metrics && (
                <CampaignProgress metrics={subcategory.metrics.soldLeads}>
                  {METRIC_HEADER.ALL}
                </CampaignProgress>
              )}
            </div>
          </div>
          <div className="subcat subcat-conversions">
            <div className="progress-container">
              {subcategory.metrics && (
                <CampaignProgress metrics={subcategory.metrics.conversions}>
                  {METRIC_HEADER.ALL}
                </CampaignProgress>
              )}
            </div>
          </div>
          <div className="subcat subcat-status">
            <div className="icon-outline-pink">
              <RunningStatusIcon />
              <span>{statusesCount.RUNNING || '0'}</span>
            </div>
            <div className="icon-outline-pink">
              <PausedStatusIcon />
              <span>{statusesCount.PAUSED || '0'}</span>
            </div>
            <div className="icon-outline-pink">
              <OffStatusIcon />
              <span>{statusesCount.OFF || '0'}</span>
            </div>
          </div>
          <div className="subcat subcat-action">
            <LimitsModalContainer subcategory={subcategory} />
          </div>
        </div>
        <div
          className={`scheduling-group scheduling-group--${
            isSubcategoryExpanded ? 'show' : 'hide'
          }`}
        >
          {isSubcategoryExpanded ? (
            <SchedulingGroupConnector subcategory={subcategory} />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default SubcategoryItem;
