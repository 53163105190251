import React from 'react';
import SubcategoryDivisionPicker from 'Features/SubcategoryDivisionPicker';
import SchedulingGroupManagerConnector from 'Features/SchedulingGroupManager/SchedulingGroupManagerConnector';

const GroupManager = () => (
  <div className="app">
    <div className="subcategory-division-picker-container">
      <h2 className="heading">Campaign Group Manager</h2>
      <SubcategoryDivisionPicker />
      <SchedulingGroupManagerConnector />
    </div>
  </div>
);

export default GroupManager;
