import { put, call, select, take, takeEvery } from 'redux-saga/effects';
import { map } from 'lodash';
import {
  openAlertDialog,
  closeAlertDialog,
} from 'Components/AlertDialog/AlertDialogActionCreators';
import ALERT_DIALOG_ACTIONS from 'Components/AlertDialog/AlertDialogActions';
import {
  getLimitsUpdatedAt,
  getSubcategoryLimits,
  getSchedulingGroupLimits,
} from 'Features/Limits/LimitSelector';
import LIMITS_ERROR_MESSAGES from 'Features/Limits/LimitsErrorMessages';
import { limitMutationFormatter } from 'Features/Limits/LimitsFormatters';
import { getSelectedSubcategoryDivision } from 'Shared/Selectors/SubcategoryDivisionSelector';
import {
  getLimitsSuccess,
  getLimitsFailure,
  saveLimitsSuccess,
  saveLimitsFailure,
} from './LimitsActionCreators';
import { ACTIONS, REQUEST_ACTIONS } from './LimitsActions';
import { get, save } from './LimitApi';

function* fetchLimits(action) {
  const { subcategoryId } = action.payload;
  const division = yield select(getSelectedSubcategoryDivision);
  if (!subcategoryId) {
    yield;
  } else {
    try {
      const response = yield call(get, {
        subcategoryId,
        division,
      });
      const {
        schedulingGroupLimits,
        subcategoryLimits,
        updatedAt,
      } = response.data;
      yield put(
        getLimitsSuccess({
          schedulingGroupLimits,
          subcategoryLimits,
          updatedAt,
        }),
      );
    } catch (e) {
      yield put(getLimitsFailure(e.message));
    }
  }
}

function* saveLimits(action) {
  const { alertAccept } = action.payload;
  const timestamp = yield select(getLimitsUpdatedAt);
  const subcategoryLimits = yield select(getSubcategoryLimits);
  const schedulingGroupLimits = yield select(getSchedulingGroupLimits);
  const schedulingGroupIgnoreSubcategoryLimits = map(
    schedulingGroupLimits,
    (schedulingGroup, schedulingGroupId) => ({
      id: schedulingGroupId,
      ignoreSubcategoryLimits: schedulingGroup.ignoreSubcategoryLimits,
    }),
  );
  const limits = limitMutationFormatter(
    subcategoryLimits,
    schedulingGroupLimits,
  );

  try {
    yield call(save, {
      limits,
      schedulingGroupIgnoreSubcategoryLimits,
      timestamp,
    });
    yield put(saveLimitsSuccess());
  } catch (e) {
    yield put(saveLimitsFailure(e.message));
    yield put(
      openAlertDialog({
        alertText: LIMITS_ERROR_MESSAGES.updateLimitsError,
        alertAccept,
        alertCancel: alertAccept,
        alertAcceptText: 'OK',
        multipleOptions: false,
      }),
    );
    const confirmation = yield take(ALERT_DIALOG_ACTIONS.confirmYes);
    if (confirmation) {
      yield put(closeAlertDialog());
    }
  }
}

export function* onFetchLimits() {
  yield takeEvery(ACTIONS.openLimitsModal, fetchLimits);
  yield takeEvery(REQUEST_ACTIONS.createLimitsSuccess, fetchLimits);
}

export function* onSaveLimits() {
  yield takeEvery(ACTIONS.saveLimits, saveLimits);
}
