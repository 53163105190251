import gql from 'graphql-tag';
import sendQuery from './Request';

/*
 * There should be one static method per CRUD operation as necessary.
 */
function getDivisions(filters = {}) {
  const query = gql`
    query($filters: DivisionFilter) {
      divisions: getDivisions(filters: $filters)
    }
  `;
  return sendQuery(query, { filters });
}

export default getDivisions;
