import { connect } from 'react-redux';
import SubcategoryDivisionPickerContainer from 'Features/SubcategoryDivisionPicker/SubcategoryDivisionPickerContainer';
import {
  openAlertDialog,
  closeAlertDialog,
} from 'Components/AlertDialog/AlertDialogActionCreators';
import {
  getImmutableAdmiralCampaigns,
  getAdmiralCampaigns,
} from 'Features/SchedulingGroupManager/SchedulingGroupManagerSelector';
import { getSchedulingGroups } from 'Shared/Selectors/SchedulingGroupSelector';
import {
  getSortedSubcategories,
  getSubcategoryDropdownSearchTerm,
} from './store/subcategoryDivisionPickerSelector';
import {
  filterSubcategoriesForDropdown,
  setActiveSubcategory,
} from './store/subcategoryDivisionPickerActionCreators';
import { getActiveSubcategory } from '../../Store/Selectors/ActiveSubcategory';
import {
  getDivisions,
  getSelectedDivision,
} from '../../Shared/Selectors/DivisionSelector';
import {
  loadDivisions,
  setDivisionFilter,
} from '../../Store/Actions/ActionCreators';

const mapStateToProps = state => ({
  activeSubcategory: getActiveSubcategory(state),
  subcategories: getSortedSubcategories(state),
  schedulingGroups: getSchedulingGroups(state),
  admiralCampaigns: getAdmiralCampaigns(state),
  immutableAdmiralCampaigns: getImmutableAdmiralCampaigns(state),
  divisions: getDivisions(state),
  selectedDivision: getSelectedDivision(state),
  searchTerm: getSubcategoryDropdownSearchTerm(state),
});

const mapDispatchToProps = dispatch => ({
  onFilterSubcategories: filter => {
    dispatch(filterSubcategoriesForDropdown({ filter }));
  },
  onSubcategorySelect: subcategory => {
    dispatch(setActiveSubcategory({ subcategory }));
  },
  loadDivisions: () => {
    dispatch(loadDivisions({ managed: false }));
  },
  onDivisionSelect: division => {
    dispatch(setDivisionFilter({ division }));
  },
  onAlertDialogOpen: ({ alertText, alertCancel, alertAccept }) => {
    dispatch(openAlertDialog({ alertText, alertCancel, alertAccept }));
  },
  onAlertDialogClose: () => {
    dispatch(closeAlertDialog());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubcategoryDivisionPickerContainer);
