import { normalize, schema } from 'normalizr';
import { uniqueId, cloneDeep } from 'lodash';
import SHARED_ACTIONS from 'Shared/SharedActions';
import {
  GROUP_MANAGER_REQUEST_ACTIONS,
  GROUP_MANAGER_ACTIONS,
} from 'Features/SchedulingGroupManager/SchedulingGroupManagerActions';
import SCHEDULING_GROUP_NAME_ERROR_ACTIONS from 'Features/SchedulingGroupManager/SchedulingGroupNameError/SchedulingGroupNameErrorActions';
import { SCHEDULING_GROUPS_REQUEST_ACTIONS } from './schedulingGroupsActions';

export const initialState = () => ({
  data_by_id: [],
  data: {},
  immutableData: {},
  loaded: false,
  groupManagerLoaded: false,
  showNameError: false,
});

const schedulingGroupsEntity = new schema.Entity('schedulingGroups');
const schedulingGroupSchema = new schema.Array(schedulingGroupsEntity);

const schedulingGroupReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SCHEDULING_GROUPS_REQUEST_ACTIONS.getSchedulingGroupsSuccess: {
      const { subcategoryId, schedulingGroups } = action.payload;
      const newData = {
        ...state.data,
        [subcategoryId]: schedulingGroups,
      };
      return {
        ...state,
        type: initialState().type,
        data_by_id: state.data_by_id.concat(subcategoryId),
        data: newData,
        immutableData: cloneDeep(newData),
        loaded: true,
      };
    }

    case SCHEDULING_GROUPS_REQUEST_ACTIONS.getSchedulingGroupsFailure: {
      const { subcategoryId, message } = action.payload;
      const newData = {
        [subcategoryId]: [{ message }],
      };
      return {
        ...state,
        errorMessage: newData,
        loaded: false,
      };
    }

    case GROUP_MANAGER_REQUEST_ACTIONS.getSchedulingGroupsForGroupManagerSuccess: {
      const normalized = normalize(action.payload, schedulingGroupSchema);
      const {
        schedulingGroups: normalizedSchedulingGroups,
      } = normalized.entities;
      return {
        ...state,
        type: initialState().type,
        data: normalizedSchedulingGroups,
        immutableData: cloneDeep(normalizedSchedulingGroups),
        groupManagerLoaded: true,
      };
    }

    case GROUP_MANAGER_REQUEST_ACTIONS.getSchedulingGroupsForGroupManagerFailure: {
      const { message } = action.payload;
      return {
        ...state,
        errorMessage: message,
        groupManagerLoaded: false,
      };
    }

    case SHARED_ACTIONS.resetSchedulingGroupsState: {
      return initialState();
    }

    case GROUP_MANAGER_ACTIONS.createNewSchedulingGroup: {
      const { newSchedulingGroupName } = action.payload;
      const draftGroupId = uniqueId('draftSchedulingGroup_');

      const newSchedulingGroupObject = {
        id: draftGroupId,
        name: newSchedulingGroupName,
      };
      return {
        ...state,
        data: { ...state.data, [draftGroupId]: newSchedulingGroupObject },
      };
    }

    case GROUP_MANAGER_ACTIONS.deleteSchedulingGroupSuccess: {
      const schedulingGroupId = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [schedulingGroupId]: {
            ...state.data[schedulingGroupId],
            deleted: true,
          },
        },
      };
    }

    case GROUP_MANAGER_ACTIONS.editSchedulingGroup: {
      const schedulingGroupId = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [schedulingGroupId]: {
            ...state.data[schedulingGroupId],
            editing: true,
          },
        },
      };
    }

    case GROUP_MANAGER_ACTIONS.renameSchedulingGroup: {
      const { schedulingGroupId, name } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [schedulingGroupId]: {
            ...state.data[schedulingGroupId],
            editing: false,
            name,
          },
        },
      };
    }

    case GROUP_MANAGER_ACTIONS.cancelRenameSchedulingGroup: {
      const schedulingGroupId = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [schedulingGroupId]: {
            ...state.data[schedulingGroupId],
            editing: false,
          },
        },
      };
    }

    case GROUP_MANAGER_REQUEST_ACTIONS.saveSchedulingGroupsFailure:
    case GROUP_MANAGER_REQUEST_ACTIONS.updateSchedulingGroupsFailure:
    case GROUP_MANAGER_REQUEST_ACTIONS.deleteSchedulingGroupsFailure: {
      const { message } = action.payload;
      return {
        ...state,
        errorMessage: message,
      };
    }

    case SCHEDULING_GROUP_NAME_ERROR_ACTIONS.showSchedulingGroupNameError:
      return {
        ...state,
        showNameError: true,
      };

    case SCHEDULING_GROUP_NAME_ERROR_ACTIONS.hideSchedulingGroupNameError:
      return {
        ...state,
        showNameError: false,
      };

    default:
      return state;
  }
};

export default schedulingGroupReducer;
