import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import {
  SCHEDULING_GROUP_STATUSES,
  TOOLTIP_MESSAGE,
} from 'Shared/Constants/Constants';
import { convertSpacesToHyphens } from 'Shared/Formatters/Functions';
import SchedulingGroupToggle from 'Components/SchedulingGroupToggle/SchedulingGroupToggle';
import ScheduleModalContainer from 'Features/Schedules/ScheduleModalConnector';
import CampaignProgress from 'Components/CampaignProgress/CampaignProgress';
import Tooltip from '../../../Components/Tooltip/Tooltip';
import InformationIcon from '../../../Shared/Svg/InformationIcon';

const SchedulingGroup = ({
  schedulingGroup,
  subcategory,
  icon,
  firstGroup,
}) => {
  const {
    division,
    id,
    isScheduled,
    name,
    status,
    ignoreSubcategoryLimits,
  } = schedulingGroup;
  const statusStringHashMap = {
    [SCHEDULING_GROUP_STATUSES.OFF]: 'Off',
    [SCHEDULING_GROUP_STATUSES.RUNNING]: 'Running',
    [SCHEDULING_GROUP_STATUSES.PAUSED_SCHEDULE]: 'Schedule',
    [SCHEDULING_GROUP_STATUSES.PAUSED_LIMIT]: 'Limit',
  };
  const informationTooltip = (
    <div className="scheduling-group-information icon-fill-pink">
      <InformationIcon />
      <Tooltip content={TOOLTIP_MESSAGE.IGNORE_SUBCATEGORY_LIMITS} />
    </div>
  );
  const campaignProgressInformation = ignoreSubcategoryLimits
    ? informationTooltip
    : '';

  const statusString = statusStringHashMap[status];
  const schedulingGroupNameHyphened = convertSpacesToHyphens(
    `${division}-${name}`,
  ).toLowerCase();

  return (
    <div className="grid-container grid-container-scheduling-groups">
      <div className="scheduling-group scheduling-group-toggle">
        <SchedulingGroupToggle
          endpoint="groups"
          schedulingGroupId={id}
          hasGroupBool={isScheduled}
        />
      </div>
      <div
        className="scheduling-group__name"
        data-scheduling-group={schedulingGroupNameHyphened}
      >
        <div
          className={`scheduling-group__folder-lines${
            firstGroup ? ' scheduling-group__folder-lines--first-group' : ''
          }`}
        />
        <NavLink
          className="subcategory-item-link"
          data-cy="scheduling-group-link"
          to={`subcategories/${subcategory.id}/schedulinggroup/${schedulingGroup.id}`}
        >
          <span>{`${division} - ${name}`}</span>
        </NavLink>
      </div>
      <div className="scheduling-group-sold-leads">
        <div className="progress-container">
          <CampaignProgress
            color="green"
            metrics={schedulingGroup.metrics.soldLeads}
          >
            {campaignProgressInformation}
          </CampaignProgress>
        </div>
      </div>
      <div className="scheduling-group-conversions">
        <div className="progress-container">
          <CampaignProgress
            color="green"
            metrics={schedulingGroup.metrics.conversions}
          />
        </div>
      </div>
      <div className="scheduling-group scheduling-group-status">
        <span className="icon-outline-black">
          {icon}
          {` ${statusString}`}
        </span>
      </div>
      <div className="scheduling-group-actions">
        <ScheduleModalContainer
          id={id}
          name={name}
          subcatName={subcategory.name}
          subcategoryId={subcategory.id}
          division={division}
        />
      </div>
    </div>
  );
};

SchedulingGroup.defaultProps = {
  firstGroup: false,
};

SchedulingGroup.propTypes = {
  schedulingGroup: PropTypes.shape({
    division: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isScheduled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(values(SCHEDULING_GROUP_STATUSES)).isRequired,
    ignoreSubcategoryLimits: PropTypes.bool.isRequired,
    metrics: PropTypes.shape({
      soldLeads: PropTypes.oneOfType([PropTypes.object]).isRequired,
      conversions: PropTypes.oneOfType([PropTypes.object]).isRequired,
    }).isRequired,
  }).isRequired,
  subcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  firstGroup: PropTypes.bool,
};

export default SchedulingGroup;
