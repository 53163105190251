import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ name }) => {
  const classNames = `loader loader-${name.replace(/\s+/g, '-').toLowerCase()}`;
  return (
    <div className={classNames}>
      <span className="spinner" />
      <h2>{`Loading ${name}...`}</h2>
    </div>
  );
};

Loader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Loader;
