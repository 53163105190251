import React from 'react';
import { useAuth0 } from '../Auth0/react-auth0-spa';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      id="login-button"
      onClick={() =>
        loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        })
      }
    >
      Log in
    </button>
  );
};

export default Login;
