import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { defaultTheme, InputField, Icon } from '@mvf/product-components';
import BaseDropdownItem from 'Components/Dropdown/BaseDropdownItem';
import Dropdown from 'Components/Dropdown/BaseDropdown';
import subcategoryDivisionPickerStyles from './subcategoryDivisionPickerStyles';

const SubcategoryDivisionPicker = props => {
  const {
    activeSubcategory,
    subcategories,
    filterSubcategories,
    searchTerm,
    onSubcategorySelect,
    divisions,
    loadDivisions,
    onDivisionSelect,
    selectedDivision,
  } = props;

  const subcategorySelected = activeSubcategory.loaded
    ? activeSubcategory.name
    : '-';
  const divisionSelected = selectedDivision || '-';
  const styles = subcategoryDivisionPickerStyles(defaultTheme);

  return (
    <div className="picker-container">
      <div className="division-picker">
        <div className="division-picker-header">
          <h4>Select Division * </h4>
        </div>
        <Dropdown onMenuMount={loadDivisions} dropdownHeader={divisionSelected}>
          {map(divisions, division => (
            <BaseDropdownItem
              key={division}
              name={division}
              handleClick={() => onDivisionSelect(division)}
            />
          ))}
        </Dropdown>
      </div>
      <div className="subcategory-picker">
        <div className="subcategory-picker-header">
          <h4>Subcategory * </h4>
        </div>
        <Dropdown
          onMenuMount={filterSubcategories}
          dropdownHeader={subcategorySelected}
        >
          <div style={styles.search}>
            <InputField
              placeholder="Search"
              onChange={filterSubcategories}
              value={searchTerm}
              icon={Icon.SEARCH}
            />
          </div>
          {map(subcategories, subcategory => (
            <BaseDropdownItem
              key={subcategory.id}
              name={subcategory.name}
              handleClick={() =>
                onSubcategorySelect({
                  id: subcategory.id,
                  name: subcategory.name,
                })
              }
            />
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

SubcategoryDivisionPicker.propTypes = {
  filterSubcategories: PropTypes.func.isRequired,
  activeSubcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  searchTerm: PropTypes.string,
  onSubcategorySelect: PropTypes.func.isRequired,
  loadDivisions: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDivisionSelect: PropTypes.func.isRequired,
  selectedDivision: PropTypes.string.isRequired,
};

SubcategoryDivisionPicker.defaultProps = {
  searchTerm: '',
};

export default SubcategoryDivisionPicker;
