export const getActiveSubcategory = state =>
  state.activeSubcategory.loaded
    ? {
        id: state.activeSubcategory.data.id,
        name: state.activeSubcategory.data.name,
        loaded: true,
      }
    : {
        loaded: false,
      };

export const getActiveSubcategoryName = state =>
  getActiveSubcategory(state).name;
