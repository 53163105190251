const appConfig = {
  appName: 'Mercury Scheduler',
};

const appConfigReducer = (state = appConfig, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default appConfigReducer;
