import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from '../../../Components/Error/Error';

class SchedulingGroupErrorContainer extends Component {
  render() {
    const { isErrorVisible } = this.props;
    let error = <div />;
    if (isErrorVisible) {
      error = <Error message="Name already exists" type="input" />;
    }

    return error;
  }
}

SchedulingGroupErrorContainer.propTypes = {
  isErrorVisible: PropTypes.bool.isRequired,
};

export default SchedulingGroupErrorContainer;
