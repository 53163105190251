const SHARED_ACTIONS = {
  resetSchedulingGroupsState: 'RESET_SCHEDULING_GROUPS_STATE',
  resetActiveSchedulingGroupState: 'RESET_ACTIVE_SCHEDULING_GROUP_STATE',
  setCampaignNameSearchTerm: 'SET_CAMPAIGN_NAME_SEARCH_TERM',
  resetActiveSubcategoryState: 'RESET_ACTIVE_SUBCATEGORY_STATE',
  updateSchedulingGroupToggle: 'UPDATE_SCHEDULING_GROUP_TOGGLE',
  setSubcategoriesSearchTerm: 'SET_SUBCATEGORIES_SEARCH_TERM',
};

export default SHARED_ACTIONS;
