import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmYes } from 'Components/AlertDialog/AlertDialogActionCreators';
import {
  getSubcategoryLimits,
  getSchedulingGroupLimits,
  getLimitsLoaded,
} from './LimitSelector';
import getTimezoneSelector from '../../Store/Selectors/TimezoneSelector';
import LimitsModal from './LimitsModal';
import {
  changeLimit,
  openLimitsModal,
  resetLimits,
  setIgnoreSubcategoryLimits,
  saveLimits,
  closeLimitsModal,
} from './LimitsActionCreators';
import { updateTimezone } from '../../Store/Actions/ActionCreators';
import { limitInputFormatter } from './LimitsFormatters';

export class LimitsModalContainer extends Component {
  constructor(props) {
    super(props);
    this.changeFieldValues = this.changeFieldValues.bind(this);
    this.saveLimits = this.saveLimits.bind(this);
    this.updateRemoteTimezone = this.updateRemoteTimezone.bind(this);
  }

  changeFieldValues(inputValue, currentLimit) {
    const { onChangeLimit } = this.props;
    const newLimit = limitInputFormatter(inputValue, currentLimit);
    onChangeLimit(newLimit);
  }

  updateRemoteTimezone() {
    const { onSaveTimezone } = this.props;
    const timezone = this.props.timezone.data.timezone.name;
    const { subcategoryId } = this.props.timezone.data;
    onSaveTimezone({ subcategoryId, timezone });
  }

  saveLimits() {
    const { onSaveLimits } = this.props;
    onSaveLimits();
    this.updateRemoteTimezone();
  }

  render() {
    const {
      subcategoryLimits,
      schedulingGroupLimits,
      subcategory,
      onOpenLimitsModal,
      onResetLimits,
      onSetIgnoreSubcategoryLimits,
      limitsLoaded,
      onCloseLimitsModal,
    } = this.props;

    return (
      <LimitsModal
        subcategoryLimits={subcategoryLimits}
        schedulingGroupLimits={schedulingGroupLimits}
        subcategory={subcategory}
        onOpenLimitsModal={() => onOpenLimitsModal(subcategory.id)}
        onResetLimits={onResetLimits}
        onSetIgnoreSubcategoryLimits={onSetIgnoreSubcategoryLimits}
        onSaveLimits={this.saveLimits}
        onChangeFieldValues={this.changeFieldValues}
        limitsLoaded={limitsLoaded}
        onCloseLimitsModal={onCloseLimitsModal}
      />
    );
  }
}

LimitsModalContainer.propTypes = {
  subcategoryLimits: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
      soldLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  ).isRequired,
  schedulingGroupLimits: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      division: PropTypes.string.isRequired,
      ignoreSubcategoryLimits: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
      soldLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  ).isRequired,
  subcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  timezone: PropTypes.shape({
    data: PropTypes.shape({
      timezone: PropTypes.shape({ name: PropTypes.string }),
      subcategoryId: PropTypes.number,
    }),
    loaded: PropTypes.bool,
  }).isRequired,
  onOpenLimitsModal: PropTypes.func.isRequired,
  onCloseLimitsModal: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  onSaveLimits: PropTypes.func.isRequired,
  onSaveTimezone: PropTypes.func.isRequired,
  onResetLimits: PropTypes.func.isRequired,
  onSetIgnoreSubcategoryLimits: PropTypes.func.isRequired,
  limitsLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  subcategoryLimits: getSubcategoryLimits(state),
  schedulingGroupLimits: getSchedulingGroupLimits(state),
  timezone: getTimezoneSelector(state),
  limitsLoaded: getLimitsLoaded(state),
});

const mapDispatchToProps = dispatch => ({
  onOpenLimitsModal: subcategoryId => {
    dispatch(openLimitsModal({ subcategoryId }));
  },
  onChangeLimit: limit => {
    dispatch(changeLimit(limit));
  },
  onSaveLimits: () => {
    dispatch(
      saveLimits({
        alertAccept: () => {
          dispatch(confirmYes());
        },
      }),
    );
  },
  onSaveTimezone: (id, timezone) => {
    dispatch(updateTimezone(id, timezone));
  },
  onResetLimits: () => {
    dispatch(resetLimits());
  },
  onSetIgnoreSubcategoryLimits: ({
    schedulingGroupId,
    ignoreSubcategoryLimits,
  }) => {
    dispatch(
      setIgnoreSubcategoryLimits({
        schedulingGroupId,
        ignoreSubcategoryLimits,
      }),
    );
  },
  onCloseLimitsModal: () => {
    dispatch(closeLimitsModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LimitsModalContainer);
