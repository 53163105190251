import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({ title, modalToggle }) => (
  <header className="modal-header">
    <h4 className="modal-header--title">{title}</h4>
    <button
      type="button"
      className="modal-header--close-button"
      onClick={modalToggle}
    >
      X
    </button>
  </header>
);

ModalHeader.defaultProps = {
  modalToggle: () => {},
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  modalToggle: PropTypes.func,
};

export default ModalHeader;
