import React from 'react';
import LOCAL_STORAGE from 'Shared/Constants/LocalStorage';
import { useAuth0 } from '../Auth0/react-auth0-spa';

const Logout = () => {
  const { logout } = useAuth0();

  const removeAccessTokenAndLogOut = () => {
    localStorage.removeItem(LOCAL_STORAGE.accessToken);
    localStorage.removeItem(LOCAL_STORAGE.tokenExpiresAt);

    logout({ returnTo: `https://${window.location.hostname}` });
  };

  return (
    <button
      type="button"
      id="logout-button"
      onClick={() => removeAccessTokenAndLogOut()}
    >
      Log out
    </button>
  );
};

export default Logout;
