import { call, put, takeEvery, select } from 'redux-saga/effects';
import getDivisionsApi from '../../Api/GraphQL/DivisionApi';
import { ACTIONS } from '../Actions/Actions';
import {
  getSubcategoryDivisionsFailure,
  getSubcategoryDivisionsSuccess,
} from '../Actions/ActionCreators';
import { getSubcategoryDivisions } from '../../Shared/Selectors/SubcategoryDivisionSelector';

function* fetchSubcategoryDivisions() {
  try {
    const divisions = yield select(getSubcategoryDivisions);

    if (divisions.length > 0) {
      return;
    }

    const { data } = yield call(() => getDivisionsApi({ managed: true }));
    yield put(getSubcategoryDivisionsSuccess(data.divisions));
  } catch (e) {
    yield put(getSubcategoryDivisionsFailure({ message: e.message }));
  }
}

export default function* onFetchSubcategoryDivisions() {
  yield takeEvery(ACTIONS.loadSubcategoryDivisions, fetchSubcategoryDivisions);
}
