import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle } from 'reactstrap';
import BaseDropdownMenu from './BaseDropdownMenu';
import ChevronDownIcon from './Svg/ChevronDownIcon';

class BaseDropdown extends Component {
  static propTypes = {
    dropdownHeader: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.node.isRequired,
    onMenuMount: PropTypes.func,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    icon: <ChevronDownIcon />,
    onMenuMount: () => {},
    style: {},
  };

  state = {
    dropdownOpen: false,
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { dropdownHeader, onMenuMount, children, icon, style } = this.props;
    const { dropdownOpen } = this.state;

    return (
      <Dropdown
        className="base-dropdown"
        isOpen={dropdownOpen}
        size="sm"
        toggle={this.toggleDropdown}
        style={style}
      >
        <DropdownToggle>
          <div className="base-dropdown">
            <span className="base-dropdown__text">{dropdownHeader}</span>
            <span className="base-dropdown__icon">{icon}</span>
          </div>
        </DropdownToggle>
        {dropdownOpen && (
          <BaseDropdownMenu onMenuMount={onMenuMount}>
            {children}
          </BaseDropdownMenu>
        )}
      </Dropdown>
    );
  }
}

export default BaseDropdown;
