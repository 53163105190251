import { first, flatten, map, omit, values } from 'lodash';
import { LIMIT_FORMATS } from './LimitsConstants';

export const limitInputFormatter = (inputValue, currentLimit) => {
  const newLimit = { ...currentLimit };
  const { empty, infinity, numbersOrInfinity } = LIMIT_FORMATS;

  if (empty.test(inputValue)) {
    newLimit.limit = 0;
    newLimit.unlimited = true;
  }
  if (numbersOrInfinity.test(inputValue)) {
    const limit = inputValue.replace(`${infinity}`, '');
    newLimit.limit = parseInt(limit, 10);
    newLimit.unlimited = false;
  }
  return newLimit;
};

export const removeLimitProperties = limits =>
  limits.map(limit => {
    const limitInput = omit(limit, ['dayOfWeek']);
    return limitInput;
  });

export const limitMutationFormatter = (
  subcategoryLimits,
  schedulingGroupLimits,
) => {
  let limits = map(schedulingGroupLimits, schedulingGroup => {
    const { conversions, soldLeads } = schedulingGroup;
    return conversions.concat(soldLeads);
  });
  const { conversions, soldLeads } = first(values(subcategoryLimits));
  limits = limits.concat(conversions.concat(soldLeads));
  return removeLimitProperties(flatten(limits));
};
