export const SUBCATEGORYLIST_ACTIONS = {
  loadSubcategories: 'LOAD_SUBCATEGORIES',
  loadSubcategoriesOnInterval: 'LOAD_SUBCATEGORIES_ON_INTERVAL',
  subcategoryClick: 'SUBCATEGORY_CLICK',
  clearExpandedSubcategories: 'CLEAR_EXPANDED_SUBCATEGORIES',
  selectPage: 'SELECT_PAGE',
  resetCurrentPage: 'RESET_CURRENT_PAGE',
};

export const SUBCATEGORYLIST_REQUEST_ACTIONS = {
  getSubcategoriesSuccess: 'GET_SUBCATEGORIES_SUCCEEDED',
  getSubcategoriesFailure: 'GET_SUBCATEGORIES_FAILED',
};
