import { connect } from 'react-redux';
import {
  resetSchedulingGroupsState,
  resetActiveSchedulingGroupState,
  resetActiveSubcategoryState,
} from 'Shared/SharedActionCreators';
import {
  getActiveSchedulingGroup,
  getActiveSchedulingGroupLoaded,
} from 'Shared/Selectors/SchedulingGroupSelector';
import {
  showSchedulingGroupNameError,
  hideSchedulingGroupNameError,
} from 'Features/SchedulingGroupManager/SchedulingGroupNameError/SchedulingGroupNameErrorActionCreators';
import { getActiveSubcategory } from '../../Store/Selectors/ActiveSubcategory';
import { getSelectedDivision } from '../../Shared/Selectors/DivisionSelector';
import {
  loadSchedulingGroupsForGroupManager,
  setActiveSchedulingGroup,
  createNewSchedulingGroup,
  cancelNewSchedulingGroups,
  saveSchedulingGroups,
  selectAdmiralCampaign,
  deselectAdmiralCampaign,
  resetSelectedAdmiralCampaigns,
  moveToSchedulingGroup,
  moveToNewSchedulingGroup,
} from './SchedulingGroupManagerActionCreators';
import SchedulingGroupManagerContainer from './SchedulingGroupManagerContainer';
import {
  getActiveSchedulingGroupAdmiralCampaigns,
  getSortedSchedulingGroups,
  getSelectedAdmiralCampaigns,
  getSchedulingGroupsForDropdown,
  getNumberOfAdmiralCampaigns,
  getActiveSubcategoryEnableFooterButtons,
} from './SchedulingGroupManagerSelector';

const mapStateToProps = state => ({
  selectedDivision: getSelectedDivision(state),
  activeSubcategory: getActiveSubcategory(state),
  activeSchedulingGroup: getActiveSchedulingGroup(state),
  activeSchedulingGroupLoaded: getActiveSchedulingGroupLoaded(state),
  activeSchedulingGroupAdmiralCampaigns: getActiveSchedulingGroupAdmiralCampaigns(
    state,
  ),
  numberOfAdmiralCampaigns: getNumberOfAdmiralCampaigns(state),
  sortedSchedulingGroups: getSortedSchedulingGroups(state),
  selectedAdmiralCampaigns: getSelectedAdmiralCampaigns(state),
  schedulingGroupsForDropdown: getSchedulingGroupsForDropdown(state),
  enableFooterButtons: getActiveSubcategoryEnableFooterButtons(state),
});

const mapDispatchToProps = dispatch => ({
  setActiveSubcategoryStateToInitialState: () => {
    dispatch(resetActiveSubcategoryState());
  },
  onFetchSchedulingGroups: () => {
    dispatch(loadSchedulingGroupsForGroupManager());
  },
  onCancelNewSchedulingGroups: subcategoryId => {
    dispatch(cancelNewSchedulingGroups({ subcategoryId }));
  },
  setSchedulingGroupsToInitialState: () => {
    dispatch(resetSchedulingGroupsState());
  },
  onSetActiveSchedulingGroup: schedulingGroup => {
    dispatch(setActiveSchedulingGroup(schedulingGroup));
  },
  setActiveSchedulingGroupToInitialState: () => {
    dispatch(resetActiveSchedulingGroupState());
  },
  onCreateNewSchedulingGroup: newSchedulingGroupName => {
    dispatch(createNewSchedulingGroup({ newSchedulingGroupName }));
  },
  onSaveSchedulingGroups: () => {
    dispatch(saveSchedulingGroups());
  },
  onSelectAdmiralCampaign: admiralCampaign => {
    dispatch(selectAdmiralCampaign({ admiralCampaign }));
  },
  onDeselectAdmiralCampaign: admiralCampaign => {
    dispatch(deselectAdmiralCampaign({ admiralCampaign }));
  },
  onMoveToSchedulingGroup: (selectedAdmiralCampaigns, schedulingGroupId) => {
    if (
      typeof schedulingGroupId === 'string' &&
      schedulingGroupId.indexOf('draftSchedulingGroup_') > -1
    ) {
      dispatch(
        moveToNewSchedulingGroup({
          selectedAdmiralCampaigns,
          schedulingGroupId,
        }),
      );
    } else {
      dispatch(
        moveToSchedulingGroup({ selectedAdmiralCampaigns, schedulingGroupId }),
      );
    }
  },
  setSelectedAdmiralCampaignsToInitialState: () => {
    dispatch(resetSelectedAdmiralCampaigns());
  },
  onShowSchedulingGroupNameError: () => {
    dispatch(showSchedulingGroupNameError());
  },
  onHideSchedulingGroupNameError: () => {
    dispatch(hideSchedulingGroupNameError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchedulingGroupManagerContainer);
