import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { onFetchLimits, onSaveLimits } from 'Features/Limits/LimitSaga';
import scheduleReducer from 'Features/Schedules/ScheduleReducer';
import limitReducer from 'Features/Limits/LimitReducer';
import {
  onFetchSchedule,
  onUpdateSchedule,
} from 'Features/Schedules/ScheduleSaga';
import filterBarReducer from 'Features/FilterBar/store/filterBarReducer';
import onFilterSubcategories from 'Features/SubcategoryDivisionPicker/store/subcategoryDivisionPickerSaga';
import selectedAdmiralCampaignsReducer from 'Features/SchedulingGroupManager/SelectedAdmiralCampaignsReducer';
import admiralCampaignReducer from 'Features/SchedulingGroupManager/AdmiralCampaignReducer';
import admiralCampaignTimestampReducer from 'Features/SchedulingGroupManager/AdmiralCampaignTimestampReducer';
import {
  onFetchSchedulingGroupsForGroupManager,
  onFetchAdmiralCampaigns,
  onSaveSchedulingGroups,
  onDeleteSchedulingGroup,
} from 'Features/SchedulingGroupManager/SchedulingGroupManagerSaga';
import {
  onGetPlatformCampaigns,
  onUpdatePlatformCampaignStatus,
} from 'Features/PlatformCampaigns/PlatformCampaignSaga';
import { platformCampaignReducer } from 'Features/PlatformCampaigns/PlatformCampaignReducer';
import subcategoriesReducer from 'Features/SubcategoryList/SubcategoriesReducer';
import subcategoryDropdownReducer from 'Features/SubcategoryDivisionPicker/store/subcategoryDropdownReducer';
import subcategoriesPageUIReducer from 'Features/SubcategoryList/SubcategoriesPageUIReducer';
import activeSubcategoryReducer from 'Features/SubcategoryList/ActiveSubcategoryReducer';
import onFetchSubcategories from 'Features/SubcategoryList/subcategoryListSaga';
import onFetchSchedulingGroups from 'Features/SchedulingGroups/store/schedulingGroupSaga';
import onUpdateSchedulingGroupToggle from 'Features/SchedulingGroups/store/schedulingToggleSaga';
import activeSchedulingGroupReducer from 'Features/SchedulingGroups/store/activeSchedulingGroupReducer';
import schedulingGroupReducer from 'Features/SchedulingGroups/store/SchedulingGroupReducer';
import alertDialogReducer from 'Components/AlertDialog/AlertDialogReducer';
import snackbarReducer from 'Components/Snackbar/SnackbarReducer';
import LogRocket from 'logrocket';
import appConfigReducer from './Reducers/AppConfigReducer';
import managerReducer from './Reducers/ManagerReducer';
import subcategoryDivisionReducer from './Reducers/SubcategoryDivisionReducer';
import divisionReducer from './Reducers/DivisionReducer';
import { onFetchManagers } from './Sagas/ManagerSaga';
import onFetchDivisions from './Sagas/DivisionSaga';
import {
  onGetTimezoneForSubcategory,
  onUpdateTimezone,
} from './Sagas/TimezoneSaga';
import onGetTimezones from './Sagas/TimezonesSaga';
import timezonesReducer from './Reducers/TimezonesReducer';
import timezoneReducer from './Reducers/TimezoneReducer';
import { loadState, saveState } from './localStorage/localStorage';
import onFetchSubcategoryDivisions from './Sagas/SubcategoryDivisionSaga';

const rootReducers = {
  schedulingGroups: schedulingGroupReducer,
  subcategories: subcategoriesReducer,
  subcategoriesPageUI: subcategoriesPageUIReducer,
  subcategoryDropdown: subcategoryDropdownReducer,
  activeSubcategory: activeSubcategoryReducer,
  timezones: timezonesReducer,
  timezone: timezoneReducer,
  schedules: scheduleReducer,
  limits: limitReducer,
  appConfig: appConfigReducer,
  managers: managerReducer,
  subcategoryDivisions: subcategoryDivisionReducer,
  divisions: divisionReducer,
  statuses: filterBarReducer,
  alertDialog: alertDialogReducer,
  snackbars: snackbarReducer,
  admiralCampaigns: admiralCampaignReducer,
  activeSchedulingGroup: activeSchedulingGroupReducer,
  admiralCampaignTimestamp: admiralCampaignTimestampReducer,
  selectedAdmiralCampaigns: selectedAdmiralCampaignsReducer,
  platformCampaigns: platformCampaignReducer,
};

const sagaMiddleware = createSagaMiddleware();
let middleware = composeWithDevTools(
  applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()),
);
if (window.location.hostname === 'mercury-dev.mvfglobal.com') {
  const logger = createLogger({ collapsed: true });
  middleware = composeWithDevTools(applyMiddleware(sagaMiddleware, logger));
}

const persistedState = loadState();

function makeStore() {
  return {
    ...createStore(combineReducers(rootReducers), persistedState, middleware),
    runSaga: sagaMiddleware.run,
  };
}

function* rootSaga() {
  yield all([
    fork(onFetchSchedule),
    fork(onUpdateSchedule),
    fork(onFetchSubcategories),
    fork(onFilterSubcategories),
    fork(onFetchSchedulingGroups),
    fork(onUpdateSchedulingGroupToggle),
    fork(onFetchLimits),
    fork(onSaveLimits),
    fork(onFetchManagers),
    fork(onFetchSubcategoryDivisions),
    fork(onFetchDivisions),
    fork(onGetTimezones),
    fork(onGetTimezoneForSubcategory),
    fork(onUpdateTimezone),
    fork(onFetchAdmiralCampaigns),
    fork(onFetchSchedulingGroupsForGroupManager),
    fork(onSaveSchedulingGroups),
    fork(onDeleteSchedulingGroup),
    fork(onGetPlatformCampaigns),
    fork(onUpdatePlatformCampaignStatus),
  ]);
}

let store;

export function getStore() {
  return store;
}

export function loadStateAndMakeStore() {
  store = makeStore();
  store.subscribe(() => {
    saveState(store.getState());
  });
  store.runSaga(rootSaga);
  return store;
}

export default function makeStoreAndRunSaga() {
  store = makeStore();
  store.runSaga(rootSaga);
  return store;
}
