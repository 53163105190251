import PropTypes from 'prop-types';
import { map, values } from 'lodash';
import React, { Component } from 'react';
import SchedulingGroup from './components/SchedulingGroup';
import { SCHEDULING_GROUP_STATUSES } from '../../Shared/Constants/Constants';
import OffStatusIcon from '../../Shared/Svg/OffStatusIcon';
import RunningStatusIcon from '../../Shared/Svg/RunningStatusIcon';
import PausedStatusIcon from '../../Shared/Svg/PausedStatusIcon';
import Error from '../../Components/Error/Error';

export class SchedulingGroupContainer extends Component {
  static propTypes = {
    subcategory: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
    schedulingGroups: PropTypes.arrayOf(
      PropTypes.shape({
        division: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isScheduled: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.oneOf(values(SCHEDULING_GROUP_STATUSES)).isRequired,
        ignoreSubcategoryLimits: PropTypes.bool.isRequired,
        metrics: PropTypes.shape({
          soldLeads: PropTypes.oneOfType([PropTypes.object]).isRequired,
          conversions: PropTypes.oneOfType([PropTypes.object]).isRequired,
        }).isRequired,
        message: PropTypes.string,
      }).isRequired,
    ),
    onPageLoad: PropTypes.func.isRequired,
  };

  static defaultProps = {
    schedulingGroups: undefined,
  };

  componentDidMount() {
    const { onPageLoad, subcategory } = this.props;
    this.pollInterval = onPageLoad(subcategory.id);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  render() {
    const { subcategory, schedulingGroups } = this.props;
    const statusIconHashMap = {
      [SCHEDULING_GROUP_STATUSES.OFF]: <OffStatusIcon />,
      [SCHEDULING_GROUP_STATUSES.RUNNING]: <RunningStatusIcon />,
      [SCHEDULING_GROUP_STATUSES.PAUSED_SCHEDULE]: <PausedStatusIcon />,
      [SCHEDULING_GROUP_STATUSES.PAUSED_LIMIT]: <PausedStatusIcon />,
    };

    if (!schedulingGroups || schedulingGroups.length === 0) {
      return <div />;
    }

    const error = schedulingGroups[0].message;
    if (error) return <Error message={error} type="default" />;
    return map(schedulingGroups, (schedulingGroup, index) => {
      const statusIcon = statusIconHashMap[schedulingGroup.status];
      const firstGroup = index === 0;
      return (
        <SchedulingGroup
          schedulingGroup={schedulingGroup}
          subcategory={subcategory}
          key={schedulingGroup.id}
          icon={statusIcon}
          firstGroup={firstGroup}
        />
      );
    });
  }
}

export default SchedulingGroupContainer;
