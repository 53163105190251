import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import AdmiralCampaignItem from './AdmiralCampaignItem';

const AdmiralCampaignsList = props => {
  const { admiralCampaigns, handleAdmiralCampaignSelect } = props;
  return map(Object.keys(admiralCampaigns), data => (
    <AdmiralCampaignItem
      key={admiralCampaigns[data].id}
      admiralCampaign={admiralCampaigns[data]}
      handleAdmiralCampaignSelect={handleAdmiralCampaignSelect}
    />
  ));
};

AdmiralCampaignsList.propTypes = {
  admiralCampaigns: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  handleAdmiralCampaignSelect: PropTypes.func.isRequired,
};

export default AdmiralCampaignsList;
