export const PLATFORM_CAMPAIGN_ACTIONS = {
  loadPlatformCampaigns: 'LOAD_PLATFORM_CAMPAIGNS',
  updatePlatformCampaignStatus: 'UPDATE_PLATFORM_CAMPAIGN_STATUS',
};

export const PLATFORM_CAMPAIGN_REQUEST_ACTIONS = {
  getPlatformCampaignsSuccess: 'GET_PLATFORM_CAMPAIGNS_SUCCESS',
  getPlatformCampaignsFailure: 'GET_PLATFORM_CAMPAIGNS_FAILURE',
  updatePlatformCampaignStatusSuccess:
    'UPDATE_PLATFORM_CAMPAIGN_STATUS_SUCCESS',
  updatePlatformCampaignStatusFailure:
    'UPDATE_PLATFORM_CAMPAIGN_STATUS_FAILURE',
};
