import React from 'react';
import PropTypes from 'prop-types';

const SchedulingGroupManagerHeader = props => {
  const { selectedDivision, activeSubcategory, campaignCount } = props;
  const plural = campaignCount > 1 ? 's' : '';
  const admiralCampaignText = `Admiral Campaign${plural}`;

  return (
    <header className="scheduling-group-manager-header">
      <h3 className="scheduling-group-manager-header-text">
        {selectedDivision}
        <span> - </span>
      </h3>
      <p>
        {`${activeSubcategory.name} (${campaignCount} ${admiralCampaignText})`}
      </p>
    </header>
  );
};

SchedulingGroupManagerHeader.propTypes = {
  selectedDivision: PropTypes.string.isRequired,
  activeSubcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  campaignCount: PropTypes.number.isRequired,
};

export default SchedulingGroupManagerHeader;
