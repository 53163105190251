import { DropdownMenu } from 'reactstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BaseDropdownMenu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onMenuMount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onMenuMount } = this.props;
    onMenuMount();
  }

  render() {
    const { children } = this.props;
    return (
      <DropdownMenu className="base-dropdown-menu" left="true">
        {children}
      </DropdownMenu>
    );
  }
}

export default BaseDropdownMenu;
