import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import AdmiralCampaignsList from './AdmiralCampaignsList';
import SchedulingGroupManagerInput from './SchedulingGroupManagerInput';
import SchedulingGroupManagerCreate from './SchedulingGroupManagerCreate';
import SchedulingGroupManagerHeader from './SchedulingGroupManagerHeader';
import SchedulingGroupManagerFooter from './SchedulingGroupManagerFooter';
import SchedulingGroupNameErrorConnector from './SchedulingGroupNameError/SchedulingGroupNameErrorConnector';
import SchedulingGroupItemConnector from './SchedulingGroupItem/SchedulingGroupItemConnector';
import Dropdown from '../../Components/Dropdown/BaseDropdown';
import CustomDropdownItem from '../../Components/Dropdown/CustomDropdownItem';
import schedulingGroupManagerTableStyles from './schedulingGroupManagerTableStyles';

const SchedulingGroupManagerTable = props => {
  const {
    selectedDivision,
    activeSubcategory,
    sortedSchedulingGroups,
    activeSchedulingGroup,
    numberOfAdmiralCampaigns,
    activeSchedulingGroupAdmiralCampaigns,
    newSchedulingGroupInput,
    schedulingGroupsForDropdown,
    submitOnReturn,
    enableFooterButtons,
    cancelDraftSchedulingGroups,
    saveSchedulingGroups,
    clearErrorOnDelete,
    handleNewSchedulingGroupClick,
    handleAddDraftSchedulingGroup,
    handleAdmiralCampaignSelect,
    handleSetActiveSchedulingGroup,
    activeSchedulingGroupLoaded,
  } = props;

  const dropdownActive =
    isEmpty(schedulingGroupsForDropdown) === true
      ? 'scheduling-group-manager-header-dropdown-disabled'
      : '';

  const styles = schedulingGroupManagerTableStyles();

  return (
    <div className="scheduling-group-manager-table-display">
      <Fragment>
        <div className="scheduling-group-manager-table">
          <SchedulingGroupManagerHeader
            selectedDivision={selectedDivision}
            activeSubcategory={activeSubcategory}
            campaignCount={numberOfAdmiralCampaigns}
          />

          <div className="scheduling-group-manager-column-left">
            <h3 className="scheduling-group-manager-column-left-header">
              Campaign Groups
            </h3>
            {map(sortedSchedulingGroups, group => (
              <SchedulingGroupItemConnector
                key={group.id}
                schedulingGroup={group}
                handleSetActiveSchedulingGroup={handleSetActiveSchedulingGroup}
              />
            ))}
            {newSchedulingGroupInput && (
              <div className="scheduling-group-item">
                <SchedulingGroupManagerInput
                  newSchedulingGroupInput={newSchedulingGroupInput}
                  submitOnReturn={submitOnReturn}
                  handleAddDraftSchedulingGroup={handleAddDraftSchedulingGroup}
                  clearErrorOnDelete={clearErrorOnDelete}
                />
              </div>
            )}
            <div>
              <SchedulingGroupNameErrorConnector />
              <SchedulingGroupManagerCreate
                handleNewSchedulingGroupClick={handleNewSchedulingGroupClick}
              />
            </div>
          </div>
          <div className="scheduling-group-manager-column-right">
            <h3 className="scheduling-group-manager-column-right-header">
              <div className="scheduling-group-manager-header-content">
                {activeSchedulingGroupLoaded && (
                  <span>{activeSchedulingGroup.name.toUpperCase()}</span>
                )}
              </div>
              <div
                className={`scheduling-group-manager-header-dropdown ${dropdownActive}`}
              >
                <Dropdown
                  dropdownHeader="Move to campaign group"
                  style={styles.dropdown}
                >
                  {map(schedulingGroupsForDropdown, dropdownItem => (
                    <CustomDropdownItem
                      key={dropdownItem.id}
                      id={dropdownItem.id}
                      name={dropdownItem.name}
                      handleClick={dropdownItem.handleClick}
                      itemClassName={dropdownItem.itemClassName}
                    />
                  ))}
                </Dropdown>
              </div>
            </h3>
            <AdmiralCampaignsList
              admiralCampaigns={activeSchedulingGroupAdmiralCampaigns}
              handleAdmiralCampaignSelect={handleAdmiralCampaignSelect}
            />
          </div>
        </div>
        <SchedulingGroupManagerFooter
          enableFooterButtons={enableFooterButtons}
          cancelDraftSchedulingGroups={cancelDraftSchedulingGroups}
          saveSchedulingGroups={saveSchedulingGroups}
        />
      </Fragment>
    </div>
  );
};

SchedulingGroupManagerTable.propTypes = {
  selectedDivision: PropTypes.string.isRequired,
  activeSubcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  sortedSchedulingGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      numberOfCampaigns: PropTypes.number,
    }),
  ).isRequired,
  activeSchedulingGroup: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  handleSetActiveSchedulingGroup: PropTypes.func.isRequired,
  numberOfAdmiralCampaigns: PropTypes.number.isRequired,
  activeSchedulingGroupAdmiralCampaigns: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  schedulingGroupsForDropdown: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      handleClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  activeSchedulingGroupLoaded: PropTypes.bool.isRequired,
  handleAddDraftSchedulingGroup: PropTypes.func.isRequired,
  newSchedulingGroupInput: PropTypes.bool.isRequired,
  handleNewSchedulingGroupClick: PropTypes.func.isRequired,
  submitOnReturn: PropTypes.func.isRequired,
  enableFooterButtons: PropTypes.bool.isRequired,
  cancelDraftSchedulingGroups: PropTypes.func.isRequired,
  saveSchedulingGroups: PropTypes.func.isRequired,
  clearErrorOnDelete: PropTypes.func.isRequired,
  handleAdmiralCampaignSelect: PropTypes.func.isRequired,
};

export default SchedulingGroupManagerTable;
