import React from 'react';
import PropTypes from 'prop-types';
import { indexOf, sortBy } from 'lodash';
import { Input } from 'reactstrap';
import { WEEKDAYS } from 'Shared/Constants/Constants';
import { LIMIT_FORMATS } from './LimitsConstants';

export const sortByDayOfWeek = limits =>
  sortBy(limits, limit => indexOf(WEEKDAYS, limit.dayOfWeek));

const LimitRow = ({ handleChange, limits, type }) => {
  const { infinity, numbersOrInfinity } = LIMIT_FORMATS;
  const sortedLimits = sortByDayOfWeek(limits);
  const limitRow = sortedLimits.map((limit, index) => {
    const volume = limit.unlimited ? infinity : limit.limit;
    const key = limit.id ? limit.id : `${limit.dayOfWeek}-${index}`;
    return (
      <div key={key} className="limit-field">
        <Input
          type="text"
          name={`limit-${limit.id}`}
          value={volume}
          onChange={e => handleChange(e.target.value, limit)}
          pattern={`${numbersOrInfinity}`}
        />
      </div>
    );
  });
  return (
    <div className="limit-row-container">
      <div className="limit-row-container--limits">{limitRow}</div>
      <div className="limit-row-container--action-type">{type}</div>
    </div>
  );
};

LimitRow.propTypes = {
  limits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      unlimited: PropTypes.bool.isRequired,
      dayOfWeek: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default LimitRow;
