import React from 'react';
import PropTypes from 'prop-types';
import PlatformCampaignConnector from 'Features/PlatformCampaigns/PlatformCampaignConnector';

const PlatformCampaigns = ({ match }) => {
  const { schedulingGroupId, subcategoryId } = match.params;

  return (
    <div className="app">
      <PlatformCampaignConnector
        schedulingGroupId={parseInt(schedulingGroupId, 0)}
        subcategoryId={parseInt(subcategoryId, 0)}
      />
    </div>
  );
};

export default PlatformCampaigns;

PlatformCampaigns.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      schedulingGroupId: PropTypes.string,
      subcategoryId: PropTypes.string,
    }),
  }).isRequired,
};
