import React, { Component } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import PlatformCampaignList from 'Features/PlatformCampaigns/PlatformCampaignList';
import formatPlatformName from 'Features/PlatformCampaigns/PlatformCampaignFormatter';

class PlatformCampaignContainer extends Component {
  componentDidMount() {
    const {
      onLoadPlatformCampaigns,
      schedulingGroupId,
      subcategoryId,
    } = this.props;

    onLoadPlatformCampaigns(schedulingGroupId, subcategoryId);
  }

  onScheduleToggle = id => {
    const { onUpdatePlatformCampaignStatus, platformCampaigns } = this.props;
    onUpdatePlatformCampaignStatus(!platformCampaigns[id].isScheduled, id);
  };

  formatPlatformNames = () => {
    const { platformCampaigns } = this.props;
    return map(platformCampaigns, platformCampaign => {
      const modifiedPlatformCampaign = platformCampaign;
      modifiedPlatformCampaign.platform = formatPlatformName(
        platformCampaign.platform,
      );
      return modifiedPlatformCampaign;
    });
  };

  render() {
    const {
      subcategoryName,
      schedulingGroupName,
      schedulingGroupId,
      loaded,
      onSetCampaignNameSearchTerm,
      campaignNameSearchTerm,
    } = this.props;

    const schedulingGroup = {
      name: schedulingGroupName,
      id: schedulingGroupId,
    };

    return (
      <PlatformCampaignList
        platformCampaigns={this.formatPlatformNames()}
        schedulingGroup={schedulingGroup}
        subcategoryName={subcategoryName}
        onScheduleToggle={this.onScheduleToggle}
        loaded={loaded}
        onSetCampaignNameSearchTerm={onSetCampaignNameSearchTerm}
        campaignNameSearchTerm={campaignNameSearchTerm}
      />
    );
  }
}

PlatformCampaignContainer.defaultProps = {
  platformCampaigns: {},
  subcategoryName: '',
  schedulingGroupName: '',
};

PlatformCampaignContainer.propTypes = {
  schedulingGroupId: PropTypes.number.isRequired,
  subcategoryId: PropTypes.number.isRequired,
  onLoadPlatformCampaigns: PropTypes.func.isRequired,
  platformCampaigns: PropTypes.shape({
    isScheduled: PropTypes.bool,
    platform: PropTypes.string,
    name: PropTypes.string,
    admiralCampaignId: PropTypes.string,
    manager: PropTypes.string,
    mercuryStatus: PropTypes.string,
    platformStatus: PropTypes.string,
    schedulingGroupId: PropTypes.number,
    identifier: PropTypes.string,
  }),
  schedulingGroupName: PropTypes.string,
  subcategoryName: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  onUpdatePlatformCampaignStatus: PropTypes.func.isRequired,
  onSetCampaignNameSearchTerm: PropTypes.func.isRequired,
  campaignNameSearchTerm: PropTypes.string.isRequired,
};

export default PlatformCampaignContainer;
