import React from 'react';
import PropTypes from 'prop-types';
import LimitRow from './LimitRow';

const Limits = ({ onChangeFieldValues, conversions, soldLeads }) => (
  <div className="limits-container">
    <div className="limits">
      <LimitRow
        key="sold-leads"
        type="Sold Leads"
        limits={soldLeads}
        handleChange={onChangeFieldValues}
      />
    </div>
    <div className="limits">
      <LimitRow
        key="conversions"
        type="Conversions"
        limits={conversions}
        handleChange={onChangeFieldValues}
      />
    </div>
  </div>
);

Limits.propTypes = {
  conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
  soldLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeFieldValues: PropTypes.func.isRequired,
};

export default Limits;
