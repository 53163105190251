import { put, call, takeEvery } from 'redux-saga/effects';
import {
  getScheduleSuccess,
  getScheduleFailure,
  updateScheduleSuccess,
  updateScheduleFailure,
} from 'Features/Schedules/ScheduleActionCreators';
import { SCHEDULE_ACTIONS } from 'Features/Schedules/ScheduleActions';
import ScheduleApi from '../../Api/GraphQL/ScheduleApi';

function* fetchSchedule(action) {
  const { schedulingGroupId } = action.payload;
  if (!schedulingGroupId) {
    yield;
  } else {
    try {
      const schedule = yield call(ScheduleApi.get, { schedulingGroupId });
      yield put(getScheduleSuccess(schedule, schedulingGroupId));
    } catch (e) {
      yield put(getScheduleFailure(e.message));
    }
  }
}

export function* onFetchSchedule() {
  yield takeEvery(SCHEDULE_ACTIONS.openScheduleModal, fetchSchedule);
}

function* saveSchedule(action) {
  const { schedule } = action.payload;
  try {
    const scheduleResponse = yield call(ScheduleApi.save, {
      schedulingTimes: schedule,
    });
    yield put(updateScheduleSuccess(scheduleResponse));
  } catch (e) {
    yield put(updateScheduleFailure(e.message));
  }
}

export function* onUpdateSchedule() {
  yield takeEvery(SCHEDULE_ACTIONS.saveSchedule, saveSchedule);
}
