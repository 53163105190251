import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { map, flatten } from 'lodash';
import { STATUS_FILTERS } from 'Shared/Constants/Constants';
import { getSelectedSubcategoryDivision } from 'Shared/Selectors/SubcategoryDivisionSelector';
import getSelectedStatusFilters from 'Features/FilterBar/store/filterBarSelector';
import FILTER_BAR_ACTIONS from 'Features/FilterBar/store/filterBarActions';
import {
  clearExpandedSubcategories,
  getSubcategoriesFailure,
  getSubcategoriesSuccess,
} from 'Features/SubcategoryList/subcategoryListActionCreators';
import { getSelectedManagerFilters } from 'Shared/Selectors/ManagerSelector';
import {
  getSubcategoriesSearchTerm,
  getCurrentPage,
} from 'Shared/Selectors/SubcategorySelector';
import SHARED_ACTIONS from 'Shared/SharedActions';
import getSubcategories from '../../Api/GraphQL/SubcategoryApi';
import { loadError } from '../../Store/Actions/ActionCreators';
import { ACTIONS } from '../../Store/Actions/Actions';
import { SUBCATEGORYLIST_ACTIONS } from './subcategoryListActions';

function* fetchSubcategories(action) {
  const statusFilters = yield select(getSelectedStatusFilters);
  const statuses = flatten(
    map(statusFilters, status => STATUS_FILTERS[status]),
  );
  const managers = yield select(getSelectedManagerFilters);
  const division = yield select(getSelectedSubcategoryDivision);
  const searchTerm = yield select(getSubcategoriesSearchTerm);
  const limit = 10;
  let page = yield select(getCurrentPage);
  if (page > 0) {
    page -= 1;
  }

  let subcategoryFilters = { division, managers, statuses };
  if (searchTerm) {
    subcategoryFilters = { ...subcategoryFilters, text: searchTerm };
  }
  try {
    const response = yield call(getSubcategories, {
      subcategoryFilters,
      paginate: { page, limit },
      statusFilters: { division },
    });

    const { edges, pageCount } = response.data.subcategories;
    const subcategories = map(edges, ({ node }) => node);
    yield put(getSubcategoriesSuccess({ subcategories, pageCount }));
    if (action.type !== 'LOAD_SUBCATEGORIES_ON_INTERVAL') {
      yield put(clearExpandedSubcategories());
    }
  } catch (error) {
    yield put(loadError());
    yield put(getSubcategoriesFailure(error.message));
  }
}

export default function* onFetchSubcategories() {
  yield takeEvery(
    SUBCATEGORYLIST_ACTIONS.loadSubcategories,
    fetchSubcategories,
  );
  yield takeEvery(
    SUBCATEGORYLIST_ACTIONS.loadSubcategoriesOnInterval,
    fetchSubcategories,
  );
  yield takeEvery(FILTER_BAR_ACTIONS.selectStatusesFilter, fetchSubcategories);
  yield takeEvery(
    FILTER_BAR_ACTIONS.deselectStatusesFilter,
    fetchSubcategories,
  );
  yield takeEvery(ACTIONS.selectManagerFilter, fetchSubcategories);
  yield takeEvery(ACTIONS.deselectManagerFilter, fetchSubcategories);
  yield takeEvery(ACTIONS.setSubcategoryDivisionFilter, fetchSubcategories);
  yield takeEvery(ACTIONS.clearFilters, fetchSubcategories);
  yield takeLatest(
    SHARED_ACTIONS.setSubcategoriesSearchTerm,
    fetchSubcategories,
  );
  yield takeEvery(SUBCATEGORYLIST_ACTIONS.selectPage, fetchSubcategories);
}
