import TimezonePicker from 'Components/TimezonePicker/TimezonePicker';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { first, map, values } from 'lodash';
import Loader from 'Components/Loader/Loader';
import BaseModal from 'Components/BaseModal/BaseModal';
import Accordion from 'Components/Accordion/Accordion';
import WeekdayHeader from 'Components/Weekdays/Weekdays';
import AccordionTitle from 'Components/Accordion/AccordionTitle';
import CrossChannelLimitsExclusion from 'Features/Limits/CrossChannelLimitsExclusion';
import Limits from './Limits';
import { SCHEDULING_GROUPS } from './LimitsConstants';

const LimitsModal = props => {
  const {
    subcategoryLimits,
    schedulingGroupLimits,
    subcategory,
    onOpenLimitsModal,
    onResetLimits,
    onSetIgnoreSubcategoryLimits,
    onSaveLimits,
    onChangeFieldValues,
    limitsLoaded,
    onCloseLimitsModal,
  } = props;

  let limitsAccordions = [];

  if (limitsLoaded) {
    const subcategoryGroup = first(values(subcategoryLimits));
    const subcategoryAccordionTitle = (
      <AccordionTitle
        title={subcategory.name}
        group=" - Cross Channel Limits"
      />
    );
    const subcategoryLimitsAccordion = (
      <Accordion
        title={subcategoryAccordionTitle}
        key={subcategoryGroup.id}
        color="blue-dark"
        accordionState
      >
        <Limits
          conversions={subcategoryGroup.conversions}
          soldLeads={subcategoryGroup.soldLeads}
          onChangeFieldValues={onChangeFieldValues}
        />
      </Accordion>
    );

    const schedulingGroupLimitsAccordions = map(
      schedulingGroupLimits,
      schedulingGroup => {
        const groupNameAndDivision = ` - ${schedulingGroup.division} - ${schedulingGroup.name}`;

        const schedulingGroupAccordionTitle = (
          <AccordionTitle
            title="Scheduling Group"
            group={groupNameAndDivision}
          />
        );

        const accordionOpen =
          schedulingGroup.name === SCHEDULING_GROUPS.defaultGroup;

        return (
          <Accordion
            title={schedulingGroupAccordionTitle}
            key={schedulingGroup.id}
            color="blue-light"
            accordionState={accordionOpen}
          >
            <Limits
              conversions={schedulingGroup.conversions}
              soldLeads={schedulingGroup.soldLeads}
              onChangeFieldValues={onChangeFieldValues}
            />
            <CrossChannelLimitsExclusion
              checked={schedulingGroup.ignoreSubcategoryLimits}
              setIgnoreSubcategoryLimits={() =>
                onSetIgnoreSubcategoryLimits({
                  schedulingGroupId: schedulingGroup.id,
                  ignoreSubcategoryLimits: !schedulingGroup.ignoreSubcategoryLimits,
                })
              }
            />
          </Accordion>
        );
      },
    );

    limitsAccordions = [subcategoryLimitsAccordion].concat(
      schedulingGroupLimitsAccordions,
    );
  }

  return (
    <Fragment>
      <BaseModal
        buttonText="Limits"
        getModalData={onOpenLimitsModal}
        resetCallback={onResetLimits}
        saveCallback={onSaveLimits}
        title="Limits Settings"
        modalClose={onCloseLimitsModal}
      >
        <h3>{subcategory.name}</h3>
        <TimezonePicker subcategoryId={subcategory.id} />
        <div className="limit-container">
          <WeekdayHeader />
          {limitsLoaded ? limitsAccordions : <Loader name="limits" />}
        </div>
      </BaseModal>
    </Fragment>
  );
};

LimitsModal.propTypes = {
  subcategoryLimits: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
      soldLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  ).isRequired,
  schedulingGroupLimits: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      division: PropTypes.string.isRequired,
      ignoreSubcategoryLimits: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      conversions: PropTypes.arrayOf(PropTypes.object).isRequired,
      soldLeads: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  ).isRequired,
  subcategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onOpenLimitsModal: PropTypes.func.isRequired,
  onCloseLimitsModal: PropTypes.func.isRequired,
  onResetLimits: PropTypes.func.isRequired,
  onSetIgnoreSubcategoryLimits: PropTypes.func.isRequired,
  onSaveLimits: PropTypes.func.isRequired,
  onChangeFieldValues: PropTypes.func.isRequired,
  limitsLoaded: PropTypes.bool.isRequired,
};

export default LimitsModal;
