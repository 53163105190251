import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar/ProgressBar';

const CampaignProgress = ({ children, color, metrics }) => (
  <Fragment>
    <span className="campaign-progress-children">{children}</span>
    <ProgressBar
      progress={metrics.progress}
      limit={metrics.limit}
      unlimited={metrics.unlimited}
      color={color}
    />
  </Fragment>
);

CampaignProgress.defaultProps = {
  children: '',
  color: 'blue',
};

CampaignProgress.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  metrics: PropTypes.oneOfType([
    PropTypes.shape({
      progress: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      unlimited: PropTypes.bool.isRequired,
    }),
  ]).isRequired,
};

export default CampaignProgress;
