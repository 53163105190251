import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropdownToggle, DropdownMenu } from 'reactstrap';

const DropdownComponent = ({ dropdownHeader, icon, children }) => (
  <Fragment>
    <DropdownToggle>
      <div className="dropdown-selection">
        {!!dropdownHeader && (
          <span className="dropdown-selection-button--text">
            {dropdownHeader}
          </span>
        )}
        <span className="dropdown-selection-button--icon">{icon}</span>
      </div>
    </DropdownToggle>
    <DropdownMenu isopen="true" left="true">
      {children}
    </DropdownMenu>
  </Fragment>
);

DropdownComponent.propTypes = {
  dropdownHeader: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
};

export default DropdownComponent;
