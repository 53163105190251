import gql from 'graphql-tag';
import sendQuery from './Request';

export function getTimezoneForSubcategory(payload) {
  const query = gql`
    query GetCurrentTimezone($id: Int!) {
      getSubcategory(id: $id) {
        timezone {
          name
          text
        }
      }
    }
  `;
  return sendQuery(query, payload);
}

export function getTimezonesList(payload) {
  const query = gql`
    query GetTimezones($filter: String) {
      timezones: getTimezones(filter: $filter) {
        name
        text
      }
    }
  `;
  return sendQuery(query, payload);
}

export function updateClientTimezone(payload) {
  const mutation = gql`
    mutation updateTimezoneBySubcategoryId($id: Int!, $timezone: String!) {
      updateTimezoneBySubcategoryId(id: $id, timezone: $timezone) {
        id
      }
    }
  `;
  return sendQuery(mutation, payload);
}
