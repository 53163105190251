export const ACTIONS = {
  selectManagerFilter: 'SELECT_MANAGER_FILTER',
  deselectManagerFilter: 'DESELECT_MANAGER_FILTER',
  loadManagers: 'LOAD_MANAGERS',
  loadSubcategoryDivisions: 'LOAD_SUBCATEGORY_DIVISIONS',
  loadDivisions: 'LOAD_DIVISIONS',
  setSubcategoryDivisionFilter: 'SET_SUBCATEGORY_DIVISION_FILTER',
  setDivisionFilter: 'SET_DIVISION_FILTER',
  clearFilters: 'CLEAR_FILTERS',
  updateTimezone: 'UPDATE_TIMEZONE',
  getTimezones: 'GET_TIMEZONES',
  getTimezone: 'GET_TIMEZONE',
  changeTimezone: 'TIMEZONE_CHANGED',
  loadAdmiralCampaigns: 'GET_ADMIRAL_CAMPAIGNS',
  loadError: 'LOAD_ERROR',
};

export const REQUEST_ACTIONS = {
  getManagersSuccess: 'GET_MANAGERS_SUCCESS',
  getManagersFailure: 'GET_MANAGERS_FAILURE',
  getSubcategoryDivisionsSuccess: 'GET_SUBCATEGORY_DIVISIONS_SUCCESS',
  getSubcategoryDivisionsFailure: 'GET_SUBCATEGORY_DIVISIONS_FAILURE',
  getDivisionsSuccess: 'GET_DIVISIONS_SUCCESS',
  getDivisionsFailure: 'GET_DIVISIONS_FAILURE',
  getTimezonesSuccess: 'GET_TIMEZONES_SUCCEEDED',
  getTimezonesFailure: 'GET_TIMEZONES_FAILED',
  getTimezoneSuccess: 'GET_TIMEZONE_SUCCEEDED',
  getTimezoneFailure: 'GET_TIMEZONE_FAILED',
  getAdmiralCampaignsSuccess: 'GET_ADMIRAL_CAMPAIGNS_SUCCEEDED',
  getAdmiralCampaignsFailure: 'GET_ADMIRAL_CAMPAIGNS_FAILURE',
};
