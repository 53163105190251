import gql from 'graphql-tag';
import sendQuery from './Request';

/*
 * There should be one static method per CRUD operation as necessary.
 */
export function get() {
  const query = gql`
    query {
      managers: getManagers {
        fullName
        email
      }
    }
  `;
  return sendQuery(query);
}

export default { get };
