import { put, call, takeLatest, select } from 'redux-saga/effects';
import { map } from 'lodash';
import getSubcategories from '../../../Api/GraphQL/SubcategoryNamesApi';
import {
  getSubcategoriesForDropdownSuccess,
  getSubcategoriesForDropdownFailure,
} from './subcategoryDivisionPickerActionCreators';
import { SUBCATEGORY_DROPDOWN_ACTIONS } from './subcategoryDivisionPickerActions';
import { getSubcategoryDropdownSearchTerm } from './subcategoryDivisionPickerSelector';

function* filterSubcategories() {
  let subcategoryFilters = { allSubcategories: false };
  const searchTerm = yield select(getSubcategoryDropdownSearchTerm);
  if (searchTerm) {
    subcategoryFilters = { ...subcategoryFilters, text: searchTerm };
  }
  try {
    const response = yield call(getSubcategories, { subcategoryFilters });
    const subcategories = map(
      response.data.subcategories.edges,
      ({ node }) => node,
    );
    yield put(getSubcategoriesForDropdownSuccess({ subcategories }));
  } catch (e) {
    yield put(getSubcategoriesForDropdownFailure(e.message));
  }
}

function* onFilterSubcategories() {
  yield takeLatest(
    SUBCATEGORY_DROPDOWN_ACTIONS.filterSubcategoriesForDropdown,
    filterSubcategories,
  );
}

export default onFilterSubcategories;
