import { SUBCATEGORY_DROPDOWN_ACTIONS } from 'Features/SubcategoryDivisionPicker/store/subcategoryDivisionPickerActions';
import {
  GROUP_MANAGER_ACTIONS,
  GROUP_MANAGER_REQUEST_ACTIONS,
} from 'Features/SchedulingGroupManager/SchedulingGroupManagerActions';
import { PLATFORM_CAMPAIGN_REQUEST_ACTIONS } from 'Features/PlatformCampaigns/PlatformCampaignActions';
import { ACTIONS } from '../../Store/Actions/Actions';

export const initialState = () => ({
  data: {},
  enableFooterButtons: false,
  schedulingGroupsUpdated: false,
  loaded: false,
});

const activeSubcategoryReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SUBCATEGORY_DROPDOWN_ACTIONS.setActiveSubcategory:
    case PLATFORM_CAMPAIGN_REQUEST_ACTIONS.getPlatformCampaignsSuccess: {
      const { id, name } = action.payload.subcategory;
      return {
        ...state,
        data: { id, name },
        loaded: true,
      };
    }
    case ACTIONS.resetActiveSubcategoryState: {
      return initialState();
    }
    case GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsSuccess:
      return {
        ...state,
        enableFooterButtons: false,
        schedulingGroupsUpdated: false,
      };
    case GROUP_MANAGER_ACTIONS.createNewSchedulingGroup:
    case GROUP_MANAGER_ACTIONS.deleteSchedulingGroupSuccess:
    case GROUP_MANAGER_REQUEST_ACTIONS.saveSchedulingGroupsFailure:
      return {
        ...state,
        enableFooterButtons: true,
      };
    case GROUP_MANAGER_ACTIONS.renameSchedulingGroup:
    case GROUP_MANAGER_ACTIONS.moveToSchedulingGroup:
      return {
        ...state,
        enableFooterButtons: true,
        schedulingGroupsUpdated: true,
      };
    case GROUP_MANAGER_ACTIONS.moveToNewSchedulingGroup:
      return {
        ...state,
        enableFooterButtons: true,
        schedulingGroupsUpdated: false,
      };
    default:
      return state;
  }
};

export default activeSubcategoryReducer;
