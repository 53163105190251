import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import ModalHeader from 'Components/ModalHeader/ModalHeader';

const AlertDialog = props => {
  const {
    alertText,
    alertCancel,
    alertAccept,
    alertAcceptText,
    alertCancelText,
    isOpen,
    multipleOptions,
  } = props;

  const headerTitle = 'Woah There!';

  return (
    <Modal isOpen={isOpen} className="modal-alert">
      <ModalHeader title={headerTitle} modalToggle={alertCancel} />
      <div className="modal-alert--box">
        <p>{alertText}</p>
      </div>
      <footer className="modal-footer">
        <button
          type="button"
          onClick={alertAccept}
          className="modal-alert--action action-primary"
        >
          {alertAcceptText}
        </button>
        {multipleOptions && (
          <button
            type="button"
            onClick={alertCancel}
            className="modal-alert--action action-secondary"
          >
            {alertCancelText}
          </button>
        )}
      </footer>
    </Modal>
  );
};

AlertDialog.defaultProps = {
  alertAcceptText: 'Yes',
  alertCancelText: 'No',
  alertAccept: () => {},
  alertCancel: () => {},
  multipleOptions: true,
};

AlertDialog.propTypes = {
  alertText: PropTypes.string.isRequired,
  alertAcceptText: PropTypes.string,
  alertCancelText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  alertCancel: PropTypes.func,
  alertAccept: PropTypes.func,
  multipleOptions: PropTypes.bool,
};

export default AlertDialog;
