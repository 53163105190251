import React from 'react';
import PropTypes from 'prop-types';

const SchedulingGroupManagerCreate = props => {
  const { handleNewSchedulingGroupClick } = props;

  return (
    <div className="scheduling-group-manager-create-group">
      <button
        type="button"
        className="action-link"
        onClick={handleNewSchedulingGroupClick}
      >
        + Create new group
      </button>
    </div>
  );
};

export default SchedulingGroupManagerCreate;

SchedulingGroupManagerCreate.propTypes = {
  handleNewSchedulingGroupClick: PropTypes.func.isRequired,
};
