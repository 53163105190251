import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';

const CustomDropdownItem = ({ id, name, handleClick, itemClassName }) => (
  <div className={`dropdown-item-data-${itemClassName}`}>
    <DropdownItem onClick={() => handleClick(id)}>{name}</DropdownItem>
  </div>
);

CustomDropdownItem.defaultProps = {
  itemClassName: '',
};

CustomDropdownItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  itemClassName: PropTypes.string,
};

export default CustomDropdownItem;
