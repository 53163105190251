import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from 'Features/Limits/Utilities';
import ScheduleModal from 'Features/Schedules/ScheduleModal';
import { change24HToLongStatus } from 'Shared/Formatters/Formatters';
import getTimezoneSelector from '../../Store/Selectors/TimezoneSelector';

export const isInvalidTime = times =>
  !!times.filter(
    time =>
      parseInt(time.start.replace(':', ''), 10) >=
      parseInt(time.stop.replace(':', ''), 10),
  ).length;

export class ScheduleModalContainer extends Component {
  static propTypes = {
    subcatName: PropTypes.string.isRequired,
    subcategoryId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    division: PropTypes.string.isRequired,
    schedulesLoaded: PropTypes.bool.isRequired,
    schedules: PropTypes.shape({
      data: PropTypes.arrayOf,
      times_by_id: PropTypes.arrayOf,
      type: PropTypes.string,
    }).isRequired,
    timezone: PropTypes.shape({
      data: PropTypes.shape,
      loaded: PropTypes.bool,
    }).isRequired,
    onOpenScheduleModal: PropTypes.func.isRequired,
    onCloseScheduleModal: PropTypes.func.isRequired,
    onChangeScheduleTime: PropTypes.func.isRequired,
    onSaveSchedule: PropTypes.func.isRequired,
    onResetSchedule: PropTypes.func.isRequired,
  };

  state = {
    invalidFieldValues: false,
  };

  setValidation = validationState => {
    this.setState({ invalidFieldValues: validationState });
  };

  changeFieldValues = (e, itemKey, fieldType) => {
    const fieldValue = change24HToLongStatus(e.target.value);
    const { schedules, onChangeScheduleTime } = this.props;
    // @Todo: Ensure we are not mutating the object
    // We need to check why this breaks if we try const data = ...schedules.data
    const { data } = schedules;
    data[itemKey] = { ...data[itemKey], [fieldType]: fieldValue };
    onChangeScheduleTime(data[itemKey]);
    this.setValidation(isInvalidTime(data));
  };

  render() {
    const {
      id,
      name,
      division,
      subcatName,
      subcategoryId,
      schedules,
      onOpenScheduleModal,
      onCloseScheduleModal,
      onChangeScheduleTime,
      onSaveSchedule,
      schedulesLoaded,
      onResetSchedule,
    } = this.props;

    const { invalidFieldValues } = this.state;
    const renderFooterError = invalidFieldValues && <ErrorMessage />;

    return (
      <ScheduleModal
        id={id}
        name={name}
        subcatName={subcatName}
        subcategoryId={subcategoryId}
        division={division}
        changeFieldValues={this.changeFieldValues}
        setValidation={this.setValidation}
        renderFooterError={renderFooterError}
        onOpenScheduleModal={() => {
          onOpenScheduleModal(id);
        }}
        onCloseScheduleModal={onCloseScheduleModal}
        onChangeScheduleTime={onChangeScheduleTime}
        onResetSchedule={onResetSchedule}
        onSaveSchedule={onSaveSchedule}
        schedulesLoaded={schedulesLoaded}
        schedules={schedules}
      />
    );
  }
}

const mapStateToProps = state => ({
  timezone: getTimezoneSelector(state),
});

export default connect(mapStateToProps)(ScheduleModalContainer);
