import PropTypes from 'prop-types';

const metricsProptypes = PropTypes.shape({
  conversions: PropTypes.shape({
    progress: PropTypes.number,
    limit: PropTypes.number,
    unlimited: PropTypes.bool,
  }),
  soldLeads: PropTypes.shape({
    progress: PropTypes.number,
    limit: PropTypes.number,
    unlimited: PropTypes.bool,
  }),
});

export default metricsProptypes;
