import { GROUP_MANAGER_REQUEST_ACTIONS } from './SchedulingGroupManagerActions';

export const initialTimestampState = () => ({
  type: 'timestamp',
  timestamp: null,
  loaded: false,
});

const admiralCampaignTimestampReducer = (
  state = initialTimestampState(),
  action,
) => {
  switch (action.type) {
    case GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsSuccess: {
      const newTimestamp = Math.round(+new Date() / 1000);
      return {
        ...state,
        timestamp: newTimestamp,
        loaded: true,
      };
    }
    case GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsFailure: {
      return initialTimestampState();
    }
    default: {
      return state;
    }
  }
};

export default admiralCampaignTimestampReducer;
