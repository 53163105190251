import { flatten, map, cloneDeep } from 'lodash';
import {
  normalizeData,
  addSchedulingGroupId,
} from 'Shared/Formatters/Formatters';
import {
  GROUP_MANAGER_ACTIONS,
  GROUP_MANAGER_REQUEST_ACTIONS,
} from './SchedulingGroupManagerActions';

export const initialCampaignState = () => ({
  admiralCampaigns: {},
  immutableAdmiralCampaigns: {},
  type: 'admiral campaigns',
});

const processAdmiralCampaigns = schedulingGroups =>
  flatten(
    map(schedulingGroups, (campaigns, schedulingGroupId) =>
      addSchedulingGroupId(campaigns, schedulingGroupId),
    ),
  );

const admiralCampaignReducer = (state = initialCampaignState(), action) => {
  switch (action.type) {
    case GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsSuccess: {
      const schedulingGroups = action.payload;
      const campaigns = processAdmiralCampaigns(schedulingGroups);
      const admiralCampaigns = normalizeData(campaigns);
      return {
        ...state,
        admiralCampaigns,
        immutableAdmiralCampaigns: cloneDeep(admiralCampaigns),
      };
    }
    case GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsFailure: {
      const { message } = action.payload;
      return { ...state, message };
    }
    case GROUP_MANAGER_ACTIONS.moveToSchedulingGroup:
    case GROUP_MANAGER_ACTIONS.moveToNewSchedulingGroup: {
      const { selectedAdmiralCampaigns, schedulingGroupId } = action.payload;

      const campaigns = map(selectedAdmiralCampaigns, campaign => ({
        ...campaign,
        schedulingGroupId,
      }));
      const admiralCampaigns = normalizeData(campaigns);

      return {
        ...state,
        admiralCampaigns: { ...state.admiralCampaigns, ...admiralCampaigns },
      };
    }
    default: {
      return state;
    }
  }
};

export default admiralCampaignReducer;
