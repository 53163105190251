import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getAppName from '../../Store/Selectors/appConfigSelector';

export const Navbar = ({ appName }) => (
  <nav id="navbar">
    <div className="logo">
      <img src="/img/mvf-logo.png" alt="MVF Logo" title="MVF" />
      <NavLink to="/" title={appName}>
        {appName}
      </NavLink>
    </div>
  </nav>
);

Navbar.propTypes = {
  appName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  appName: getAppName(state),
});

export default connect(mapStateToProps)(Navbar);
