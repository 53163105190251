import {
  GROUP_MANAGER_ACTIONS,
  GROUP_MANAGER_REQUEST_ACTIONS,
} from './SchedulingGroupManagerActions';

export const cancelNewSchedulingGroups = payload => ({
  type: GROUP_MANAGER_ACTIONS.cancelNewSchedulingGroups,
  payload,
});

export const loadSchedulingGroupsForGroupManager = () => ({
  type: GROUP_MANAGER_ACTIONS.loadSchedulingGroupsForGroupManager,
});

export const setActiveSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.setActiveSchedulingGroup,
  payload,
});

export const loadAdmiralCampaigns = payload => ({
  type: GROUP_MANAGER_ACTIONS.loadAdmiralCampaigns,
  payload,
});

export const createNewSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.createNewSchedulingGroup,
  payload,
});

export const saveSchedulingGroups = () => ({
  type: GROUP_MANAGER_ACTIONS.saveSchedulingGroups,
});

export const selectAdmiralCampaign = payload => ({
  type: GROUP_MANAGER_ACTIONS.selectAdmiralCampaign,
  payload,
});

export const deselectAdmiralCampaign = payload => ({
  type: GROUP_MANAGER_ACTIONS.deselectAdmiralCampaign,
  payload,
});

export const resetSelectedAdmiralCampaigns = () => ({
  type: GROUP_MANAGER_ACTIONS.resetSelectedAdmiralCampaigns,
});

export const moveToSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.moveToSchedulingGroup,
  payload,
});

export const deleteSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.deleteSchedulingGroup,
  payload,
});

export const deleteSchedulingGroupSuccess = payload => ({
  type: GROUP_MANAGER_ACTIONS.deleteSchedulingGroupSuccess,
  payload,
});

export const moveToNewSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.moveToNewSchedulingGroup,
  payload,
});

export const editSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.editSchedulingGroup,
  payload,
});

export const renameSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.renameSchedulingGroup,
  payload,
});

export const cancelRenameSchedulingGroup = payload => ({
  type: GROUP_MANAGER_ACTIONS.cancelRenameSchedulingGroup,
  payload,
});

// Request Actions
export const getSchedulingGroupsForGroupManagerSuccess = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.getSchedulingGroupsForGroupManagerSuccess,
  payload,
});

export const getSchedulingGroupsForGroupManagerFailure = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.getSchedulingGroupsForGroupManagerFailure,
  payload,
});

export const getAdmiralCampaignsSuccess = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsSuccess,
  payload,
});

export const getAdmiralCampaignsFailure = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.getAdmiralCampaignsFailure,
  payload,
});

export const saveSchedulingGroupsSuccess = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.saveSchedulingGroupsSuccess,
  payload,
});

export const saveSchedulingGroupsFailure = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.saveSchedulingGroupsFailure,
  payload,
});

export const updateSchedulingGroupsSuccess = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.updateSchedulingGroupsSuccess,
  payload,
});

export const updateSchedulingGroupsFailure = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.updateSchedulingGroupsFailure,
  payload,
});

export const deleteSchedulingGroupsSuccess = () => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.deleteSchedulingGroupsSuccess,
});

export const deleteSchedulingGroupsFailure = payload => ({
  type: GROUP_MANAGER_REQUEST_ACTIONS.deleteSchedulingGroupsFailure,
  payload,
});
