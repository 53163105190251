export const SCHEDULING_GROUP_STATUSES = {
  RUNNING: 'RUNNING',
  PAUSED_LIMIT: 'PAUSED_LIMIT',
  PAUSED_SCHEDULE: 'PAUSED_SCHEDULE',
  OFF: 'OFF',
};

export const STATUS_FILTERS = {
  running: SCHEDULING_GROUP_STATUSES.RUNNING,
  paused: [
    SCHEDULING_GROUP_STATUSES.PAUSED_SCHEDULE,
    SCHEDULING_GROUP_STATUSES.PAUSED_LIMIT,
  ],
  off: SCHEDULING_GROUP_STATUSES.OFF,
};

export const PLATFORM_CAMPAIGN_STATUSES = {
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
};

export const WEEKDAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const SCHEDULE_STATUSES = {
  TWENTY_FOUR_HOUR: 'TWENTY_FOUR_HOUR',
  '24H': '24H',
  OFF: 'OFF',
  SCHEDULE: 'SCHEDULE',
};

export const KEY_CODES = {
  BACKSPACE: 8,
  DELETE: 46,
};

export const UNICODE_SYMBOLS = {
  OPTIONS_MENU_DOTS: '\u22EE',
};

export const DIVISIONS = {
  CDN: 'CDN',
  EPM: 'EPM',
  notApplicable: 'N/A',
};

export const TEXT_STRINGS = {
  EMPTY_SEARCH: 'Nothing found. Please clear the search filter and try again.',
  EMPTY_PLATFORM_CANPAIGNS: 'No Platform Campaigns in this Scheduling Group',
};

export const TOOLTIP_MESSAGE = {
  IGNORE_SUBCATEGORY_LIMITS: 'Excluded from the cross-channel limits',
};

export const METRIC_HEADER = {
  ALL: 'ALL',
};
