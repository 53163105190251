import { connect } from 'react-redux';
import { getActiveSchedulingGroupWithAdmiralCampaigns } from 'Shared/Selectors/SchedulingGroupSelector';
import { getSelectedDivision } from 'Shared/Selectors/DivisionSelector';
import SchedulingGroupItemContainer from './SchedulingGroupItemContainer';
import { getSortedSchedulingGroups } from '../SchedulingGroupManagerSelector';
import {
  editSchedulingGroup,
  renameSchedulingGroup,
  cancelRenameSchedulingGroup,
  deleteSchedulingGroup,
} from '../SchedulingGroupManagerActionCreators';
import {
  showSchedulingGroupNameError,
  hideSchedulingGroupNameError,
} from '../SchedulingGroupNameError/SchedulingGroupNameErrorActionCreators';
import {
  closeAlertDialog,
  confirmYes,
} from '../../../Components/AlertDialog/AlertDialogActionCreators';

const mapStateToProps = state => ({
  sortedSchedulingGroups: getSortedSchedulingGroups(state),
  activeSchedulingGroup: getActiveSchedulingGroupWithAdmiralCampaigns(state),
  selectedDivision: getSelectedDivision(state),
});

const mapDispatchToProps = dispatch => ({
  onDeleteSchedulingGroup: deletedGroupId => {
    dispatch(
      deleteSchedulingGroup({
        deletedGroupId,
        alertAccept: () => {
          dispatch(confirmYes());
        },
        alertCancel: () => {
          dispatch(closeAlertDialog());
        },
      }),
    );
  },
  onEditSchedulingGroup: schedulingGroupId => {
    dispatch(editSchedulingGroup(schedulingGroupId));
  },
  onRenameSchedulingGroup: (schedulingGroupId, name) => {
    dispatch(renameSchedulingGroup({ schedulingGroupId, name }));
  },
  onCancelRenameSchedulingGroup: schedulingGroupId => {
    dispatch(cancelRenameSchedulingGroup(schedulingGroupId));
  },
  onShowSchedulingGroupNameError: () => {
    dispatch(showSchedulingGroupNameError());
  },
  onHideSchedulingGroupNameError: () => {
    dispatch(hideSchedulingGroupNameError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SchedulingGroupItemContainer);
