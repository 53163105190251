import React from 'react';

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const currentDate = new Date();
const currentDay = weekdays[currentDate.getDay() - 1];

const WeekdayHeader = () => (
  <header className="weekdays-header">
    {weekdays.map(weekday => {
      const currentDayClassName =
        currentDay === weekday ? 'weekday current-day' : 'weekday';
      return (
        <div className={currentDayClassName} key={weekday}>
          {weekday}
        </div>
      );
    })}
  </header>
);

export default WeekdayHeader;
