import React from 'react';
import PropTypes from 'prop-types';
import SchedulingGroupIcon from '../../Shared/Svg/SchedulingGroupIcon';

const SchedulingGroupManagerInput = props => {
  const {
    handleAddDraftSchedulingGroup,
    submitOnReturn,
    newSchedulingGroupInput,
    clearErrorOnDelete,
  } = props;

  if (newSchedulingGroupInput) {
    return (
      <div
        className="scheduling-group-manager-scheduling-group scheduling-group-item-active"
        role="presentation"
      >
        <div className="scheduling-group-manager-scheduling-group-icon">
          <SchedulingGroupIcon />
        </div>
        <input
          autoFocus
          className="scheduling-group-manager-scheduling-group-field"
          type="text"
          onBlur={handleAddDraftSchedulingGroup}
          onKeyPress={submitOnReturn}
          onKeyDown={clearErrorOnDelete}
        />
      </div>
    );
  }
  return null;
};

SchedulingGroupManagerInput.propTypes = {
  handleAddDraftSchedulingGroup: PropTypes.func.isRequired,
  submitOnReturn: PropTypes.func.isRequired,
  newSchedulingGroupInput: PropTypes.bool.isRequired,
  clearErrorOnDelete: PropTypes.func.isRequired,
};

export default SchedulingGroupManagerInput;
