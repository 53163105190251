import React from 'react';
import PropTypes from 'prop-types';
import 'Features/Limits/CrossChannelLimitsExclusion.less';

const CrossChannelLimitsExclusion = ({
  checked,
  setIgnoreSubcategoryLimits,
}) => (
  <div className="crossChannelLimitsExclusion">
    <input
      checked={checked}
      className="crossChannelLimitsExclusion__input"
      type="checkbox"
      onChange={setIgnoreSubcategoryLimits}
    />
    <span className="crossChannelLimitsExclusion__label">
      Exclude from the Cross Channel Limits
    </span>
  </div>
);

CrossChannelLimitsExclusion.propTypes = {
  checked: PropTypes.bool.isRequired,
  setIgnoreSubcategoryLimits: PropTypes.func.isRequired,
};

export default CrossChannelLimitsExclusion;
