import { isDev, isStaging } from './environment';
import BASE_CONFIG from './config.base';
import DEV_CONFIG from './config.dev';
import STAGING_CONFIG from './config.staging';
import PRODUCTION_CONFIG from './config.production';

let envConfig = PRODUCTION_CONFIG;
if (isDev()) {
  envConfig = DEV_CONFIG;
} else if (isStaging()) {
  envConfig = STAGING_CONFIG;
}

export default { ...BASE_CONFIG, ...envConfig };
