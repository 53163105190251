import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { UNICODE_SYMBOLS, KEY_CODES } from 'Shared/Constants/Constants';
import SchedulingGroupIcon from 'Shared/Svg/SchedulingGroupIcon';
import DropdownContainer from 'Components/Dropdown/DropdownContainer';
import CustomDropdownItem from 'Components/Dropdown/CustomDropdownItem';
import SchedulingGroupManagerInput from '../SchedulingGroupManagerInput';
import { isDefaultSchedulingGroup } from '../SchedulingGroupManagerHelper';

class SchedulingGroupItemContainer extends Component {
  static propTypes = {
    schedulingGroup: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      numberOfCampaigns: PropTypes.number.isRequired,
      division: PropTypes.string,
      editing: PropTypes.bool,
    }).isRequired,
    selectedDivision: PropTypes.string.isRequired,
    handleSetActiveSchedulingGroup: PropTypes.func.isRequired,
    activeSchedulingGroup: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    onDeleteSchedulingGroup: PropTypes.func.isRequired,
    onEditSchedulingGroup: PropTypes.func.isRequired,
    onRenameSchedulingGroup: PropTypes.func.isRequired,
    onCancelRenameSchedulingGroup: PropTypes.func.isRequired,
    sortedSchedulingGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        numberOfCampaigns: PropTypes.number,
      }),
    ).isRequired,
    onShowSchedulingGroupNameError: PropTypes.func.isRequired,
    onHideSchedulingGroupNameError: PropTypes.func.isRequired,
  };

  onReturn = event => {
    const { onHideSchedulingGroupNameError } = this.props;
    if (event.key === 'Enter') {
      this.handleAddDraftSchedulingGroup(event);
    } else {
      onHideSchedulingGroupNameError();
    }
  };

  clearErrorOnDelete = event => {
    const { onHideSchedulingGroupNameError } = this.props;
    if (
      event.keyCode === KEY_CODES.DELETE ||
      event.keyCode === KEY_CODES.BACKSPACE
    ) {
      onHideSchedulingGroupNameError();
    }
  };

  handleAddDraftSchedulingGroup = event => {
    const {
      schedulingGroup,
      onRenameSchedulingGroup,
      onCancelRenameSchedulingGroup,
      sortedSchedulingGroups,
      onShowSchedulingGroupNameError,
      onHideSchedulingGroupNameError,
    } = this.props;

    const newSchedulingGroupName = event.target.value.replace(/\s+$/g, '');
    const lowerCaseSchedulingGroupName = newSchedulingGroupName.toLowerCase();
    const currentGroupNames = map(sortedSchedulingGroups, group =>
      group.name.toLowerCase(),
    );

    if (
      newSchedulingGroupName &&
      !currentGroupNames.includes(lowerCaseSchedulingGroupName) &&
      lowerCaseSchedulingGroupName !== 'all'
    ) {
      onRenameSchedulingGroup(schedulingGroup.id, newSchedulingGroupName);
      onHideSchedulingGroupNameError();
    } else {
      onShowSchedulingGroupNameError();
    }

    if (!newSchedulingGroupName) {
      onHideSchedulingGroupNameError();
      onCancelRenameSchedulingGroup(schedulingGroup.id);
    }
  };

  render() {
    const {
      schedulingGroup,
      activeSchedulingGroup,
      selectedDivision,
      handleSetActiveSchedulingGroup,
      onDeleteSchedulingGroup,
      onEditSchedulingGroup,
    } = this.props;

    let active = '';
    let disabled = '';
    if (schedulingGroup.numberOfCampaigns === 0) {
      disabled = ' scheduling-group-manager-scheduling-group-disabled';
    } else if (schedulingGroup.id === activeSchedulingGroup.id) {
      active = ' scheduling-group-item-active';
    }

    let dropdownButton = <div className={`${active}`} />;
    if (!isDefaultSchedulingGroup(schedulingGroup)) {
      dropdownButton = (
        <DropdownContainer
          dropdownHeader=""
          menuClassName={`scheduling-group-manager-scheduling-group-options${active}`}
          filterEnabled={false}
          icon={UNICODE_SYMBOLS.OPTIONS_MENU_DOTS}
        >
          <CustomDropdownItem
            name="Rename"
            id={schedulingGroup.id}
            handleClick={onEditSchedulingGroup}
          />
          <CustomDropdownItem
            name="Delete"
            id={schedulingGroup.id}
            handleClick={onDeleteSchedulingGroup}
            itemClassName="delete"
          />
        </DropdownContainer>
      );
    }

    let item = (
      <div className={`scheduling-group-item${active}`}>
        <div
          key={schedulingGroup.id}
          className={`scheduling-group-manager-scheduling-group${disabled}`}
          onClick={() => {
            handleSetActiveSchedulingGroup({ schedulingGroup });
          }}
          role="presentation"
        >
          <span className="scheduling-group-manager-scheduling-group-icon">
            <SchedulingGroupIcon />
          </span>
          <span>{`${selectedDivision} - ${schedulingGroup.name}`}</span>
          <span className="scheduling-group-manager-scheduling-group-number">
            {schedulingGroup.numberOfCampaigns}
          </span>
        </div>
        {dropdownButton}
      </div>
    );

    if (schedulingGroup.editing) {
      item = (
        <div className={`scheduling-group-item${active}`}>
          <SchedulingGroupManagerInput
            newSchedulingGroupInput
            handleAddDraftSchedulingGroup={this.handleAddDraftSchedulingGroup}
            submitOnReturn={this.onReturn}
            clearErrorOnDelete={this.clearErrorOnDelete}
          />
        </div>
      );
    }

    return item;
  }
}

export default SchedulingGroupItemContainer;
