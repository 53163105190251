import React from 'react';

const SchedulingGroupIcon = () => (
  <svg width="24" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.524 1.078c-.281 0-.597.197-.703.399l-.552 1.044a2.176 2.176 0 0 0-.212.848v9.904c0 .445.397.876.968.876H9.37a6.838 6.838 0 0 1-.393-2.291c0-1.92.792-3.72 2.214-5.064 1.42-1.343 3.303-2.078 5.297-2.078 1.222 0 2.403.276 3.461.8V3.84c0-.445-.396-.876-.967-.876H11.5a.857.857 0 0 1-.419-.125.845.845 0 0 1-.322-.32v-.002l-.55-1.04c-.107-.202-.423-.4-.704-.4H2.524zm7.338 14.149H2.025C.946 15.227 0 14.388 0 13.273V3.37c0-.422.14-.985.339-1.36L.89.967C1.195.389 1.885 0 2.524 0h6.982c.64 0 1.329.389 1.633.966m0 0l.488.921h7.356c1.078 0 2.024.839 2.024 1.954v2.31c.273.196.533.41.78.643C23.206 8.138 24 9.937 24 11.858c0 1.92-.791 3.72-2.214 5.065C20.366 18.266 18.482 19 16.489 19c-1.993 0-3.877-.734-5.298-2.078a7.209 7.209 0 0 1-1.33-1.695m.785-.784c.31.616.734 1.187 1.265 1.69 1.216 1.15 2.84 1.79 4.578 1.79 1.737 0 3.36-.64 4.578-1.79 1.216-1.15 1.876-2.667 1.876-4.275 0-1.608-.66-3.124-1.876-4.274a6.453 6.453 0 0 0-.876-.695 6.692 6.692 0 0 0-3.702-1.095c-1.738 0-3.36.64-4.579 1.79-1.215 1.15-1.875 2.667-1.875 4.274 0 .909.21 1.786.611 2.585z"
      fill="#000"
    />
    <path
      d="M21.647 6.961c-1.379-1.316-3.212-2.041-5.163-2.041-1.95 0-3.783.725-5.162 2.041-1.379 1.316-2.138 3.067-2.138 4.928 0 1.862.76 3.612 2.138 4.93 1.379 1.316 3.212 2.04 5.162 2.04 1.95 0 3.783-.724 5.163-2.04 1.379-1.317 2.137-3.068 2.137-4.93 0-1.862-.759-3.612-2.137-4.928zM16.483 17.97c-3.578 0-6.37-2.654-6.37-6.07 0-3.416 2.792-6.07 6.37-6.07 3.578 0 6.367 2.676 6.367 6.092 0 3.416-2.789 6.048-6.367 6.048z"
      fill="#000"
    />
    <path
      d="M20.202 10.223l-3.908 1.784-3.178-1.74c-.178-.102-.295.103-.42.249-.124.146-.08.347.098.449l3.228 1.85a.363.363 0 0 0 .05.023l.029.012.026.008.026.006.027.005.029.003.04.002a.523.523 0 0 0 .132-.02.435.435 0 0 0 .043-.015l.02-.009 4.072-1.964c.189-.09.245-.27.136-.423-.109-.154-.262-.309-.45-.22z"
      fill="#000"
    />
  </svg>
);

export default SchedulingGroupIcon;
