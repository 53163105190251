import gql from 'graphql-tag';
import sendQuery from '../../Api/GraphQL/Request';

export function getSchedulingGroupsApi(payload) {
  const query = gql`
    query($id: Int!, $filters: SchedulingGroupFilter) {
      schedulingGroups: getSchedulingGroups(
        subcategoryId: $id
        filters: $filters
      ) {
        id
        name
        division
      }
    }
  `;
  return sendQuery(query, payload);
}

export function getAdmiralCampaignsApi(schedulingGroupIds) {
  const queries = schedulingGroupIds.map(
    id =>
      `id_${id}: getAdmiralCampaigns(schedulingGroupId: ${id}) { id, name }`,
  );
  const queryString = queries.join(' ');
  const query = gql`
    query {
      ${queryString}
    }
  `;
  return sendQuery(query);
}

const schedulingGroupsMutation = mutationName => gql`
  mutation(
    $schedulingGroups: [SchedulingGroupInput]!
    $subcategoryId: Int!
    $timestamp: Int!
    $division: Division!
  ) {
    schedulingGroups: ${mutationName}(
      schedulingGroups: $schedulingGroups
      subcategoryId: $subcategoryId
      timestamp: $timestamp
      division: $division
    ) {
      id
    }
  }
`;

export function createSchedulingGroups(payload) {
  const mutation = schedulingGroupsMutation('createSchedulingGroups');
  return sendQuery(mutation, payload);
}

export function updateSchedulingGroups(payload) {
  const mutation = schedulingGroupsMutation('updateSchedulingGroups');
  return sendQuery(mutation, payload);
}

export function deleteSchedulingGroups(payload) {
  const mutation = schedulingGroupsMutation('deleteSchedulingGroups');
  return sendQuery(mutation, payload);
}
