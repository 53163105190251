import FILTER_BAR_ACTIONS from 'Features/FilterBar/store/filterBarActions';

export const selectStatusesFilter = payload => ({
  type: FILTER_BAR_ACTIONS.selectStatusesFilter,
  payload,
});

export const deselectStatusesFilter = payload => ({
  type: FILTER_BAR_ACTIONS.deselectStatusesFilter,
  payload,
});
