import gql from 'graphql-tag';
import sendQuery from './Request';

/*
 * There should be one static method per CRUD operation as necessary.
 */
function getSubcategories(payload) {
  const query = gql`
    query(
      $subcategoryFilters: SubcategoryFilter
      $statusFilters: StatusFilter
      $paginate: PageFilter
    ) {
      subcategories: getPaginatedSubcategories(
        filters: $subcategoryFilters
        paginate: $paginate
      ) {
        edges {
          node {
            id
            name

            timezone {
              name
              currentWeekday
            }

            managers {
              fullName
              email
            }

            metrics {
              conversions {
                progress
                limit
                unlimited
              }
              soldLeads {
                progress
                limit
                unlimited
              }
            }

            statuses(filters: $statusFilters) {
              count
              status
            }
          }
        }
        pageCount
      }
    }
  `;
  return sendQuery(query, payload);
}

export default getSubcategories;
