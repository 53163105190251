import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Navbar from 'Components/Navbar/Navbar';
import Sidebar from 'Features/Sidebar/Sidebar';
import sidebarData from 'Features/Sidebar/SidebarData';
import SecureRoute from './SecureRoute';
import NotFound from './Error404';
import Subcategories from '../../Pages/Subcategories/Subcategories';
import GroupManager from '../../Pages/GroupManager/GroupManager';
import PlatformCampaigns from '../../Pages/PlatformCampaigns/PlatformCampaigns';
import PrivacyPolicy from '../../Pages/PrivacyPolicy';
import history from '../../utils/history';

const URLRouter = () => (
  <Router history={history}>
    <div className="app-container">
      <Sidebar sidebarData={sidebarData} />
      <Navbar />
      <Switch>
        <SecureRoute path="/" exact component={Subcategories} />
        <SecureRoute
          path="/subcategories/:subcategoryId(\d+)/schedulinggroup/:schedulingGroupId(\d+)"
          component={PlatformCampaigns}
        />
        <SecureRoute path="/groupmanager" component={GroupManager} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <SecureRoute path="*" component={NotFound} />
      </Switch>
    </div>
  </Router>
);

export default URLRouter;
