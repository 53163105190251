import React, { Fragment } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import Loader from 'Components/Loader/Loader';
import EmptySearch from 'Components/EmptySearch/EmptySearch';
import FilterBar from 'Features/FilterBar';
import platformCampaignFilter from 'Features/FilterBar/platformCampaignFilter';
import { TEXT_STRINGS } from 'Shared/Constants/Constants';
import PlatformCampaignItem from './PlatformCampaignItem';

const PlatformCampaignList = ({
  platformCampaigns,
  schedulingGroup,
  subcategoryName,
  onScheduleToggle,
  loaded,
  onSetCampaignNameSearchTerm,
  campaignNameSearchTerm,
}) => {
  const platformCampaignBody = map(platformCampaigns, campaign => (
    <PlatformCampaignItem
      key={campaign.id}
      id={campaign.id}
      isScheduled={campaign.isScheduled}
      platform={campaign.platform}
      name={campaign.name}
      admiralCampaignId={campaign.admiralCampaignId}
      manager={campaign.manager.fullName}
      mercuryStatus={campaign.mercuryStatus}
      platformStatus={campaign.platformStatus}
      schedulingGroupId={schedulingGroup.id}
      onScheduleToggle={onScheduleToggle}
      identifier={campaign.identifier}
    />
  ));

  const lowerCaseSearchTerm = campaignNameSearchTerm.toLowerCase();
  let loadedPlatformCampaigns;

  if (campaignNameSearchTerm) {
    loadedPlatformCampaigns = platformCampaignFilter(
      platformCampaignBody,
      lowerCaseSearchTerm,
    );
  } else {
    loadedPlatformCampaigns =
      platformCampaigns.length === 0 ? (
        <EmptySearch message={TEXT_STRINGS.EMPTY_PLATFORM_CANPAIGNS} />
      ) : (
        platformCampaignBody
      );
  }

  const loadFilteredCampaigns =
    loadedPlatformCampaigns.length === 0 ? (
      <EmptySearch message={TEXT_STRINGS.EMPTY_SEARCH} />
    ) : (
      loadedPlatformCampaigns
    );

  return (
    <Fragment>
      <div className="campaign__list">
        <div className="campaign__body">
          {loaded ? (
            loadFilteredCampaigns
          ) : (
            <Loader name="Platform Campaigns" />
          )}
        </div>
      </div>
      <div className="campaign__heading--fixed">
        <h2 className="campaign__title">{`Platform Campaigns - ${subcategoryName}`}</h2>
        <h2 className="campaign__subtitle">{schedulingGroup.name}</h2>
        <FilterBar
          setSearchTerm={onSetCampaignNameSearchTerm}
          getSearchTerm={campaignNameSearchTerm}
        />
      </div>
      <header className="campaign__header">
        <div className="grid-container grid-container-campaign">
          <div className="column column-toggle" />
          <div className="column column-campaign-platform">Platform</div>
          <div className="column column-campaign-name">Campaign Name</div>
          <div className="column column-campaign-id">Admiral CID</div>
          <div className="column column-campaign-manager">Manager</div>
          <div className="column column-campaign-identifier">Identifier</div>
          <div className="column column-campaign-status">Status</div>
        </div>
      </header>
    </Fragment>
  );
};

PlatformCampaignList.propTypes = {
  platformCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isScheduled: PropTypes.bool,
      platform: PropTypes.string,
      name: PropTypes.string,
      admiralCampaignId: PropTypes.string,
      manager: PropTypes.shape({
        fullName: PropTypes.string,
      }),
      mercuryStatus: PropTypes.string,
      platformStatus: PropTypes.string,
      identifier: PropTypes.string,
    }),
  ).isRequired,
  schedulingGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  subcategoryName: PropTypes.string.isRequired,
  onScheduleToggle: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  onSetCampaignNameSearchTerm: PropTypes.func.isRequired,
  campaignNameSearchTerm: PropTypes.string.isRequired,
};

export default PlatformCampaignList;
