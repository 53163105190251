import { pickBy, size } from 'lodash';

export const countObject = object => size(object);

export const pickBySchedulingGroupId = (object, value) =>
  pickBy(object, key => key.schedulingGroupId === value);

export const countObjectWithSchedulingGroupId = (object, value) => {
  const objectsReturned = pickBySchedulingGroupId(object, value);
  return countObject(objectsReturned);
};

export const isDefaultSchedulingGroup = schedulingGroup =>
  schedulingGroup.name.toLowerCase() === 'default';
