import { connect } from 'react-redux';
import ScheduleModalContainer from 'Features/Schedules/ScheduleModalContainer';
import {
  getSchedules,
  getSchedulesLoaded,
} from 'Features/Schedules/ScheduleSelector';
import {
  changeSchedule,
  openScheduleModal,
  closeScheduleModal,
  resetSchedule,
  saveSchedule,
} from 'Features/Schedules/ScheduleActionCreators';

const mapStateToProps = state => ({
  schedules: getSchedules(state),
  schedulesLoaded: getSchedulesLoaded(state),
});

const mapDispatchToProps = dispatch => ({
  onOpenScheduleModal: schedulingGroupId => {
    dispatch(openScheduleModal({ schedulingGroupId }));
  },
  onCloseScheduleModal: () => {
    dispatch(closeScheduleModal());
  },
  onChangeScheduleTime: time => {
    dispatch(changeSchedule(time));
  },
  onSaveSchedule: (schedule, schedulingGroupId) => {
    dispatch(saveSchedule({ schedule, schedulingGroupId }));
  },
  onResetSchedule: () => {
    dispatch(resetSchedule());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleModalContainer);
