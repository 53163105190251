export const getSchedulingGroupLimits = state =>
  state.limits.schedulingGroupLimits;

export const getSubcategoryLimits = state => state.limits.subcategoryLimits;

export const getLimitsStore = state => state.limits;

export const getLimitsLoaded = state => state.limits.loaded;

export const getLimitsUpdatedAt = state => state.limits.updatedAt;
