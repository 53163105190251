import gql from 'graphql-tag';
import sendQuery from '../../Api/GraphQL/Request';

/*
 * There should be one static method per CRUD operation as necessary.
 */

export function get(payload) {
  const query = gql`
    query($subcategoryId: Int!, $division: Division) {
      schedulingGroupLimits: getSchedulingGroupLimits(
        subcategoryId: $subcategoryId
        filters: { division: $division }
      ) {
        id
        division
        name
        ignoreSubcategoryLimits
        soldLeads {
          id
          unlimited
          limit
          dayOfWeek
        }
        conversions {
          id
          unlimited
          limit
          dayOfWeek
        }
      }
      subcategoryLimits: getSubcategoryLimits(subcategoryId: $subcategoryId) {
        id
        soldLeads {
          id
          unlimited
          limit
          dayOfWeek
        }
        conversions {
          id
          unlimited
          limit
          dayOfWeek
        }
      }
      updatedAt: getLastUpdatedAtLimits(subcategoryId: $subcategoryId)
    }
  `;
  return sendQuery(query, payload);
}

export function save(payload) {
  const mutation = gql`
    mutation(
      $limits: [LimitInput]!
      $schedulingGroupIgnoreSubcategoryLimits: [SchedulingGroupIgnoreSubcategoryLimitsInput]!
      $timestamp: Int!
    ) {
      limits: updateLimits(limits: $limits, timestamp: $timestamp) {
        id
      }
      updateIgnoreSubcategoryLimits(
        schedulingGroups: $schedulingGroupIgnoreSubcategoryLimits
      )
    }
  `;
  return sendQuery(mutation, payload);
}
