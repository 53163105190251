import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

const AdmiralCampaignItem = ({
  admiralCampaign,
  handleAdmiralCampaignSelect,
}) => (
  <div className="admiral-campaign-item">
    <label className="admiral-campaign-item-label" htmlFor={admiralCampaign.id}>
      <Toggle
        className="admiral-campaign-item-checkbox"
        icons={false}
        id={admiralCampaign.id}
        onClick={e =>
          handleAdmiralCampaignSelect(e.target.checked, admiralCampaign)
        }
      />
      <span className="admiral-campaign-item-name">{admiralCampaign.name}</span>
      <span className="admiral-campaign-item-id">{admiralCampaign.id}</span>
    </label>
  </div>
);

AdmiralCampaignItem.propTypes = {
  admiralCampaign: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleAdmiralCampaignSelect: PropTypes.func.isRequired,
};

export default AdmiralCampaignItem;
